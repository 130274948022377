<template>
  <v-list
    class="pb-0"
    dense
  >
    <v-list-item-group>
      <div
        v-for="(item, index) in getMessages"
        :key="item.id"
      >
        <MessageItem
          :message="item"
          :replyMessage="getReplyMessage(item)"
          :avatar="isSame(item, index)"
          :time="isSameTime(item, index)"
          :date="isSameDate(item, index)"
          @reply="emitReply"
        />
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
import MessageItem from "./MessageItem.vue";
import { dateUtils } from "../../mixins/date";

export default {
  components: {
    MessageItem,
  },

  props: {
    //
  },

  data() {
    return {
      benched: 0,
    };
  },

  mixins: [dateUtils],

  watch: {
    // whenever messages changes, this function will run
    getMessages() {
      //if user is scrolled up, then do nothing
      var cardText = document.getElementById("card-text");
      //Scrolltop + clientHeight varētu būt +- 2, lai izvairītos no neprecizitatēm
      let scroll = Math.round(cardText.scrollTop + cardText.clientHeight);
      if (
        cardText.scrollHeight == scroll - 2 ||
        cardText.scrollHeight == scroll - 1 ||
        cardText.scrollHeight == scroll ||
        cardText.scrollHeight == scroll + 1 ||
        cardText.scrollHeight == scroll + 2
      ) {
        //if at the bottom, then scroll down on new posts
        setTimeout(() => {
          this.scrollToEnd();
        }, 50);

        return;
      }
      //Else parādīt jaunu message bar ar opciju noskrollēt uz leju
      console.log("Show me some new message info bar");
    },
  },

  computed: {
    ...mapGetters("channels", ["getCurrentChannelPosts"]),

    getMessages() {
      return this.getCurrentChannelPosts.posts;
    },
  },

  methods: {
    emitReply(message) {
      this.$emit("reply", message);
    },

    getReplyMessage(post) {
      if (post.reply_id == "") {
        return null;
      }

      for (let i = 0; i < this.getCurrentChannelPosts.reply_posts.length; i++) {
        if (
          this.getCurrentChannelPosts.reply_posts[i].id != undefined &&
          this.getCurrentChannelPosts.reply_posts[i].id == post.reply_id
        ) {
          return this.getCurrentChannelPosts.reply_posts[i];
        }
      }

      return null;
    },

    isSameTime(item, index) {
      if (
        index <= 0 ||
        item.created - this.getCurrentChannelPosts.posts[index - 1].created > 60
      ) {
        return true;
      }
      return false;
    },

    isSameDate(item, index) {
      if (index <= 0) {
        return true;
      }
      let dateTime = this.getDateAndTime(item.created);
      let time = dateTime.substr(0, 10); // getting only date from timestamp of current message

      let preDateTime = this.getDateAndTime(
        this.getCurrentChannelPosts.posts[index - 1].created
      );
      let preTime = preDateTime.substr(0, 10); // getting only date from timestamp of previous message
      return time != preTime;
    },

    isSame(item, index) {
      if (index <= 0) {
        return true;
      }

      return (
        item.user_id != this.getCurrentChannelPosts.posts[index - 1].user_id
      );
    },

    scrollToEnd() {
      var container = document.getElementById("card-text");
      container.scroll(0, container.scrollHeight);
    },
  },
};
</script>
