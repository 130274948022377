<template>
  <v-responsive height="100%">

    <v-card height="100%">
      <v-card-title class="pa-0">
        <v-col class="pa-0">
          <v-toolbar
            class="elevation-0"
            dense
          >
            <v-toolbar-title v-if="getCurrentChannel !== null">{{ getCurrentChannel.displayname }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="startVideo"
              icon
            >
              <v-icon>mdi-video-box</v-icon>
            </v-btn>
            <v-btn
              @click.stop="drawer = !drawer"
              icon
            >
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
        </v-col>
      </v-card-title>

      <v-card-text
        id="card-text"
        ref="cardText"
        :style="`height: ${windowHeight}px;`"
        class="overflow-y-auto pb-0 px-0"
      >
        <MessageList
          id="messageList"
          @reply="showReplyMessage"
        />
      </v-card-text>

      <!-- Varbūt pārtaisīt uz div, jo šeit nav auto overflows -->

      <!-- <v-card
          v-if="replyMessage != ''"
          elevation="2"
        >
         
          <v-row align="center">
            <v-col class="grow">
              <v-card-text
                dense
                class="py-0"
              >
                {{ getUserName(replyMessage.user_id) }}
                {{ replyMessage.message }}</v-card-text>
            </v-col>
            <v-col class="shrink">
              <v-btn
                @click="clearReplyMessage"
                text
                fab
                x-small
              ><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card> -->

      <transition name="fade">
        <div
          v-if="replyMessage != ''"
          class="card-wrapper-cc mx-4"
          ref="replyCard"
        >
          <div class="text-caption card-cc blue--text text--darken-2">

            <div class="font-weight-bold d-flex justify-space-between">
              {{ getUserName(replyMessage.user_id) }}

              <v-btn
                @click="clearReplyMessage"
                text
                fab
                x-small
                class="mt-n2 mr-n2"
              ><v-icon>mdi-close</v-icon></v-btn>
            </div>

            <div
              class="text-wrapper overflow-auto"
              v-html="getMessage(replyMessage.message)"
            ></div>

          </div>
        </div>
      </transition>

      <v-card
        tile
        flat
        outlined
        id="messageCard"
        ref="messageCard"
      >

        <v-textarea
          ref='messageInput'
          autofocus
          background-color="white"
          id="messageInput"
          v-model="message"
          hide-details
          filled
          rounded
          placeholder="Type message"
          auto-grow
          rows="1"
          row-height="15"
          class="text-area-height rounded-0"
          @keydown.enter.exact.prevent="createPost"
          @keydown.enter.shift.exact.prevent="handleShiftEnter"
          @keydown="getTextareaHeight"
          @keyup="getTextareaHeight"
        >
        </v-textarea>

        <v-row
          class="pb-0 ma-0"
          justify="space-between"
        >
          <v-btn-toggle
            borderless
            background-color="grey lighten-3"
            class="pa-0 ma-1"
          >
            <v-btn
              @click="formatText('*')"
              small
              icon
            >
              <v-icon small>mdi-format-italic</v-icon>
            </v-btn>

            <v-btn
              @click="formatText('**')"
              small
              icon
            >
              <v-icon small>mdi-format-bold</v-icon>
            </v-btn>

            <v-btn
              @click="formatText('~~')"
              small
              icon
            >
              <v-icon small>mdi-format-strikethrough-variant</v-icon>
            </v-btn>
            <v-btn
              @click="formatText('link')"
              small
              icon
            >
              <v-icon small>mdi-link-variant</v-icon>
            </v-btn>

            <v-btn
              @click="formatText('```')"
              small
              icon
            >
              <v-icon small>mdi-code-tags</v-icon>
            </v-btn>

            <v-btn
              @click="formatText('>')"
              small
              icon
            >
              <v-icon small>mdi-format-quote-close</v-icon>
            </v-btn>
            <v-btn
              @click="formatText('-')"
              small
              icon
            >
              <v-icon small>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn
              @click="formatText('1.')"
              small
              icon
            >
              <v-icon small>mdi-format-list-numbered</v-icon>
            </v-btn>
            <v-btn
              small
              icon
            >
              <v-icon small>mdi-emoticon-happy-outline</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-spacer></v-spacer>
          <v-btn-toggle class="pa-0 ma-1">
            <v-btn
              elevation="0"
              small
              color="primary"
              @click="createPost"
              :disabled="!message"
            >
              <v-icon
                small
                color="white"
              >mdi-send</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </v-card>
    </v-card>

    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      width="350"
      right
    >
      <template v-slot:prepend>
        <v-list-item v-if="getCurrentChannel != null">
          <v-list-item-content>
            <v-list-item-title>{{
              getCurrentChannel.displayname
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              getCurrentChannel.description
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <v-subheader class="px-4">Channel members</v-subheader>
        <v-list-item
          v-for="item in getChannelUsers()"
          :key="item.id"
        >
          <v-list-item-avatar size="24">
            <v-img :src="`/api/users/photo/${item.photo}`"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{
              item.name + " " + item.lastname
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list-item
        v-if="getCurrentChannel != null && getCurrentChannel.type != 'D'"
        @click.stop="shareOpen = true"
      >

        <v-list-item-avatar size="24">
          <v-icon>mdi-plus</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Add Members</v-list-item-title>
        </v-list-item-content>

      </v-list-item>

      <ShareChannelDialog
        v-if="shareOpen && getCurrentChannel"
        :shareStatus.sync="shareOpen"
        :thisChannel.sync="getCurrentChannel"
      />

    </v-navigation-drawer>
  </v-responsive>
</template>

<script>
import { mapGetters } from "vuex";
import MessageList from "../../components/Chat/MessageList.vue";
import ShareChannelDialog from "../../components/Chat/navigation/ShareChannelDialog.vue";
import * as DOMPurify from "dompurify";
import marked from "marked";

export default {
  components: {
    MessageList,
    ShareChannelDialog,
  },

  data: function () {
    return {
      shareOpen: false,
      replyHeight: 0,
      textareaHeight: 25,
      windowHeight: 500,
      formatting: [],
      drawer: false,
      message: "",
      benched: 0,
      items: [],
      selectionStart: 0,
      selectionEnd: 0,
      allowToLoadMessages: true,
      replyMessage: "",
    };
  },

  mounted() {
    window.addEventListener("focus", this.readAllPosts);

    if (this.$route.params.id != undefined) {
      this.$store
        .dispatch("channels/getChannelPosts", {
          id: this.$route.params.id,
          previous: "",
        })
        .then((response) => {
          response.data.posts.reverse();
          this.$store.commit("channels/setCurrentChannelPosts", response.data);
        });

      var self = this;
      document
        .getElementById("messageInput")
        .addEventListener("mouseup", function () {
          // event nav vajadzīgs
          let input = document.getElementById("messageInput");
          self.selectionStart = input.selectionStart;
          self.selectionEnd = input.selectionEnd;
        });

      document
        .getElementById("card-text")
        .addEventListener("scroll", function (e) {
          if (e.target.scrollTop < 200 && self.allowToLoadMessages) {
            //disable loading messages because scrolling triggers this multiple times
            self.allowToLoadMessages = false;
            self.$store
              .dispatch("channels/getChannelPosts", {
                id: self.$route.params.id,
                previous: self.getCurrentChannelPosts.posts[0].id,
              })
              .then((response) => {
                if (response.data.posts.length > 0) {
                  self.getCurrentChannelPosts.posts.unshift(
                    ...response.data.posts.reverse()
                  );
                  self.allowToLoadMessages = true;
                }
              });
          }
        });
    }

    setTimeout(() => {
      if (this.getCurrentChannel != null) {
        this.$store.dispatch("channels/readChannelPosts", {
          channel_id: this.getCurrentChannel.id,
        });
      }
    }, 1500);
    // message loga izmērs tiek mainīts pie ielādes un pie resize
    window.addEventListener("resize", this.getWindowHeight);
    this.getWindowHeight();
  },

  destroyed() {
    this.$store.commit("channels/setCurrentChannelPosts", {});
    window.removeEventListener("focus", this.readAllPosts);
  },

  unmounted() {
    window.removeEventListener("resize", this.getWindowHeight);
  },

  updated() {
    if (this.replyMessage === "") {
      this.replyHeight = 0;
    } else {
      this.replyHeight = this.$refs.replyCard.offsetHeight;
    }
    this.getWindowHeight();
  },

  computed: {
    ...mapGetters("channels", [
      "getChannelsList",
      "getCurrentChannelPosts",
      "getCurrentChannel",
    ]),

    ...mapGetters("user", ["getUserById", "getUser"]),
  },

  methods: {
    getMessage(message) {
      let escapedMessage = this.escapeHTMLelements(message);
      return DOMPurify.sanitize(marked.parse(escapedMessage));
    },

    escapeHTMLelements(message) {
      return message
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const cardText = this.$refs.cardText;
        cardText.scrollTop = cardText.scrollHeight;
      });
    },

    handleShiftEnter() {
      this.message += "\n"; // Append a newline character to the message
      this.getWindowHeight();
      // this.scrollToBottom();
    },

    scrollPositionCheck() {
      var cardText = document.getElementById("card-text");
      let scroll = Math.round(cardText.scrollTop + cardText.clientHeight);
      if (
        cardText.scrollHeight == scroll - 2 ||
        cardText.scrollHeight == scroll - 1 ||
        cardText.scrollHeight == scroll ||
        cardText.scrollHeight == scroll + 1 ||
        cardText.scrollHeight == scroll + 2
      ) {
        //if at the bottom, then scroll down on new posts
        setTimeout(() => {
          this.scrollToBottom();
        }, 50);
      }
    },

    getTextareaHeight() {
      const textarea = this.$refs.messageInput.$refs.input;
      this.scrollPositionCheck();
      switch (textarea.scrollHeight) {
        case 32:
          this.textareaHeight = 25;
          this.getWindowHeight();
          break;
        case 56:
          this.textareaHeight = 35;
          this.getWindowHeight();
          break;
        case 84:
          this.textareaHeight = 50;
          this.getWindowHeight();
          break;

        default:
          this.textareaHeight = 50;
          this.getWindowHeight();
          break;
      }
    },

    // dinamiski tiek mainīts loga izmērs, kur tiek atspoguļotas visi ieraksti
    getWindowHeight() {
      this.windowHeight =
        window.innerHeight - (this.textareaHeight + 178 + this.replyHeight);
    },

    getUserName(id) {
      let user = this.getUserById(id);

      if (this.getUser.id == user.id) {
        return "You";
      }

      return user.name + " " + user.lastname;
    },

    clearReplyMessage() {
      this.replyMessage = "";
    },

    createPost() {
      //TODO: regex vajag iekļaut arī Ā Ž utml vai vispār skatīties vai nav tikai space un kkas tāds
      const regex = /[A-Za-z0-9]/;
      if (!this.message.match(regex)) {
        return;
      }

      let data = {
        channel_id: this.$route.params.id,
        message: this.message,
        reply: "",
      };

      if (this.replyMessage != "") {
        data.reply = this.replyMessage.id;
      }

      this.$store
        .dispatch("channels/createChannelPost", data)
        .then((response) => {
          this.getCurrentChannelPosts.posts.push(...response.data.posts);
          this.getCurrentChannelPosts.reply_posts.push(
            ...response.data.reply_posts
          );
          this.replyMessage = "";
          this.message = "";
        });
      this.textareaHeight = 25;
      this.getWindowHeight();
      this.scrollToBottom();
    },

    getChannelByName() {
      this.getChannelsList.forEach((element) => {
        if (element.id == this.$route.params.id) {
          return element.displayname;
        }
      });

      for (let i = 0; i < this.getChannelsList.length; i++) {
        if (this.getChannelsList[i].id == this.$route.params.id) {
          return this.getChannelsList[i].displayname;
        }
      }

      //return "name not found";
    },

    getChannelUsers() {
      if (this.getCurrentChannel != null) {
        let currentChannel = this.getCurrentChannel;
        let userList = [];

        for (let i = 0; i < currentChannel.channel_members.length; i++) {
          userList.push(
            this.getUserById(currentChannel.channel_members[i].user_id)
          );
        }

        return userList;
      }
    },

    formatText(format) {
      let start = this.selectionStart;
      let end = this.selectionEnd;

      if (this.selectionStart > this.selectionEnd) {
        start = this.selectionEnd;
        end = this.selectionStart;
      }

      var txt2 = this.message.slice(start, end);
      console.log(txt2);

      let stringStart = this.message.slice(0, start);

      let stringMiddle = this.message.slice(start, end);

      let stringEnd = this.message.slice(end, this.message.length);

      let wrapped = "";
      //markdown string stringMiddle
      if (format == "link") {
        wrapped = `[${stringMiddle}](https://www.google.com)`;
      } else if (format == ">") {
        wrapped = `> ${stringStart}`; //Pielikt Quote ikonu, jo šis neko nemaina praktiski, tikai pabīda uz priekšu
      } else if (format == "-") {
        wrapped = `- ${stringStart}`;
      } else if (format == "1.") {
        wrapped = `1. ${stringStart}`;
      } else {
        wrapped = `${format}${stringMiddle}${format}`;
      }

      console.log(stringStart);
      console.log(stringMiddle);
      console.log(stringEnd);
      console.log(wrapped);

      this.message = stringStart + wrapped + stringEnd;
      //set selection on new range
    },

    readAllPosts() {
      if (this.getCurrentChannel != null) {
        this.$store.dispatch("channels/readChannelPosts", {
          channel_id: this.getCurrentChannel.id,
        });
      }
    },

    showReplyMessage(message) {
      this.scrollPositionCheck();

      this.replyMessage = message;
      // message input is focuset after reply is clicked, so that message coluld be written straightaway
      this.$refs.messageInput.focus();
    },

    startVideo() {
      this.$router.push({
        name: "video",
        params: { id: this.getCurrentChannel.id },
      });
    },

    addUserToPrivateChannel(users) {
      this.$store.dispatch("channels/addUsersToChannel", {
        channel_id: this.getCurrentChannel.id,
        users: users.map((el) => el.id),
      });
    },
  },
};
</script>

<style>
.text-area-height {
  overflow-y: auto;
  max-height: 80px;
}

#messageCard {
  border-top: 0px solid white;
  border-left: 15px solid white;
  border-right: 15px solid white;
  border-bottom: 5px solid white;

  background-color: #eeeeee;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 100%;
  padding: 4px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.text-wrapper p {
  max-height: 200px;
  overflow: auto;
  display: block;
}

.card-wrapper-cc {
  border: 1px solid #1976d2; /* Outline border */
  border-radius: 4px; /* Rounded corners */

  background-color: #fff;
}

.card-cc {
  border-radius: 4px; /* Rounded corners */
  border-left: 10px solid #aecfef; /* Thick border on the left */
  padding: 10px; /* Optional: Add padding to the card content */
}
</style>
