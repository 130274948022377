<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col cols="10">

      <div
        class="mx-1 mb-2"
        style="height: 50vh; widht: 80vw;"
        dark
      >
        <div
          v-if="!showVideoTile"
          class="fill-height text-center d-flex"
          style="align-items: center; justify-content: center;"
        >
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="white"
          ></v-progress-circular>
        </div>
        <VideoTile
          v-if="showVideoTile"
          :participant.sync="participantsData[0]"
          @videoMounted="videoMounted"
          :screenShared.sync="screenShared"
          :key="participantsData[0].identity"
        />
      </div>

      <v-row max-height="40vh">
        <v-col>
          <v-sheet
            dark
            class="mx-1"
            style="widht: 80vw;"
          >

            <v-slide-group
              class="pa-4"
              show-arrows
            >

              <v-slide-item
                v-for="(participant) in participants"
                :key="participant.identity"
              >
                <v-card
                  dark
                  class="ma-4"
                  height="150"
                  width="250"
                >

                  <VideoTile
                    :participant="participant"
                    @videoMounted="videoMounted"
                    :screenShared.sync="screenShared"
                    :key="participant.identity"
                  />
                </v-card>
              </v-slide-item>

            </v-slide-group>

          </v-sheet>
        </v-col>
      </v-row>

    </v-col>

  </v-row>

</template>
  
  <script>
import VideoTile from "./VideoTile.vue";

export default {
  components: { VideoTile },
  props: {
    participants: Array,
    screenShared: Boolean,
  },
  emits: ["videoMounted"],

  data: function () {
    return {
      participantsData: this.participants,
      showVideoTile: false,
    };
  },

  watch: {
    // ja kāds runā tad, listei participantsData mainās kartībā, lai augšā radās cilvēks kas runā
    participantsData: {
      deep: true,
      handler: function (newVal) {
        const newParticipants = [...newVal]; // create a new array

        const speakingIndex = newParticipants.findIndex(
          (participant) => participant.isSpeaking
        );
        if (speakingIndex >= 0) {
          const speakingParticipant = newParticipants.splice(
            speakingIndex,
            1
          )[0];
          newParticipants.unshift(speakingParticipant);
        }

        if (
          JSON.stringify(newParticipants) !==
          JSON.stringify(this.participantsData)
        ) {
          // only update if the new array is different from the old one
          this.participantsData = newParticipants;
        }
      },
    },
  },

  methods: {
    videoMounted(data) {
      const length = this.participants.length;
      const lastUser = this.participants[length - 1].identity;

      if (data == lastUser) {
        this.showVideoTile = true;
        console.log("GRID GALLERY", data);
      }
      this.$emit("videoMounted");
    },
  },
};
</script>
  