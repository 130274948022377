<template>
  <div>
    <v-list dense>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-subheader>
          CHANNELS

          <v-spacer></v-spacer>

          <v-dialog
            v-model="createChannelDialog"
            persistent
            max-width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="pink"
                icon
                v-bind="attrs"
                v-on="on"
                @click="channelType = 'public'"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-form
              v-model="valid"
              ref="channelForm"
            >
              <v-card>
                <v-card-title class="text-h5">
                  Create a new channel
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="channelName"
                    dense
                    label="Channel name"
                    outlined
                    required
                    :rules="[v => !!v || 'Name is required', checkDuplicateName]"
                  ></v-text-field>

                  <v-select
                    v-model="channelType"
                    :items="channelTypes"
                    label="Channel type"
                  ></v-select>

                  <v-textarea
                    v-model="channelDescription"
                    outlined
                    name="input-7-4"
                    label="Channel description"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red"
                    text
                    @click="cancelCreateChannel"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    @click="createChannel"
                  >
                    Create channel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>

        </v-subheader>
        <v-list-item
          exact-path
          v-for="(item, i) in setChannels"
          :key="i"
          :value="item.name"
          @click="openChannel(item)"
          class="pr-0"
          @mouseover="hoverOver(item.id)"
          @mouseleave="hoverLeave()"
        >
          <v-list-item-icon>
            <v-icon>{{ item.type == "O" ? "mdi-web" : "mdi-lock" }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content class="ml-n4">
            <v-list-item-title
              :style="titleWidth(item)"
              v-text="item.displayname"
            ></v-list-item-title>
          </v-list-item-content>

          <v-badge
            v-if="unreadPostCount(item.id) > 0"
            :value="unreadPostCount(item.id) > 0"
            color="error"
            :content="unreadPostCount(item.id)"
            inline
            :class="item.id == hovering ? 'ml-2' : 'ml-2 mr-3'"
          ></v-badge>

          <v-list-item-action
            v-if="(item.id == hovering)"
            class="ma-0 pa-0 mr-2"
          >

            <ActionMenu
              :actionStatus.sync="menuOpen"
              :position="'channel'"
              :shareStatus.sync="shareOpen"
              :thisChannel="item"
              :selectedChannel.sync="selectedChannel"
            />

          </v-list-item-action>

        </v-list-item>

        <ShareChannelDialog
          :shareStatus.sync="shareOpen"
          :thisChannel.sync="selectedChannel"
        />

        <v-subheader>
          DIRECT MESSAGES

          <v-spacer></v-spacer>
          <AddUserDialog
            :addUser="false"
            :loading="loading"
            @click="createDirectChannel"
          />
        </v-subheader>
        <v-list-item
          v-for="(item, i) in setDirectMsg"
          :value="item.name"
          :key="i + item.id"
          @click="openChannel(item)"
          class="pr-0"
          v-show="showChannel(item)"
          @mouseover="hoverOver(item.id)"
          @mouseleave="hoverLeave()"
        >
          <!-- <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon> -->
          <v-list-item-avatar size="24">
            <v-img :src="getUserAvatar(item.channel_members)"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              :style="titleWidth(item)"
              v-text="getDirectMessageName(item)"
            ></v-list-item-title>
          </v-list-item-content>

          <v-badge
            v-if="unreadPostCount(item.id) > 0"
            :value="unreadPostCount(item.id) > 0"
            color="error"
            :content="unreadPostCount(item.id)"
            inline
            :class="item.id == hovering ? 'ml-2' : 'ml-2 mr-3'"
          ></v-badge>

          <v-list-item-action
            v-if="(item.id == hovering)"
            class="ma-0 pa-0 mr-2"
          >
            <!-- <v-row class="ma-0 pa-0">
              <v-btn
                icon
                small
                @click="loading = true"
              >
                <v-icon size="20">mdi-delete</v-icon>
              </v-btn>
            </v-row> -->
            <!-- <AddUserDialog
              :addUser="true"
              @click="addUserToPrivateChannel"
              :listShare="true"
            /> -->

            <ActionMenu
              :actionStatus.sync="menuOpen"
              :position="'direct'"
              :shareStatus.sync="shareOpen"
              :thisChannel="item"
              :selectedChannel.sync="selectedChannel"
            />

          </v-list-item-action>

        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import AddUserDialog from "../../components/Chat/AddUserDialog.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import ActionMenu from "../Chat/navigation/actionMenu.vue";
import ShareChannelDialog from "../../components/Chat/navigation/ShareChannelDialog.vue";

export default {
  components: { AddUserDialog, ActionMenu, ShareChannelDialog },

  data: function () {
    return {
      menuOpen: false,
      valid: true,
      loading: false,
      channelName: "",
      channelDescription: "",
      channelType: "public",
      channelTypes: ["public", "private"],
      selectedItem: "",
      createChannelDialog: false,
      unread: [],
      hovering: "",
      selectedChannel: {},
      shareOpen: false,
    };
  },

  watch: {
    createChannelDialog(newVal) {
      if (newVal) {
        // Do something when createChannelDialog becomes true
        // For example, execute a function or perform some action
        this.channelType = "public";
      }
    },
  },

  computed: {
    ...mapGetters("user", ["getUser", "getUserById"]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("channels", [
      "getUnreadPosts",
      "getCurrentChannel",
      "getDirectMsg",
      "getChannels",
    ]),

    setDirectMsg: {
      get() {
        return this.getDirectMsg;
      },
      set(val) {
        this.$store.commit("channels/setDirectMsg", val);
      },
    },

    setChannels: {
      get() {
        return this.getChannels;
      },
      set(val) {
        this.$store.commit("channels/setChannels", val);
      },
    },
  },

  created() {
    // Search for last visited channel which is stored in localstorage as ID
    // Then router push to /chat/{name} name is unique in database for channel name
    // If channel name is not present in localstorage then push to off-topic or the oldest created channel ?
    this.$store.commit("channels/setEmptyDirectMsg");
    this.$store.commit("channels/setEmptyChannels");

    this.$store.dispatch("channels/getChannelList").then((response) => {
      for (let i = 0; i < response.data.length; i++) {
        if (this.$route.params.id == response.data[i].id) {
          this.$store.commit("channels/setCurrentChannel", response.data[i]);
        }
        if (response.data[i].type == "D") {
          this.setDirectMsg.push(response.data[i]);
        } else {
          this.setChannels.push(response.data[i]);
        }
      }

      if (this.$route.name == "messages" && response.data.length > 0) {
        this.$router.push({
          name: "messages",
          params: { id: response.data[0].id },
        });

        this.$store.commit("channels/setCurrentChannel", response.data[0]);

        this.selectedItem = response.data[0].name;
      }
      //else {
      //  this.selectedItem = this.$route.params.name;
      //}

      this.$store.commit("channels/setReadyToLoad", true);
    });

    this.$store.dispatch("channels/getUnreadPosts");
  },

  mounted() {},

  methods: {
    showChannel(item) {
      for (let i = 0; i < item.channel_members.length; i++) {
        if (item.channel_members[i].user_id == this.getUser.id) {
          return !item.channel_members[i].deleted;
        }
      }
    },

    titleWidth(item) {
      const unreadCount = this.unreadPostCount(item.id);
      const hovering = item.id == this.hovering;

      if (hovering && unreadCount == 0) {
        return "max-width: 166px;";
      } else if (hovering && unreadCount > 0) {
        return "max-width: 138px;";
      } else {
        return "max-width: 170px;";
      }
    },

    hoverOver(item) {
      if (!this.menuOpen) {
        this.hovering = item;
      }
    },

    hoverLeave() {
      if (!this.menuOpen) {
        this.hovering = "";
      }
    },

    checkDuplicateName(v) {
      // Check if the name already exists in the channels array
      const isDuplicate = this.setChannels.some(
        (channel) => channel.name === v
      );
      return !isDuplicate || "Name already exists";
    },

    // duplikāts no conteiner, mixinos?
    addUserToPrivateChannel(users) {
      this.$store.dispatch("channels/addUsersToChannel", {
        channel_id: this.getCurrentChannel.id,
        users: users.map((el) => el.id),
      });
    },

    cancelCreateChannel() {
      this.createChannelDialog = false;
      this.$refs.channelForm.reset(); // noresetējom formu pēc Cancel pogas
    },

    createChannel() {
      if (this.$refs.channelForm.validate()) {
        this.loading = true;
        const options = {
          url: "/api/channels/create",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            name: this.channelName,
            type: this.channelType,
            description: this.channelDescription,
          },
        };

        axios(options)
          .then((response) => {
            this.loading = false;
            this.createChannelDialog = false;
            this.setChannels.push(response.data);
            if (this.setChannels.length == 0) {
              this.$store.commit("channels/setCurrentChannel", response.data);
              this.$router.push({
                name: "messages",
                params: { id: response.data.id },
              });
            }
          })
          .catch(() => {
            this.loading = false;
          });
        this.$refs.channelForm.reset(); // noresetējom formu pēc Channel izveides
      }
    },

    createDirectChannel(selected) {
      this.loading = true;
      const options = {
        url: "/api/channels/create",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: {
          name: `${selected.name} (${selected.email})`, //last name arī ?
          type: "direct",
          description: "",
          user: selected.id,
        },
      };

      axios(options)
        .then((response) => {
          this.loading = false;
          this.setDirectMsg.push(response.data);
          if (this.setChannels.length == 0) {
            this.$store.commit("channels/setCurrentChannel", response.data);
            this.$router.push({
              name: "messages",
              params: { id: response.data.id },
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getDirectMessageName(channel) {
      let me = this.getUser;

      for (let i = 0; i < channel.channel_members.length; i++) {
        if (me.id != channel.channel_members[i].user_id) {
          let user = this.getUserById(channel.channel_members[i].user_id);

          return user.name + `(${user.email})`;
        }
      }
    },

    getUserAvatar(users) {
      for (let i = 0; i < users.length; i++) {
        if (this.getUser.id != users[i].user_id) {
          let photo = this.getUserById(users[i].user_id).photo;
          return `/api/users/photo/${photo}`;
        }
      }

      return "";
    },

    unreadPostCount(id) {
      let count = 0;

      for (let i = 0; i < this.getUnreadPosts.length; i++) {
        if (this.getUnreadPosts[i].channel_id == id) {
          count++;
        }
      }

      return count;
    },

    openChannel(channel) {
      if (this.getCurrentChannel == null) {
        return;
      }

      if (this.getCurrentChannel.id == channel.id) {
        return;
      }

      this.$store.commit("channels/setCurrentChannelPosts", {});
      this.$store.commit("channels/setCurrentChannel", channel);
      this.$router.push({
        name: "messages",
        params: { id: channel.id },
      });
    },
  },
};
</script>
