<template>
  <div>
    <!-- Date title -->
    <div
      v-if="date"
      class="text-center"
    >
      <div
        style="overflow: visible"
        class="no-space"
      >
        <div style="position: absolute; right: 50%;">
          <v-fab-transition>
            <v-btn
              color="primary"
              style="z-index: 5; margin-top: -7px;"
              dark
              small
              absolute
              rounded
              height="15px"
            >
              {{ isTodayDate() }}
            </v-btn>
          </v-fab-transition>
        </div>
      </div>
    </div>
    <!-- end of Date title -->

    <!-- Main message card -->
    <v-card
      flat
      tile
      class="ma-0 pr-5 messageEntry"
      @click="onClicInside"
      v-click-outside="onClickOutside"
    >
      <!-- Show if || Different user || Same user but interval more than 1 min || Mobile view-->
      <v-card-title
        v-if="showCardTitle"
        class="pa-0"
      >
        <!-- Hide only if Mobile view && Same User && interval less than 1 minute -->
        <v-list-item-avatar
          size="32"
          color="grey darken-3"
          class="my-0 avatar"
          :style="showCardAvatar ? 'opacity: 0;' : 'opacity: 1'"
        >
          <v-img
            class="elevation-6"
            alt=""
            :src="`/api/users/photo/${getUserPhoto(message.user_id)}`"
          ></v-img>
        </v-list-item-avatar>
        <span class="text-body-2">
          <span class="font-weight-bold">{{
            getUserName(message.user_id)
          }}</span>
          {{ getDateAndTime(message.created) }}</span>
      </v-card-title>
      <!-- Message action buttons -->
      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        class="msgAction d-flex align-center hide"
      >
        <v-btn
          icon
          small
          color="primary"
        >
          <v-icon small>mdi-thumb-up</v-icon>
        </v-btn>

        <v-btn
          icon
          small
          color="pink"
        >
          <v-icon small>mdi-heart</v-icon>
        </v-btn>

        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="onMenuClick(item)"
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!-- End of Message action buttons -->
      <!-- Mobile message action buttons, buttons show when message is selected -->
      <div
        v-if="selected && $vuetify.breakpoint.smAndDown"
        class="msgAction d-flex align-center"
      >
        <v-btn
          icon
          @click="sheet = true"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </div>
      <!-- End of mobile message action buttons -->

      <!-- <v-list-item
        v-if="replyMessage != null"
        class=" ml-7 my-3"
        color="primary"
      >
        <v-list-item-content class="mx-1">
          <v-list-item-title>{{
            getUserName(replyMessage.user_id)
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            replyMessage.message
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Reply message -->
      <div
        v-if="replyMessage != null"
        class="card-wrapper my-1 ml-16"
      >
        <div class="text-caption card blue--text text--darken-2">

          <div class="font-weight-bold">
            {{ getUserName(replyMessage.user_id) }}
          </div>

          <div
            class="text-wrapper overflow-auto"
            v-html="getMessage(replyMessage.message)"
          ></div>

        </div>
      </div>
      <!-- end of Reply message -->

      <v-card-text class="pa-0 d-flex">
        <span
          :class="
            $vuetify.breakpoint.smAndDown ||
            ($vuetify.breakpoint.mdAndUp && time && !avatar) ||
            ($vuetify.breakpoint.mdAndUp && avatar)
              ? 'pl-4 pr-3 hidden'
              : 'pl-4 pr-3 hide'
          "
          :style="!date && !time ? 'margin-top: 6px; margin-bottom: 6px;' : 'margin-bottom: 6px;'"
        >

          {{ getDateAndTime(message.created).substr(-5) }}
        </span>
        <!-- class="overflow-x-auto" if messege cannot be wrapped then horizontal scroll is shown -->
        <div
          class="overflow-x-auto"
          :style="!date && !time ? 'margin-top: 6px; margin-bottom: 6px;' : 'margin-bottom: 6px;'"
          v-html="getMessage(message.message)"
        ></div>
      </v-card-text>
    </v-card>
    <!-- end of Main message card -->

    <!-- Mobile version action buttons -->
    <v-bottom-sheet v-model="sheet">
      <v-list>
        <v-subheader>Action buttons:</v-subheader>
        <v-list-item>
          <v-btn
            icon
            color="primary"
          >
            <v-icon>mdi-thumb-up</v-icon>
          </v-btn>

          <v-btn
            icon
            color="pink"
          >
            <v-icon>mdi-heart</v-icon>
          </v-btn>
        </v-list-item>
        <v-list-item
          @click="onMenuClick(item)"
          v-for="item in items"
          :key="item.title"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
    <!-- end of mobile buttons -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { dateUtils } from "../../mixins/date";
import marked from "marked";
import * as DOMPurify from "dompurify";
import moment from "moment";

export default {
  props: {
    message: Object, // Messege object with all data
    replyMessage: Object, //reply message. Null if not present
    avatar: Boolean, // False = Same user as previous message; True = Different user from prevous message
    date: Boolean, // True if date of previous message changes
    time: Boolean, // True = more than 1 min after last massage; False = less than 1 minute after last message
  },

  data() {
    return {
      selected: false,
      interval: false,
      sheet: false,
      items: [
        { title: "Reply", name: "reply" },
        { title: "Forward", name: "forward" },
        { title: "Mark as unread", name: "mark_unread" },
        { title: "Edit", name: "edit" },
        { title: "Copy text", name: "copy_text" },
        { title: "Delete", name: "delete" },
      ],
    };
  },

  watch: {},

  mixins: [dateUtils],

  computed: {
    ...mapGetters("user", ["getUserById"]),
    ...mapGetters("user", ["getTimezone"]),

    showCardTitle() {
      if (
        (this.time && !this.avatar) ||
        this.avatar ||
        this.$vuetify.breakpoint.smAndDown
      ) {
        return true;
      }
      return false;
    },

    showCardAvatar() {
      if (!this.time && !this.avatar && this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },

    showCardMsgLayout() {
      if (
        this.$vuetify.breakpoint.smAndDown ||
        (this.$vuetify.breakpoint.mdAndUp && this.time && !this.avatar) ||
        (this.$vuetify.breakpoint.mdAndUp && this.avatar)
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    onMenuClick(item) {
      switch (item.name) {
        case "reply":
          this.$emit("reply", this.message);
          this.sheet = false;
          break;
        default:
          break;
      }
    },

    onClicInside() {
      this.selected = true;
    },
    onClickOutside() {
      this.selected = false;
    },
    isTodayDate() {
      var notFormatedToday = moment().format("YYYY-MM-DD" + " 00:00");
      let todayStart = moment
        .tz(notFormatedToday, this.getTimezone)
        .startOf("day")
        .format("X");
      let todayEnd = moment
        .tz(notFormatedToday, this.getTimezone)
        .endOf("day")
        .format("X");

      if (
        this.message.created >= todayStart &&
        this.message.created <= todayEnd
      ) {
        return "Today";
      } else {
        return this.getDateAndTime(this.message.created).substr(0, 10);
      }
    },

    getUserPhoto(id) {
      let user = this.getUserById(id);

      return user.photo;
    },

    getUserName(id) {
      let user = this.getUserById(id);

      return user.name + " " + user.lastname;
    },

    getMessage(message) {
      let escapedMessage = this.escapeHTMLelements(message);
      return DOMPurify.sanitize(marked.parse(escapedMessage));
    },

    escapeHTMLelements(message) {
      return message
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    },
  },
};
</script>
<style>
.multiline {
  /* white-space: pre-wrap; nestrādā! Viss nobīdās un neizskatās labi! */
  white-space: pre-wrap;
}
/* noņem marginu messege textam */
.v-application p {
  margin: 0px;
}

#wrap:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid #eeeeee;
  background: #eeeeee;
  width: 100%;
  transform: translateY(-50%);
}

.messageEntry {
  /* transition-duration: 500ms;
  transition-property: background-color; */
  transition: all 0.2s;
  width: 100%;
}

.messageEntry:hover {
  background-color: #eeeeee;
  -webkit-transition: background-color 350ms linear;
  -ms-transition: background-color 350ms linear;
  transition: background-color 350ms linear;
}
.hidden {
  opacity: 0;
}

.hide {
  opacity: 0;
  transition-duration: 350ms;
  transition-property: opacity;
}

.messageEntry:hover .hide {
  opacity: 1;
}

.msgAction {
  /* z-index is used because the v-alert (replied message) was above action buttons */
  z-index: 3;
  position: absolute;
  top: -10px;
  right: 16px;
  background-color: white;
  border: 1px solid #eeeeee;
}

.avatar {
  transform: translateY(20%);
}

.sideColor {
  border-left: 3px solid #d32f2f;
  border-radius: 3px;
  background: lightgray;
}

.no-space {
  position: relative;
  line-height: 0;
  overflow: visible;
  z-index: 2;
}

.no-space::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-top: 1px solid #eeeeee;
  width: 100%;
}

.card-wrapper {
  border: 1px solid #1976d2; /* Outline border */
  border-radius: 4px; /* Rounded corners */

  background-color: #e3eef9;
}

.card {
  border-radius: 4px; /* Rounded corners */
  border-left: 10px solid #aecfef; /* Thick border on the left */
  padding: 10px; /* Optional: Add padding to the card content */
}
</style>