<template>

  <v-row
    align="center"
    justify="center"
    class="container-height"
  >
    <v-col
      :cols="getColumnSize()"
      v-for="(participant) in participants"
      :key="participant.identity"
    >
      <div
        class="ma-1"
        :style="{ height: getRowHeight() }"
      >
        <VideoTile
          :participant="participant"
          @videoMounted="videoMounted"
          :screenShared.sync="screenShared"
        />
      </div>

    </v-col>
  </v-row>

</template>

<script>
import VideoTile from "./VideoTile.vue";

export default {
  components: { VideoTile },
  props: {
    participants: Array,
    screenShared: Boolean,
  },
  emits: ["videoMounted"],

  data: function () {
    return {};
  },

  methods: {
    videoMounted() {
      this.$emit("videoMounted");
    },
    getColumnSize() {
      let participantLength = this.participants.length;
      let size = 4;

      if (participantLength == 1) {
        size = 10;
      } else if (participantLength >= 2 && participantLength <= 4) {
        size = 6;
      } else if (participantLength >= 5 && participantLength <= 9) {
        size = 4;
      } else if (participantLength >= 10 && participantLength <= 15) {
        size = 3;
      } else if (participantLength >= 16 && participantLength <= 24) {
        size = 2;
      }

      return Math.round(size);
    },

    getRowHeight() {
      let participantLength = this.participants.length;
      let size = "20vh";

      if (participantLength == 1) {
        size = "70vh";
      } else if (participantLength == 2) {
        size = "45vh";
      } else if (participantLength >= 3 && participantLength <= 4) {
        size = "35vh";
      } else if (participantLength >= 5 && participantLength <= 9) {
        size = "25vh";
      } else if (participantLength >= 10 && participantLength <= 15) {
        size = "15vh";
      } else if (participantLength >= 16 && participantLength <= 24) {
        size = "10vh";
      }

      return size;
    },
  },
};
</script>
