import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    readyToLoad: false,
    channelsList: [],
    currentChannel: null,
    currentChannelPosts: { posts: [], reply_posts: [] },
    unreadPosts: [],
    room: null,
    directMsg: [],
    channels: []
  },

  getters: {
    getChannelsList(state) {
      return state.channelsList;
    },
    getCurrentChannel(state) {
      return state.currentChannel;
    },
    getCurrentChannelPosts(state) {
      return state.currentChannelPosts;
    },
    getUnreadPosts(state) {
      return state.unreadPosts;
    },
    isReadyToLoad(state) {
      return state.readyToLoad;
    },
    getRoom(state) {
      return state.room;
    },
    getDirectMsg(state) {
      return state.directMsg;
    },
    getChannels(state) {
      return state.channels;
    },
  },

  mutations: {
    setChannelsList(state, channels) {
      state.channelsList = channels;
    },
    setCurrentChannel(state, channel) {
      state.currentChannel = channel;
    },
    setCurrentChannelPosts(state, posts) {
      state.currentChannelPosts = posts;
    },
    setUnreadPosts(state, posts) {
      state.unreadPosts = posts;
    },
    setReadyToLoad(state, status) {
      state.readyToLoad = status;
    },
    setRoom(state, status) {
      state.room = status;
    },
    setDirectMsg(state, status) {
      state.directMsg = status;
    },
    setEmptyDirectMsg(state) {
      state.directMsg = [];
    },
    setChannels(state, status) {
      state.channels = status;
    },
    setEmptyChannels(state) {
      state.directMsg = [];
    },
  },

  actions: {
    async getChannelList(context) {
      return new Promise((resolve, reject) => {
        const options = {
          url: '/api/channels/all',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            context.commit("setChannelsList", response.data);
            resolve(response)
          }).catch((error) => {

            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
            reject(error)
          });
      });
    },

    async getChannelPosts(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          url: '/api/posts/' + data.id + '/posts',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        if (data.previous != "") {
          options.url += "?previous=" + data.previous
        }

        axios(options)
          .then(response => {
            //            context.commit("setCurrentChannelPosts", response.data);
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
            reject(error)
          });
      });
    },

    async getUnreadPosts(context) {
      return new Promise((resolve, reject) => {
        const options = {
          url: '/api/posts/unread',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then(response => {
            resolve(response);
            context.commit("setUnreadPosts", response.data)
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
            reject(error)
          });
      });
    },

    async readChannelPosts(context, data) {
      return new Promise((resolve, reject) => {
        let url = '/api/posts/' + data.channel_id + "/read";
        if (data.id != undefined) {
          url + "?id=" + data.id;
        }

        const options = {
          url: url,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {}
        };

        axios(options)
          .then(response => {
            let unread = context.getters["getUnreadPosts"]
            if (data.id == undefined) {
              for (let i = unread.length - 1; i >= 0; i--) {
                if (unread[i].channel_id == data.channel_id) {
                  unread.splice(i, 1);
                }
              }

              resolve(response);
            }

            for (let i = 0; i < unread.length; i++) {
              if (unread[i].post_id == data.id) {
                unread.splice(i, 1);
              }
            }
            resolve(response)
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
            reject(error)
          });
      });
    },

    async createChannelPost(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          url: '/api/posts/' + data.channel_id + "/create",
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            message: data.message,
            reply: data.reply
          }
        };

        axios(options)
          .then(response => {
            resolve(response)
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
            reject(error)
          });
      });
    },

    async addUsersToChannel(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          url: '/api/channels/' + data.channel_id + "/invite",
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            users: data.users
          }
        };

        axios(options)
          .then(response => {
            context.getters["getCurrentChannel"].channel_members.push(...response.data)
            resolve(response)
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
            reject(error)
          });
      });
    },
  },

  namespaced: true,
})
