<template>
  <v-menu
    v-model="showTaskMenu"
    :close-on-content-click="false"
    :nudge-width="200"
    :activator="selectedElement"
    offset-y
    max-width="350"
    min-width="350"
    rounded
  >
    <v-card rounded>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="editSelectedRecord"
        >
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="deleteSelectedTask"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="closeTask"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>

      <v-list class="pt-0">
        <v-list-item>
          <v-list-item-icon class="mr-4">
          </v-list-item-icon>
          <v-list-item-content class="py-0">
            <v-list-item-title>{{selectedEvent.name}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-clock-time-five-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="py-0">
            <v-list-item-title
              v-if="dateFormat(selectedEvent.start, 'll') != dateFormat(selectedEvent.end, 'll')"
              style="font-size: 13px;"
            >
              {{ dateFormat(selectedEvent.start, 'll') +' - '+ dateFormat(selectedEvent.end, 'll') }}
            </v-list-item-title>
            <v-list-item-title
              v-else
              style="font-size: 13px;"
            >
              {{ dateFormat(selectedEvent.end, 'll') }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="selectedEvent.timed == true"
              style="font-size: 13px;"
            >
              {{ timeFormat(selectedEvent.start) +' - '+ timeFormat(selectedEvent.end) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-calendar-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="py-0">
            <v-list-item-title style="font-size: 13px;"> {{ selectedEvent.parentTitle }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="selectedEvent.parentType == 'project' && $route.name == 'calendar'"
          dense
          link
          @click="redirectToProject(selectedEvent)"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-open-in-new</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="py-0">
            <v-list-item-title style="font-size: 13px;"> Go to Project </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="selectedEvent.parentType == 'project' && $route.name == 'project'"
          dense
          link
          @click="openProject(selectedEvent)"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-open-in-new</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="py-0">
            <v-list-item-title style="font-size: 13px;"> Open full task view </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="getAppearances.btn_color"
          text
          @click="changeTaskStatus(selectedEvent)"
          class="text-capitalize"
          :key="getStatusKey"
        >
          <div v-if="selectedEvent.status == 'completed'">
            Completed
          </div>
          <div v-else>
            Mark Complete
          </div>
          <v-icon
            v-if="selectedEvent.status == 'completed'"
            right
            dark
            small
          >
            mdi-restore
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
  
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { projectUtils } from "../../../mixins/project";
import { calendar } from "../../../mixins/calendar";

export default {
  props: {
    selectedOpen: Boolean,
    selectedEvent: Object,
    selectedElement: HTMLElement,
    calendarItem: Object,
    createDialog: Boolean,
    action: String,
    recordDialogTab: String,
    disableTasks: Boolean,
    disableEvents: Boolean,
  },
  data: () => ({
    //
  }),

  watch: {
    "$store.state.projects.alreadyDeleted": function () {
      if (this.getAlreadyDeleted == true) {
        this.deleteCalendarTask(this.selectedEvent.id);
        this.$store.commit("projects/setAlreadyDeleted", false);
        this.closeTask();
      }
    },
  },

  mixins: [projectUtils, calendar],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("user", ["getClockDisplay"]),
    ...mapGetters("projects", [
      "getColumns",
      "getTasks",
      "getProject",
      "getAlreadyDeleted",
    ]),
    ...mapGetters("calendar", [
      "getCalendarTasks",
      "getNotSortedCalendars",
      "getStartTime",
      "getEndTime",
    ]),

    setStartTime: {
      get() {
        return this.getStartTime;
      },
      set(value) {
        this.$store.commit("calendar/setStartTime", value);
      },
    },

    setEndTime: {
      get() {
        return this.getEndTime;
      },
      set(value) {
        this.$store.commit("calendar/setEndTime", value);
      },
    },

    showTaskMenu: {
      get() {
        return this.selectedOpen;
      },
      set(value) {
        this.$emit("update:selectedOpen", value);
      },
    },

    setRecordDialogTab: {
      get() {
        return this.recordDialogTab;
      },
      set(value) {
        this.$emit("update:recordDialogTab", value);
      },
    },

    setDisableEvents: {
      get() {
        return this.disableEvents;
      },
      set(value) {
        this.$emit("update:disableEvents", value);
      },
    },

    setDisableTasks: {
      get() {
        return this.disableTasks;
      },
      set(value) {
        this.$emit("update:disableTasks", value);
      },
    },
  },

  methods: {
    dateFormat(item, format) {
      return moment(item).format(format);
    },

    timeFormat(item) {
      var format = "";
      if (this.getClockDisplay == "24h") {
        format = "HH:mm";
      } else {
        format = "LT";
      }

      return moment(item).format(format);
    },

    changeTaskStatus(item) {
      if (this.$route.name == "project") {
        this.markCompleted(item, true);
      } else if (
        this.$route.name == "calendar" &&
        item.parentType == "calendar"
      ) {
        this.updateCalendarRecord(item, "status");
      } else if (
        this.$route.name == "calendar" &&
        item.parentType == "project"
      ) {
        this.getProject.id = item.project_id;
        item.calendar_id = item.project_id;
        this.updateCalendarRecord(item, "status");
      }
    },

    editSelectedRecord() {
      this.setRecordDialogTab = "tab-2";
      this.setDisableEvents = true;
      this.setDisableTasks = false;

      if (this.$route.name == "calendar") {
        this.showTaskMenu = false;
        this.$emit("update:action", "update");

        var calendar = {};
        var selectedSection = 1;
        var sections = [];

        for (let i = 0; i < this.getNotSortedCalendars.length; i++) {
          if (
            this.selectedEvent.parentType == "calendar" &&
            this.selectedEvent.calendar_id == this.getNotSortedCalendars[i].id
          ) {
            calendar = this.getNotSortedCalendars[i];
          } else if (
            this.selectedEvent.parentType == "project" &&
            this.selectedEvent.project_id == this.getNotSortedCalendars[i].id
          ) {
            calendar = this.getNotSortedCalendars[i];
            var properties = JSON.parse(
              this.getNotSortedCalendars[i].properties
            );
            for (let i = 0; i < properties[0].length; i++) {
              for (let j = 0; j < properties[0][i].tasks.length; j++) {
                if (properties[0][i].tasks[j].id == this.selectedEvent.id) {
                  selectedSection = properties[0][i].index;
                }
                sections.push(properties[0][i]);
              }
            }
          }
        }

        if (this.selectedEvent.files == null) {
          this.selectedEvent.files = [];
        }

        var task = {
          id: this.selectedEvent.id,
          title: this.selectedEvent.name,
          start: this.selectedEvent.start,
          end: this.selectedEvent.end,
          description: this.selectedEvent.description,
          files: this.selectedEvent.files,
          parentType: this.selectedEvent.parentType,
          calendar: calendar,
          selectedSection: selectedSection,
          sections: sections,
          type: this.selectedEvent.type,
          timed: this.selectedEvent.timed,
          guests: this.selectedEvent.guests,
        };

        if (task.timed) {
          this.setStartTime = moment(this.selectedEvent.start).format("HH:mm");
          this.setEndTime = moment(this.selectedEvent.end).format("HH:mm");
        }

        this.$emit("update:calendarItem", task);
        this.$emit("update:createDialog", true);
      } else {
        this.openProject(this.selectedEvent);
      }
    },

    redirectToProject(item) {
      let id = item.id;
      if (item.type == "subtask") {
        id = item.parent_id;
      }
      this.$router.push({
        name: "project",
        params: { id: item.project_id, fullTask: id },
      });
    },

    openProject(item) {
      if (item.type == "subtask") {
        for (let i = 0; i < this.getColumns.length; i++) {
          for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
            if (this.getColumns[i].tasks[j].id == item.parent_id) {
              this.fullTaskInfo(this.getColumns[i].tasks[j]);
            }
          }
        }
      } else if (item.type == "task") {
        for (let i = 0; i < this.getColumns.length; i++) {
          for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
            if (this.getColumns[i].tasks[j].id == item.id) {
              this.fullTaskInfo(this.getColumns[i].tasks[j]);
            }
          }
        }
      }
      this.showTaskMenu = false;
    },

    closeTask() {
      this.showTaskMenu = false;
    },

    deleteSelectedTask() {
      if (
        this.selectedEvent.parentType == "project" &&
        this.$route.name == "project"
      ) {
        this.getProject.id = this.selectedEvent.project_id;
        this.closeTask();
        this.deleteTask(this.selectedEvent.id);
      } else if (
        this.selectedEvent.parentType == "project" &&
        this.$route.name == "calendar"
      ) {
        var data = {
          taskID: this.selectedEvent.id,
          projectID: this.selectedEvent.project_id,
        };
        this.$store.dispatch("projects/trashTask", data).then(() => {
          this.getCalendarTasks.splice(
            this.getCalendarTasks.indexOf(this.selectedEvent),
            1
          );
          // deleting subtasks from calendar

          if (this.selectedEvent.type == "task") {
            let i = 0;
            while (i < this.getCalendarTasks.length) {
              if (this.getCalendarTasks[i].parent_id == this.selectedEvent.id) {
                this.getCalendarTasks.splice(i, 1);
              } else {
                i++;
              }
            }
          }

          this.recDeleteKey += 1;
          this.$store.commit("calendar/setCalendarWsTask", this.selectedEvent);
          this.$store.commit("calendar/setWsTrashTask", true);
          this.closeTask();
        });
      } else if (
        this.selectedEvent.parentType == "calendar" &&
        this.$route.name == "calendar"
      ) {
        data = {
          recordID: this.selectedEvent.id,
          calendarID: this.selectedEvent.calendar_id,
        };
        this.$store.dispatch("calendar/deleteRecord", data).then(() => {
          this.getCalendarTasks.splice(
            this.getCalendarTasks.indexOf(this.selectedEvent),
            1
          );
          this.recDeleteKey += 1;
          this.closeTask();
        });
      }
    },
  },
};
</script>