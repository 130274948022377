import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

export const projectUtils = {

  data: () => ({
    taskStatusList: [
      { color: "green", name: "Completed", value: "completed" },
      { color: "red", name: "Closed", value: "closed" },
      { color: "red", name: "Overdue", value: "overdue" },
      { color: "green", name: "Open", value: "open" },
      { color: "green", name: "In Progress", value: "in progress" },
      { color: "orange", name: "On hold", value: "on hold" },
    ],
  }),

  computed: {
    ...mapGetters('projects', [
      'getColumns',
      'getProject',
      'getHeaders',
      'getAssigned',
      'getRefreshDate',
      'getTasks',
      'getTask',
      'getProjectMembers',
      'getStatusKey',
      'getFullTaskKey',
      'getMetaList',
      'getSelectedMeta',
      'getFilterData',
      'getActiveUsers',
      'getItemsToSelect',
      'getSelectedStatusFilter',
    ]),

    ...mapGetters('user', [
      'getTimezone',
      'getUsers',
      'getUser'
    ]),

    ...mapGetters("calendar", ["getCalendarTasks", "getCalendarList", "getNotSortedCalendars", "getRecordDeleteKey"]),

    recDeleteKey: {
      get() {
        return this.getRecordDeleteKey;
      },
      set(value) {
        this.$store.commit("calendar/setRecordDeleteKey", value);
      },
    },

  },

  methods: {
    ...mapMutations('projects', [
      'setIsAssigned',
      'setDate',
      'setTaskDeleted',
      'setTaskEdited',
      'setRefreshDate',
      'setAssigned',
      'setTaskCreated',
      'setStatusKey',
      'setTask',
      'setTaskNavigation',
      'setFullTaskKey',
      'setCustomFields',
      'setFieldUpdate',
    ]),

    ...mapMutations('calendar', [
      'setCalendarWsTask',
    ]),

    onEnd(update) {
      var project = JSON.parse(JSON.stringify(this.getColumns));
      project = project.map(p => ({
        ...p,
        tasks: p.tasks.map(t => ({ id: t.id }))
      }));


      var title = ''
      if (update == "title") {
        title = this.getProject.title
      } else {
        title = undefined
      }

      var properties = [project, this.getHeaders]
      var data = {
        id: this.getProject.id,
        title: title,
        description: undefined,
        properties: JSON.stringify(properties),
        startDate: undefined,
        endDate: undefined
      }
      this.$store
        .dispatch('projects/patchProject', data)
    },

    assignUser(taskID) {
      var data = {
        projectID: this.getProject.id,
        id: taskID,
        userID: this.getAssigned,
      }

      this.$store
        .dispatch('projects/assignTask', data)
        .then(() => {
          for (let i = 0; i < this.getColumns.length; i++) {
            for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
              if (this.getColumns[i].tasks[j].id == taskID) {
                this.getColumns[i].tasks[j].assigned_to = this.getAssigned;
              }
            }
          }
          this.setAssigned('');
          this.setIsAssigned(true);
        });
    },

    removeMemberFromTask(taskID) {
      var data = {
        projectID: this.getProject.id,
        id: taskID,
      }

      this.$store
        .dispatch('projects/removeMemberFromTask', data)
        .then(() => {
          for (let i = 0; i < this.getColumns.length; i++) {
            for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
              if (this.getColumns[i].tasks[j].id == taskID) {
                this.getColumns[i].tasks[j].assigned_to = '';
              }
            }
          }
          for (let i = 0; i < this.getTasks.length; i++) {
            if (this.getTasks[i].id == taskID) {
              this.getTasks[i].assigned_to = '';
            }
          }
          if (this.getTask.subtasks != undefined) {
            for (let i = 0; i < this.getTask.subtasks.length; i++) {
              if (this.getTask.subtasks[i].id == taskID) {
                this.getTask.subtasks[i].assigned_to = '';
              }
            }
          }
          this.setAssigned('');
          this.setIsAssigned(true);
        });
    },

    async newDate(item, period, date, clear) {
      var timezoneDate = moment.tz(date + " 00:00", this.getTimezone);

      if (period == 'start') {
        if (clear) {
          item.start_date = ''
        } else {
          item.start_date = timezoneDate.utc().format('X')
        }
      } else {
        if (clear) {
          item.end_date = ''
        } else {
          item.end_date = timezoneDate.utc().format('X')
        }
      }

      var data = {
        id: item.id,
        type: item.type,
        parent_id: item.parent_id,
        projectID: this.getProject.id,
        title: item.title,
        description: item.description,
        startDate: item.start_date,
        endDate: item.end_date,
      }

      this.$store
        .dispatch('projects/patchTask', data)
        .then(() => {
          for (let i = 0; i < this.getColumns.length; i++) {
            for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
              if (this.getColumns[i].tasks[j].id == item.id) {
                this.getColumns[i].tasks[j] = item;
              }
            }
          }

          for (let i = 0; i < this.getTasks.length; i++) {
            if (this.getTasks[i].id == item.id) {
              this.getTasks[i] = item
            }
          }
          this.setCalendarWsTask({ action: 'edit', task: item, fromCalendar: false })
          this.setDate(true);
        });
    },

    deleteTask(taskID) {
      var data = {
        taskID: taskID,
        projectID: this.getProject.id
      }
      this.$store
        .dispatch('projects/trashTask', data)
        .then(() => {
          if (taskID == this.getTask.id) {
            this.$store.commit("projects/setTaskNavigation", false);
            this.$store.commit("projects/setCustomFields", false);
          }

          for (let i = 0; i < this.getColumns.length; i++) {
            for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
              if (taskID == this.getColumns[i].tasks[j].id) {
                this.getColumns[i].tasks[j].trash = true;
              } else if (taskID == this.getColumns[i].tasks[j].parent_id) {
                this.getColumns[i].tasks[j].trash = true;
              }
            }
          }

          for (let i = 0; i < this.getTasks.length; i++) {
            if (this.getTasks[i].id == taskID) {
              this.getTasks[i].trash = true;
            } else if (this.getTasks[i].parent_id == taskID) {
              this.getTasks[i].trash = true;
            }
          }

          this.setCalendarWsTask({ action: 'delete', taskID: taskID })
          this.setTaskDeleted(true);
        });
    },

    deleteCalendarTask(taskID) {
      let i = 0;
      while (i < this.getCalendarTasks.length) {
        if (this.getCalendarTasks[i].id == taskID || this.getCalendarTasks[i].parent_id == taskID) {
          this.getCalendarTasks.splice(i, 1);
        } else {
          i++;
        }
      }

      if (this.getTask.subtasks != undefined) {
        for (let i = 0; i < this.getTask.subtasks.length; i++) {
          if (this.getTask.subtasks[i].id == taskID) {
            this.getTask.subtasks.splice(this.getTask.subtasks.indexOf(this.getTask.subtasks[i]), 1);
          }
        }
      }

      if (taskID == this.getTask.id) {
        this.$store.commit("projects/setTaskNavigation", false);
        this.$store.commit("projects/setCustomFields", false);
      }

      this.recDeleteKey += 1
    },

    editTask(item, calendar) {
      if (calendar) {
        item.title = item.name;
        item.start_date = item.start / 1000;
        item.end_date = item.end / 1000;
        item.start_date = item.start_date.toString()
        item.end_date = item.end_date.toString()
      }
      var data = {
        id: item.id,
        type: item.type,
        parent_id: item.parent_id,
        projectID: this.getProject.id,
        title: item.title,
        description: item.description,
        startDate: item.start_date,
        endDate: item.end_date,
        status: item.status
      }
      this.$store
        .dispatch('projects/patchTask', data)
        .then(() => {
          for (let i = 0; i < this.getTasks.length; i++) {
            if (this.getTasks[i].id == item.id) {
              this.getTasks[i].status = item.status;
              this.getTasks[i].title = item.title;
              this.getTasks[i].start_date = item.start_date;
              this.getTasks[i].end_date = item.end_date;
            }
          }
          this.setCalendarWsTask({ action: 'edit', task: item, fromCalendar: calendar })
          if (item.type == 'task') {
            for (let i = 0; i < this.getColumns.length; i++) {
              for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
                if (this.getColumns[i].tasks[j].id == item.id) {
                  this.getColumns[i].tasks[j].title = item.title
                  this.getColumns[i].tasks[j].status = item.status;
                  this.getColumns[i].tasks[j].description = item.description;
                  var key = this.getStatusKey;
                  this.setStatusKey(key += 1);
                }
              }
            }
          }
          this.setTaskEdited(true);
        });
    },

    editCalendarTask(item, calendar) {
      var start = '';
      var end = '';
      var title = '';
      var inList = false;

      if (calendar) {
        start = parseInt(item.start);
        end = parseInt(item.end);
        title = item.name;
      } else {
        if (item.start_date == '') {
          start = parseInt(item.end_date) * 1000;
          end = parseInt(item.end_date) * 1000;
        } else if (item.end_date == '') {
          start = parseInt(item.start_date) * 1000;
          end = parseInt(item.start_date) * 1000;
        } else {
          start = parseInt(item.start_date) * 1000;
          end = parseInt(item.end_date) * 1000;
        }
        title = item.title;
      }

      if (item.files == null || item.files == undefined) {
        item.files = []
      }

      for (let i = 0; i < this.getCalendarTasks.length; i++) {
        if (this.getCalendarTasks[i].id == item.id) {
          this.getCalendarTasks[i] = {
            id: item.id,
            name: title,
            start: start,
            end: end,
            color: "#00897B",
            files: item.files,
            timed: item.timed,
            type: item.type,
            days: this.dayCounter(
              start / 1000,
              end / 1000
            ),
            parentTitle: this.getProject.title,
            parentType: "project",
            status: item.status,
            parent_id: item.parent_id,
            project_id: item.project_id
          }
          inList = true;
        }
      }

      if (!inList) {
        var task = {
          id: item.id,
          name: title,
          start: start,
          end: end,
          color: "#00897B",
          files: item.files,
          timed: item.timed,
          type: item.type,
          days: this.dayCounter(
            start / 1000,
            end / 1000
          ),
          parentTitle: this.getProject.title,
          parentType: "project",
          status: item.status,
          parent_id: item.parent_id,
          project_id: item.project_id
        }
        this.getCalendarTasks.push(task);
      } else {
        var cal = JSON.parse(JSON.stringify(this.getCalendarTasks));
        this.$store.commit("calendar/setCalendarTasks", cal);
      }

    },

    getSubTasks(item) {
      item.subtasks = []
      for (let i = 0; i < this.getTasks.length; i++) {
        if (this.getTasks[i].parent_id == item.id) {
          item.subtasks.push(this.getTasks[i])
        }
      }
      return item.subtasks
    },

    fullTaskInfo(item) {
      this.getSubTasks(item)
      this.setTask(item);
      this.setCustomFields(false);
      this.setTaskNavigation(true);
      var key = this.getFullTaskKey
      this.setFullTaskKey(key += 1)
    },

    createTask(task, calendar) {
      var startDate = '';
      var description = '';
      if (calendar) {
        startDate = task.startDate;
        description = task.description;
      }
      var data = {
        type: task.type,
        parent_id: task.parentID,
        projectID: this.getProject.id,
        title: task.title,
        description: description,
        assigned_to: this.getAssigned,
        depends: '',
        startDate: startDate,
        endDate: task.date,
        timed: task.timed,
        file_ids: task.file_ids,
      }
      this.$store
        .dispatch('projects/createTask', data)
        .then((response) => {
          var newTask = response.data
          newTask.created_by = this.getUser.id
          newTask.files = task.files
          if (newTask.files == undefined) {
            newTask.files = [];
          }
          if (this.$route.name == 'project') {
            for (let i = 0; i < this.getMetaList.length; i++) {
              newTask[this.getMetaList[i].id.toString()] = ""
            }
            if (task.type == 'task') {
              for (let i = 0; i < this.getColumns.length; i++) {
                if (this.getColumns[i].index == task.index) {
                  if (task.position == 'bottom') {
                    this.getColumns[i].tasks.push(newTask)
                  } else if (task.position == 'top') {
                    this.getColumns[i].tasks.unshift(newTask)
                  }
                }
              }
              this.getTasks.push(newTask);
            } else if (task.type == 'subtask') {
              if (this.getTask.subtasks == undefined) {
                this.getTask.subtasks = []
              }
              this.getTask.subtasks.push(newTask);
              this.getTasks.push(newTask);
            }
            this.setCalendarWsTask({ action: 'add', task: newTask })
            this.setTaskCreated(true);
            this.setAssigned('');
          } else {
            //no kalendāra ienāk tasks uz projektiem
            this.setCalendarWsTask({ action: 'add', task: newTask, index: task.index })
            this.setTaskCreated(true);
          }
        });
    },

    addToCalendar(item) {
      if (item.start_date != '' || item.end_date != '') {
        var startDate = '';
        var endDate = '';
        var dayCount = 1;

        if (item.type == 'subtask' || item.start_date == '') {
          startDate = parseInt(item.end_date) * 1000;
          endDate = parseInt(item.end_date) * 1000;
        } else {
          startDate = parseInt(item.start_date) * 1000;
          endDate = parseInt(item.end_date) * 1000;
          dayCount = this.dayCounter(
            parseInt(item.start_date),
            parseInt(item.end_date)
          );
        }

        if (item.files == null || item.files == undefined) {
          item.files = []
        }

        var task = {
          id: item.id,
          name: item.title,
          start: startDate,
          end: endDate,
          color: "#00897B",
          files: item.files,
          timed: item.timed,
          type: item.type,
          days: dayCount,
          parentTitle: this.getProject.title,
          parentType: "project",
          status: item.status,
          parent_id: item.parent_id,
          project_id: item.project_id
        }

        this.getCalendarTasks.push(task);
      }

    },

    dayCounter(start, end) {
      var a = moment(start, "X").startOf("day");
      var b = moment(end, "X").endOf("day");
      var dayCount = Math.round(moment.duration(b.diff(a)).asDays());

      return dayCount;
    },

    dateFormater(ts) {
      var date = moment
        .tz(ts, "X", this.getTimezone)
        .format("YYYY-MM-DD HH:mm:ss");
      var dateFormat = moment(date).tz(this.getTimezone).format();
      var timestamp = moment(dateFormat).format("X");

      return timestamp;
    },

    dateFormat(item) {
      var newFormat = moment(item * 1000).tz(this.getTimezone).format('D MMM, YYYY')
      return newFormat
    },

    assignedUser(item) {
      var photo = ''
      for (let i = 0; i < this.getProjectMembers.length; i++) {
        if (this.getProjectMembers[i].id == item) {
          photo = this.getProjectMembers[i].photo
        }
      }
      return photo
    },

    customFields(id) {
      var field = {}
      for (let i = 0; i < this.getMetaList.length; i++) {
        if (this.getMetaList[i].id == id) {
          field = this.getMetaList[i]
        }
      }
      return field
    },

    priorityColor(item) {
      if (item == "Low") {
        return "green darken-2";
      } else if (item == "Medium") {
        return "orange accent-2";
      } else {
        return "red";
      }
    },

    customLables(name, value) {
      var color = "";
      if (this.customFields(value).type == "Labels") {
        for (let i = 0; i < this.customFields(value).fields.length; i++) {
          if (this.customFields(value).fields[i].name == name) {
            color = this.customFields(value).fields[i].color;
          }
        }
      }
      return color;
    },

    except(item) {
      var custom = true;
      var list = [
        'start_date',
        'end_date',
        'assigned_to',
        'updated',
        'created',
        'title',
        'status'
      ];
      for (let i = 0; i < list.length; i++) {
        if (list[i] == item) {
          custom = false
        }
      }
      if (custom) {
        return true
      } else {
        return false
      }
    },

    saveFields(task, value) {
      if (this.editing != undefined && this.editing != '') {
        var createNew = true
        for (let i = 0; i < this.getSelectedMeta.length; i++) {
          if (this.getSelectedMeta[i].item_id == this.editing && this.getSelectedMeta[i].metadata_id == value) {
            this.updateCustomField(task, value, this.getSelectedMeta[i].id)
            createNew = false
          }
        }
        if (createNew) {
          this.addCustomField(task, value)
        }
      }
    },

    showFileIcon(item) {
      if (item.files != undefined && item.trash == false && item.files.length > 0) {
        return true;
      }
      return false;
    },

    addCustomField(task, value) {
      this.$store.dispatch('meta/addMeta', {
        id: task.id,
        metaid: value,
        value: '"' + task[value] + '"',
        app: 'Projects',
        projectID: this.getProject.id,
      }).then((response) => {
        if (response.data.id != undefined) {
          this.getSelectedMeta.push(response.data)
          this.setFieldUpdate(true)
        }
      })
    },

    filter(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    updateCustomField(task, value, id) {
      this.$store.dispatch('meta/updateMeta', {
        id: id,
        value: task[value],
        projectID: this.getProject.id,
        app: 'Projects'
      }).then((response) => {
        if (response.data == 'success') {
          for (let i = 0; i < this.getColumns.length; i++) {
            for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
              if (task.id == this.getColumns[i].tasks[j].id) {
                this.getColumns[i].tasks[j][value] = task[value]
              }
            }
          }
          // For fullView
          if (task.id == this.getTask.id) {
            this.getTask[value] = task[value]
          }
          for (let i = 0; i < this.getTasks.length; i++) {
            if (this.getTasks[i].id == task.id) {
              this.getTasks[i][value] = task[value]
            }
          }
          this.setFieldUpdate(true);
        }
      })
    },

    statusFilter(item) {
      if (this.getSelectedStatusFilter == 'All tasks') {
        return true
      } else if (this.getSelectedStatusFilter == 'Incomplete tasks') {
        if (item.status != 'completed' && item.status != 'closed') {
          return true
        }
      } else if (this.getSelectedStatusFilter == 'Completed tasks') {
        if (item.status == 'completed') {
          return true
        }
      }
    },

    taskFilter(item) {
      var conditions = [];
      var dateList = ['created', 'end_date', 'start_date', 'updated']
      if (this.getFilterData.filterData == undefined) {
        return true
      } else {
        var data = this.getFilterData.filterData;
        var show = {};
        for (let i = 0; i < data.length; i++) {
          var inList = false
          for (let j = 0; j < dateList.length; j++) {
            if (data[i].filterType == dateList[j]) {
              inList = true
            }
          }
          if (item.trash == false) {
            if (data[i].actionType == 'Is' && item[data[i].filterType] != '') {
              if (this.customFields(data[i].filterType).type == 'Date' || inList == true) {
                if (data[i].datePeriod.action == 'stage') {
                  if (item[data[i].filterType] >= data[i].datePeriod.start
                    && item[data[i].filterType] <= data[i].datePeriod.end) {
                    conditions.push(true)
                  } else {
                    conditions.push(false)
                  }
                } else if (data[i].datePeriod.action == 'greater') {
                  if (item[data[i].filterType] < data[i].datePeriod.date) {
                    conditions.push(true)
                  } else {
                    conditions.push(false)
                  }
                } else if (data[i].datePeriod.action == 'less') {
                  if (item[data[i].filterType] > data[i].datePeriod.date) {
                    conditions.push(true)
                  } else {
                    conditions.push(false)
                  }
                }
              } else if (this.customFields(data[i].filterType).type == 'Labels') {
                item[data[i].filterType].forEach((value) => {
                  if (value == data[i].selectedItem) {
                    show[data[i].selectedItem] = 'show';
                  }
                });
                if (show[data[i].selectedItem] == 'show') {
                  conditions.push(true);
                } else {
                  conditions.push(false);
                }
              } else {
                if (item[data[i].filterType] == data[i].selectedItem) {
                  conditions.push(true)
                } else {
                  conditions.push(false)
                }
              }
            } else if (data[i].actionType == 'Is not') {
              if (this.customFields(data[i].filterType).type == 'Date' || inList == true) {
                if (data[i].datePeriod.action == 'stage') {
                  if (item[data[i].filterType] >= data[i].datePeriod.start
                    && item[data[i].filterType] <= data[i].datePeriod.end) {
                    conditions.push(false);
                  } else {
                    conditions.push(true);
                  }
                } else if (data[i].datePeriod.action == 'greater') {
                  if (item[data[i].filterType] < data[i].datePeriod.date) {
                    conditions.push(false);
                  } else {
                    conditions.push(true);
                  }
                } else if (data[i].datePeriod.action == 'less') {
                  if (item[data[i].filterType] > data[i].datePeriod.date) {
                    conditions.push(false);
                  } else {
                    conditions.push(true);
                  }
                }
              } else if (this.customFields(data[i].filterType).type == 'Labels') {
                item[data[i].filterType].forEach((value) => {
                  if (value == data[i].selectedItem) {
                    show[data[i].selectedItem] = 'show';
                  }
                });
                if (show[data[i].selectedItem] == 'show') {
                  conditions.push(false);
                } else {
                  conditions.push(true);
                }
              } else {
                data.forEach((value) => {
                  if (value.selectedItem == item[data[i].filterType] && value.filterType == data[i].filterType) {
                    show.assignee = 'show';
                  }
                });
                if (show.assignee != 'show') {
                  conditions.push(true);
                } else {
                  conditions.push(false);
                }
              }
            } else if (data[i].actionType == 'Is set') {
              if (item[data[i].filterType] != '') {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Is not set') {
              if (item[data[i].filterType] == '') {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Equals') {
              if (parseInt(item[data[i].filterType]) == parseInt(data[i].selectedItem)) {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Not equal to') {
              if (parseInt(item[data[i].filterType]) != parseInt(data[i].selectedItem)) {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Greater than') {
              if (parseInt(item[data[i].filterType]) > parseInt(data[i].selectedItem)) {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Less than') {
              if (parseInt(data[i].selectedItem) > parseInt(item[data[i].filterType]) && item[data[i].filterType] != '') {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Greater than or equal to') {
              if (parseInt(item[data[i].filterType]) >= parseInt(data[i].selectedItem)) {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Less than or equal to') {
              if (parseInt(item[data[i].filterType]) <= parseInt(data[i].selectedItem) && item[data[i].filterType] != '') {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Contains') {
              if (item[data[i].filterType].toLowerCase().includes(data[i].selectedItem.toLowerCase())) {
                conditions.push(true);
              } else {
                conditions.push(false);
              }
            } else if (data[i].actionType == 'Does not contain') {
              if (item[data[i].filterType].toLowerCase().includes(data[i].selectedItem.toLowerCase())) {
                conditions.push(false);
              } else {
                conditions.push(true);
              }
            } else {
              conditions.push(false);
            }
          } else {
            conditions.push(false);
          }
        }

        if (this.getFilterData.operator == 'AND') {
          let checkValues = arr => arr.every(v => v === true);
          if (checkValues(conditions)) {
            return true
          }
        }

        if (this.getFilterData.operator == 'OR') {
          let checkValues = arr => arr.some(v => v === true);
          if (checkValues(conditions)) {
            return true
          }
        }
      }
    },

    haveSubTask(item) {
      var subtasks = [];
      for (let i = 0; i < this.getTasks.length; i++) {
        if (this.getTasks[i].parent_id == item.id && this.getTasks[i].trash == false) {
          subtasks.push(this.getTasks[i])
        }
      }
      return subtasks.length
    },

    markCompleted(item, calendar) {
      if (this.$route.name == 'project') {
        if (item.status == "completed") {
          item.status = "open";
        } else {
          item.status = "completed";
        }

        if (item.parentType == 'project' || this.$route.name == 'project') {
          this.getProject.id = item.project_id
          this.editTask(item, calendar);
        }
      }

    },

    removeUser(item, sourceType, calendar) {
      if (sourceType == 'calendar') {
        this.$store
          .dispatch("calendar/removeCalendarMember", {
            calendar_id: calendar.id,
            member: item.id,
          })
          .then(() => {
            for (let i = 0; i < this.getCalendarList.length; i++) {
              for (let j = 0; j < this.getCalendarList[i].items.length; j++) {
                if (this.getCalendarList[i].items[j].members != undefined) {
                  for (let k = 0; k < this.getCalendarList[i].items[j].members.length; k++) {
                    if (this.getCalendarList[i].items[j].members[k].user_id == item.id &&
                      this.getCalendarList[i].items[j].id == calendar.id) {

                      this.getCalendarList[i].items[j].members.splice(
                        this.getCalendarList[i].items[j].members.indexOf(this.getCalendarList[i].items[j].members[k]), 1
                      );
                    }
                  }
                }
              }
            }
            this.getActiveUsers.splice(this.getActiveUsers.indexOf(item), 1)
            this.getItemsToSelect.push(item)
            this.actionMenu = false
          });
      } else {
        this.$store
          .dispatch("projects/removeMember", {
            project_id: this.getProject.id,
            member: item.id,
          })
          .then(() => {
            var member = [{ action: "removeUser", user: item }];
            this.$store.commit("projects/setMemberData", member);
            this.$store.commit("projects/setMemberDataUpdate", true);
          });
      }

    },

    addMemberToProject(item, calendar) {
      this.$store.commit("projects/setActiveUsers", []);
      this.$store.commit("projects/setItemsToSelect", []);

      let members = [];
      if (item == 'projects') {
        members = this.getProject.members;
      } else if (item == 'calendar') {
        members = calendar.members;
      }

      for (let i = members.length - 1; i >= 0; i--) {
        for (let j = 0; j < this.getUsers.length; j++) {
          if (this.getUsers[j].id == members[i].user_id) {
            this.getUsers[j].project_role = members[i].role;
            this.getActiveUsers.push(this.getUsers[j]);
          }
        }
      }

      for (let i = 0; i < this.getUsers.length; i++) {
        if (!this.containsActiveUser(this.getUsers[i], this.getActiveUsers) && this.getUsers[i].deactivated_at == 0) {
          this.getItemsToSelect.push(this.getUsers[i]);
        }
      }

      this.addMember = true;
    },

    containsActiveUser(item, list) {
      for (let i = 0; i < list.length; i++) {
        if (item.id == list[i].id) {
          return true;
        }
      }

      return false;
    },

    userStatus(item) {
      for (let i = 0; i < this.getProjectMembers.length; i++) {
        if (this.getProjectMembers[i].id == item.assigned_to) {
          if (this.getProjectMembers[i].deactivated_at == 0) {
            return false;
          } else {
            return true;
          }
        } else if (item.assigned_to == "") {
          return false;
        }
      }
    },
  }
}
