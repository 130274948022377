<template>
  <div class="video-container text-center">
    <div
      v-show="video"
      :class="participant.isSpeaking ? 'video-container-glow text-center container pa-0' : 'video-container text-center container pa-0'"
    >
      <v-tooltip
        bottom
        :disabled="!screenShared"
      >
        <template v-slot:activator="{ on, attrs }">

          <video
            :id="participant.identity"
            class="video"
            v-bind="attrs"
            v-on="on"
          ></video>
        </template>
        <span>{{showName()
      }}</span>
      </v-tooltip>
    </div>

    <div
      v-if="!video"
      class="fill-height text-center d-flex"
      style="align-items: center; justify-content: center;"
    >
      <v-tooltip
        bottom
        :disabled="!screenShared"
      >
        <template v-slot:activator="{ on, attrs }">

          <div
            v-bind="attrs"
            v-on="on"
            :class="participant.isSpeaking ? 'avatar-glow mask' : 'mask'"
            :style="screenShared ? 'height: 90%;' : 'height: 35%;' "
          >
            <img
              style="max-height: 100%; width: auto;"
              :src="`/api/users/photo/${getUserPhoto(participant.identity)}`"
            />
          </div>
          <!-- </v-avatar> -->
        </template>
        <span>{{showName()
      }}</span>
      </v-tooltip>

    </div>
    <v-chip
      v-if="!screenShared"
      size="small"
      class="label"
      color="primary"
      label
    >
      <div> {{ showName() }}</div>

    </v-chip>
  </div>
</template>

<script>
import { Track, ParticipantEvent, LocalParticipant } from "livekit-client";
import { mapGetters } from "vuex";

export default {
  props: {
    participant: Object,
    screenShared: Boolean,
  },
  emits: ["videoMounted"],

  data: function () {
    return {
      video: true,
    };
  },

  mounted() {
    this.isVideoMuted(); // parbaudam vai video ir ieslēgts

    //TODO: Jāuzliek delays, jo audio vai video tracks nav vēl pievienots
    setTimeout(() => {
      const videoTrack = this.participant.getTrack(Track.Source.Camera);
      const audioTrack = this.participant.getTrack(Track.Source.Microphone);

      const element = document.getElementById(this.participant.identity);

      videoTrack.videoTrack.attach(element);

      //If LocalParticipant then don't add audio ti video element
      if (this.participant instanceof LocalParticipant == false) {
        audioTrack.audioTrack.attach(element);
      }

      this.participantConnected(this.participant);

      this.videoMounted(); // Nosūtam uz video view, ka video tile ir Mounted.
    }, 200);
  },

  beforeDestroy() {
    console.log("Destroying video tile");
  },

  computed: {
    ...mapGetters("user", ["getUserById"]),
  },

  methods: {
    getUserPhoto(id) {
      let user = this.getUserById(id);

      return user.photo;
    },

    isVideoMuted() {
      // Bez šīs parbaudes radījas errors: participant.videoTracks undefined
      if (this.participant.videoTracks.size === 0) {
        console.log("No video tracks available.");
        this.video = true;
        return;
      }

      const videoTrack = this.participant.videoTracks.values().next().value;
      if (videoTrack && videoTrack.isMuted) {
        console.log("The video track is currently disabled.");
        this.video = false;
      } else {
        this.video = true;
        console.log("The video track is currently enabled.");
      }
    },
    // Nosūtam uz video view, ka video tile ir Mounted. + nosūtam identity, galleryGrid komponentam salidzin;am un kad pedējais participants ir uzrenderētas var renderēt lielo componentu
    videoMounted() {
      this.$emit("videoMounted", this.participant.identity);
    },
    showName() {
      if (this.participant instanceof LocalParticipant == true) {
        return "Me";
      }

      return this.participant.name;
    },

    participantConnected(participant) {
      participant
        .on(ParticipantEvent.TrackMuted, (pub) => {
          console.log(
            "track was muted video tile",
            pub.trackSid,
            participant.identity,
            pub.kind
          );
          //Varam šeit pielikt, ko gribam, lai atainotu vai media celiņš tika izslēgts
          if (pub.kind == "video") {
            this.video = false;
          }
        })
        .on(ParticipantEvent.TrackUnmuted, (pub) => {
          console.log(
            "track was unmuted video tile",
            pub.trackSid,
            participant.identity,
            pub.kind
          );
          //Varam šeit pielikt, ko gribam, lai atainotu vai media celiņš tika ieslēgts
          if (pub.kind == "video") {
            this.video = true;
          }
        })
        .on(ParticipantEvent.IsSpeakingChanged, () => {
          console.log(
            "this participant is speaking",
            participant.identity,
            participant.name
          );
          //Varam šeit pielikt, ko gribam, lai atainotu, ka cilvēks runā
        })
        .on(ParticipantEvent.ConnectionQualityChanged, () => {
          console.log(
            "this participants media quality changed",
            participant.identity,
            participant.name
          );
          //kvalitate mainijās! Attēlot?
        })
        .on(ParticipantEvent.TrackSubscribed, () => {
          console.log("track was subscribed from video tile");
          //Varam šeit pielikt, ko gribam, lai atainotu vai media celiņš tika izslēgts
        });
    },
  },
};
</script>

<style scoped>
.mask {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 35%;
  aspect-ratio: 1 /1;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-glow {
  outline: #2196f3 solid 5px;
  animation: borderglow 2s infinite;
}
.container {
  /* object-fit: contain; */
  border-radius: 4px;
}
.video-container {
  /* object-fit: contain; */
  width: 100%;
  height: 100%;
  background: black;
}

.video-container-glow {
  /* rāmītis kad cilvēks runa */
  width: 100%;
  height: 100%;
  background: black;
  outline: #2196f3 solid 5px;
  animation: borderglow 2s infinite;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.label {
  position: relative;
  margin-top: -50px;
}

@keyframes borderglow {
  0% {
    outline-color: rgba(50, 115, 220, 0.5);
  }
  50% {
    outline-color: rgba(50, 115, 220, 1);
  }
  100% {
    outline-color: rgba(50, 115, 220, 0.5);
  }
}
</style>
