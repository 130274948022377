import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { findItem } from '../../js/utils'

Vue.use(Vuex)

export default ({
  state: {
    treeviewFolders: [
      {
        title: "My Projects",
        id: "0",
        children: [],
      },
    ],
    openedFolders: [],
    projectFolder: {
      projects: []
    },
    tasks: [],
    projects: [],
    columns: [],
    assigned: '',
    headers: [],
    project: {},
    members: [],
    isAssigned: false,
    date: false,
    taskDeleted: false,
    taskEdited: false,
    refreshDate: 0,
    taskCreated: false,
    sectionName: '',
    sectionIndex: '',
    disableDrag: false,
    editSectionName: false,
    editing: 'list',
    socketPath: '',
    socket: null,
    taskNavigation: false,
    task: {},
    statusKey: 0,
    fullTaskKey: 888,
    customFields: false,
    selectedMeta: [],
    metadata: [],
    fieldUpdate: false,
    refreshCustomDate: 555,
    taskFilter: false,
    filterData: {},
    restoreKey: 346,
    subTaskReloadKey: 634,
    fileActions: false,
    mobileView: '',
    allProjects: [],
    reportingPath: '',
    memberData: {},
    activeUsers: [],
    itemsToSelect: [],
    memberDataUpdate: false,
    selectedStatusFilter: 'All tasks',
    alreadyDeleted: false,
  },

  getters: {
    getTreeviewFolders(state) {
      return state.treeviewFolders;
    },
    getProjectFolder(state) {
      return state.projectFolder;
    },
    getTasks(state) {
      return state.tasks;
    },
    getProjects(state) {
      return state.projects;
    },
    getColumns(state) {
      return state.columns;
    },
    getAssigned(state) {
      return state.assigned;
    },
    getHeaders(state) {
      return state.headers;
    },
    getProject(state) {
      return state.project
    },
    getProjectMembers(state) {
      return state.members
    },
    getIsAssigned(state) {
      return state.isAssigned
    },
    getDate(state) {
      return state.date
    },
    getTaskDeleted(state) {
      return state.taskDeleted
    },
    getTaskEdited(state) {
      return state.taskEdited
    },
    getRefreshDate(state) {
      return state.refreshDate
    },
    getTaskCreated(state) {
      return state.taskCreated
    },
    getSectionName(state) {
      return state.sectionName
    },
    getSectionIndex(state) {
      return state.sectionIndex
    },
    getDisableDrag(state) {
      return state.disableDrag
    },
    getEditSectionName(state) {
      return state.editSectionName
    },
    getEditing(state) {
      return state.editing
    },
    getSocketPath(state) {
      return state.socketPath
    },
    getSocket(state) {
      return state.socket
    },
    getTaskNavigation(state) {
      return state.taskNavigation;
    },
    getTask(state) {
      return state.task;
    },
    getStatusKey(state) {
      return state.statusKey;
    },
    getFullTaskKey(state) {
      return state.fullTaskKey;
    },
    getCustomFields(state) {
      return state.customFields;
    },
    getSelectedMeta(state) {
      return state.selectedMeta;
    },
    getMetaList(state) {
      return state.metadata;
    },
    getFieldUpdate(state) {
      return state.fieldUpdate;
    },
    getRefreshCustomDate(state) {
      return state.refreshCustomDate;
    },
    getTaskFilter(state) {
      return state.taskFilter;
    },
    getFilterData(state) {
      return state.filterData;
    },
    getRestoreKey(state) {
      return state.restoreKey;
    },
    getSubTaskReloadKey(state) {
      return state.subTaskReloadKey;
    },
    getFileActions(state) {
      return state.fileActions;
    },
    getMobileView(state) {
      return state.mobileView;
    },
    getAllProjects(state) {
      return state.allProjects;
    },
    getReportingPath(state) {
      return state.reportingPath;
    },
    getMemberData(state) {
      return state.memberData;
    },
    getActiveUsers(state) {
      return state.activeUsers;
    },
    getItemsToSelect(state) {
      return state.itemsToSelect;
    },
    getMemberDataUpdate(state) {
      return state.memberDataUpdate;
    },
    getSelectedStatusFilter(state) {
      return state.selectedStatusFilter;
    },
    getAlreadyDeleted(state) {
      return state.alreadyDeleted;
    },

  },

  mutations: {
    setTreeviewFolders(state, data) {
      state.treeviewFolders = [
        {
          title: "My Projects",
          id: "0",
          children: [],
        },
      ]

      state.treeviewFolders[0].children = data;
    },
    setOpenedFolders(state, folders) {
      state.openedFolders = folders;
    },
    insertFolder(state, data) {
      let item = findItem("id", data.id, state.treeviewFolders)

      if (item == undefined) {
        return;
      }

      for (let i = 0; i < state.openedFolders.length; i++) {
        if (state.openedFolders[i].id == item.id) {
          item.children.push(data.folder);
          return;
        }
      }

      if (item.children.length > 0) {
        item.children.push(data.folder);
      }
    },
    setProjectFolder(state, folder) {
      state.projectFolder = folder;
    },
    setTasks(state, tasks) {
      state.tasks = tasks;
    },
    setProjects(state, projects) {
      state.projects = projects;
    },
    setColumns(state, columns) {
      state.columns = columns;
    },
    setAssigned(state, assigned) {
      state.assigned = assigned;
    },
    setHeaders(state, headers) {
      state.headers = headers;
    },
    setProject(state, project) {
      state.project = project;
    },
    setProjectMembers(state, members) {
      state.members = members;
    },
    setIsAssigned(state, isAssigned) {
      state.isAssigned = isAssigned;
    },
    setDate(state, date) {
      state.date = date;
    },
    setTaskDeleted(state, taskDeleted) {
      state.taskDeleted = taskDeleted;
    },
    setTaskEdited(state, taskEdited) {
      state.taskEdited = taskEdited;
    },
    setRefreshDate(state, refreshDate) {
      state.refreshDate = refreshDate;
    },
    setTaskCreated(state, taskCreated) {
      state.taskCreated = taskCreated;
    },
    setSectionName(state, sectionName) {
      state.sectionName = sectionName;
    },
    setSectionIndex(state, sectionIndex) {
      state.sectionIndex = sectionIndex;
    },
    setDisableDrag(state, disableDrag) {
      state.disableDrag = disableDrag;
    },
    setEditSectionName(state, editSectionName) {
      state.editSectionName = editSectionName;
    },
    setEditing(state, editing) {
      state.editing = editing;
    },
    setSocketPath(state, socketPath) {
      state.socketPath = socketPath;
    },
    setSocket(state, socket) {
      state.socket = socket;
    },
    setTaskNavigation(state, taskNavigation) {
      state.taskNavigation = taskNavigation;
    },
    setTask(state, task) {
      state.task = task;
    },
    setStatusKey(state, statusKey) {
      state.statusKey = statusKey;
    },
    setFullTaskKey(state, fullTaskKey) {
      state.fullTaskKey = fullTaskKey;
    },
    setCustomFields(state, customFields) {
      state.customFields = customFields;
    },
    setSelectedMeta(state, selectedMeta) {
      state.selectedMeta = selectedMeta;
    },
    setMetaList(state, metadata) {
      state.metadata = metadata;
    },
    setFieldUpdate(state, fieldUpdate) {
      state.fieldUpdate = fieldUpdate;
    },
    setRefreshCustomDate(state, refreshCustomDate) {
      state.refreshCustomDate = refreshCustomDate;
    },
    setTaskFilter(state, taskFilter) {
      state.taskFilter = taskFilter;
    },
    setFilterData(state, filterData) {
      state.filterData = filterData;
    },
    setRestoreKey(state, restoreKey) {
      state.restoreKey = restoreKey;
    },
    setSubTaskReloadKey(state, subTaskReloadKey) {
      state.subTaskReloadKey = subTaskReloadKey;
    },
    setFileActions(state, fileActions) {
      state.fileActions = fileActions;
    },
    setMobileView(state, mobileView) {
      state.mobileView = mobileView;
    },
    setAllProjects(state, allProjects) {
      state.allProjects = allProjects;
    },
    setReportingPath(state, reportingPath) {
      state.reportingPath = reportingPath;
    },
    setMemberData(state, memberData) {
      state.memberData = memberData;
    },
    setActiveUsers(state, activeUsers) {
      state.activeUsers = activeUsers;
    },
    setItemsToSelect(state, itemsToSelect) {
      state.itemsToSelect = itemsToSelect;
    },
    setMemberDataUpdate(state, memberDataUpdate) {
      state.memberDataUpdate = memberDataUpdate;
    },
    setSelectedStatusFilter(state, selectedStatusFilter) {
      state.selectedStatusFilter = selectedStatusFilter;
    },
    setAlreadyDeleted(state, alreadyDeleted) {
      state.alreadyDeleted = alreadyDeleted;
    },
  },

  actions: {
    async getProjectMembers(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + id + '/members',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
            context.commit('setProjectMembers', response.data);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    createProject(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.folder + '/create',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            title: data.title,
            description: data.description,
            properties: data.properties,
            start_date: data.startDate,
            end_date: data.endDate,
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to create project'
            }, { root: true })
          });
      })
    },

    deleteProject(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + id + '/delete',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(() => {
            resolve();
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to delete project'
            }, { root: true })
          });
      })
    },

    addToFavourite(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.project_id + '/favourite',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {}
        };
        axios(options)
          .then(() => {
            resolve();
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    getFavourites(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/favourites',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    addMemberToProject(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.project_id + '/member/add',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            members: data.members,
          }
        };
        axios(options)
          .then((response) => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    removeMember(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.project_id + '/member/remove',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            member: data.member,
          }
        };
        axios(options)
          .then((response) => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    patchProject(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.id + '/patch',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            title: data.title,
            description: data.description,
            properties: data.properties,
            startDate: data.startDate,
            endDate: data.endDate,
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to update project'
            }, { root: true })
          });
      })
    },

    createProjectFolder(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/folder/create',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            title: data.title,
            properties: data.properties,
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    patchProjectFolder(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/folder/' + data.id + "/patch",
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            title: data.title,
            properties: data.properties,
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    deleteProjectFolder(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/folder/' + id + "/delete",
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(() => {
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getProjectsFolders(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/my-projects',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getProjectsInFolder(context, folderID) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/folder/' + folderID,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getProjects(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            context.commit('setProjects', response.data);
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getProject(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + id,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getTasks(context, projectID) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + projectID + '/tasks',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            context.commit('setTasks', response.data);
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getMyTasks(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/my-tasks',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getRecentTasks(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/recent-tasks',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getDeletedTasks(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/deleted-tasks',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    getTasksInProjects(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/tasks',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            searchType: data.searchType,
            ids: data.ids
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    createTask(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.projectID + '/task/create',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            type: data.type,
            parent_id: data.parent_id,
            description: data.description,
            depends: data.depends,
            title: data.title,
            assigned_to: data.assigned_to,
            start_date: data.startDate,
            end_date: data.endDate,
            timed: data.timed,
            file_ids: data.file_ids
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to create task'
            }, { root: true })
          });
      })
    },

    assignTask(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.projectID + '/task/' + data.id + '/assign/' + data.userID,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to assign member'
            }, { root: true })
          });
      })
    },

    removeMemberFromTask(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.projectID + '/task/' + data.id + '/remove',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Member removed from task'
            }, { root: true })
          });
      })
    },

    trashTask(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.projectID + '/task/' + data.taskID + '/trash',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            if (error.response.status === 405) {
              context.commit('setAlreadyDeleted', true)
              context.dispatch('alert/showAlert', {
                color: 'red',
                text: 'This task has been deleted by another user and is no longer accessible'
              }, { root: true })
            } else {
              context.dispatch('alert/showAlert', {
                color: 'red',
                text: 'Failed to delete Task'
              }, { root: true })
            }
          });
      })
    },

    deleteTask(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.projectID + '/task/' + data.taskID + '/delete',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to delete Task'
            }, { root: true })
          });
      })
    },

    restoreTask(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.projectID + '/task/' + data.taskID + '/restore',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to delete Task'
            }, { root: true })
          });
      })
    },

    patchTask(context, data) {
      console.log('data patch', data);
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.projectID + '/task/' + data.id + '/patch',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            type: data.type,
            parentID: data.parentID,
            description: data.description,
            title: data.title,
            assignedTo: data.assignedTo,
            startDate: data.startDate,
            endDate: data.endDate,
            status: data.status,
            files: data.files,
            timed: data.timed
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to updated task'
            }, { root: true })
          });
      })
    },

    addFile(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.projectID + '/task/' + data.id + '/file/add',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            fileID: data.fileID
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to add file'
            }, { root: true })
          });
      })
    },

    removeFile(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + data.projectID + '/task/' + data.id + '/file/remove',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            fileID: data.fileID
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: 'Failed to remove file'
            }, { root: true })
          });
      })
    },

    async getMetaList(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + id + '/metalist',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
            context.commit('setMetaList', response.data);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getSelectedMeta(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/' + id + '/meta',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
            context.commit('setSelectedMeta', response.data);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getAllProjects(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/projects/all',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
            context.commit('setAllProjects', response.data);
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

  },
  namespaced: true,
})
