<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row :class="$route.name == 'project' ? 'fill-height mb-3 fill-width' : 'fill-height mb-3'">
      <v-col class="pa-0 ma-0">
        <v-toolbar
          flat
          color="transparent"
        >
          <v-btn
            v-if="parent == 'calendar'"
            class="mr-3"
            :color="getAppearances.btn_color"
            dark
            @click="openCreateDialog"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            New
          </v-btn>
          <v-btn
            color="grey darken-2"
            @click="setToday"
            outlined
          >
            Today
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
            class="ml-1"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
            class="mr-1"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar != undefined && $refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="changeType('day')">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeType('week')">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeType('month')">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeType('4day')">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-sheet
          :height="parent == 'calendar' ? '86vh' : '690'"
          class="mx-3"
        >
          <v-calendar
            ref="calendar"
            v-model="calendarDate"
            color="primary"
            :events="getCalendarTasks"
            :event-color="getEventColor"
            :type="type"
            :event-ripple="false"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:day="(event)=>openCreateDialog(event)"
            @change="updateRange"
            @mousedown:event="startDrag"
            @mousedown:time="startDragTime"
            @mousemove:time="mouseMove"
            @mouseup:time="endDrag"
            @mouseleave.native="cancelDrag"
          >
            <template
              v-if="type != 'month'"
              v-slot:day-body="{ date, week }"
            >
              <div
                :key="key"
                class="v-current-time"
                :class="{ first: date === week[0].date }"
                :style="{ top: timeLinePosition }"
              ></div>
            </template>
            <template v-slot:event="{ event, timed }">
              <div
                :key="getRecordDeleteKey"
                @click="testLOG(event)"
              >
                <div
                  v-if="event.timed == true && event.days == 1 && type == 'month'"
                  class="black--text ml-1"
                >
                  <v-row class="ma-0 d-flex flex-nowrap">
                    <v-btn
                      v-if="event.type != 'event'"
                      icon
                      dark
                      :color="event.color"
                      x-small
                      style="margin-bottom: 1px; margin-right: 3px;"
                    >
                      <v-icon v-if="event.type == 'task'">mdi-checkbox-marked-circle-outline</v-icon>
                      <v-icon v-if="event.type == 'subtask'">mdi-file-tree</v-icon>
                    </v-btn>
                    <v-list-item-title style="font-size: 13px; margin-bottom: 1px;">
                      <b
                        class="d-inline-flex"
                        :style="event.status == 'completed' ? 'text-decoration: line-through;' : ''"
                      >
                        {{event.name}}
                      </b>
                      <v-btn
                        v-if="event.guests != '[]' &&  event.type == 'event'"
                        icon
                        dark
                        :color="event.color"
                        x-small
                        style="margin-bottom: 3px; margin-left: 2px;"
                      >
                        <v-icon>mdi-account-group</v-icon>
                      </v-btn>
                      <div
                        class="mx-1 d-inline-flex"
                        style="font-size: 12px;"
                      > {{ timeFormat(event.start)}} </div>
                      <div class="mr-1 d-inline-flex">-</div>
                      <div
                        class="d-inline-flex"
                        style="font-size: 12px;"
                      >
                        {{ timeFormat(event.end)}}
                      </div>
                    </v-list-item-title>
                  </v-row>
                </div>
                <div
                  v-else
                  class="mx-1"
                >
                  <div
                    v-if="type == 'month'"
                    class="d-flex flex-nowrap"
                  >
                    <v-btn
                      v-if="event.type != 'event'"
                      icon
                      dark
                      color="white"
                      x-small
                      style="margin-bottom: 1px; margin-right: 3px;"
                    >
                      <v-icon v-if="event.type == 'task'">mdi-checkbox-marked-circle-outline</v-icon>
                      <v-icon v-if="event.type == 'subtask'">mdi-file-tree</v-icon>
                    </v-btn>

                    <v-btn
                      v-if="event.shared_event"
                      icon
                      dark
                      color="white"
                      x-small
                      style="margin-bottom: 1px; margin-right: 3px;"
                    >
                      <v-icon>mdi-calendar-star</v-icon>
                    </v-btn>

                    <v-list-item-title style="font-size: 13px; margin-bottom: 1px;">
                      <b
                        class="d-inline-flex"
                        :style="event.status == 'completed' ? 'text-decoration: line-through;' : ''"
                      >
                        {{event.name}}
                      </b>
                      <v-btn
                        v-if="event.guests != '[]' &&  event.type == 'event'"
                        icon
                        dark
                        color="white"
                        x-small
                        style="margin-bottom: 3px; margin-left: 2px;"
                      >
                        <v-icon>mdi-account-group</v-icon>
                      </v-btn>
                      <!-- <div
                        v-if="event.timed"
                        class="d-inline-flex"
                      >
                        <div
                          class="mx-1 d-inline-flex"
                          style="font-size: 12px;"
                        > {{ timeFormat(event.start, 'LT')}} </div>
                        <div class="mr-1 d-inline-flex">-</div>
                        <div
                          class="d-inline-flex"
                          style="font-size: 12px;"
                        >{{ timeFormat(event.end, 'LT')}}</div>
                      </div> -->
                    </v-list-item-title>
                  </div>
                  <v-row
                    v-else
                    class="ma-0 pa-0 d-flex flex-nowrap"
                  >
                    <v-btn
                      v-if="event.type != 'event'"
                      icon
                      dark
                      color="white"
                      x-small
                      style="margin-bottom: 1px; margin-right: 3px;"
                    >
                      <v-icon v-if="event.type == 'task'">mdi-checkbox-marked-circle-outline</v-icon>
                      <v-icon v-if="event.type == 'subtask'">mdi-file-tree</v-icon>
                    </v-btn>
                    <v-list-item-title style="font-size: 13px; margin-bottom: 1px;">
                      <b :style="event.status == 'completed' ? 'text-decoration: line-through;' : ''">{{event.name}}</b>
                      <v-btn
                        v-if="type == 'day' && event.timed"
                        icon
                        dark
                        color="white"
                        x-small
                        style="margin-bottom: 1px;"
                        @click="taskInfoBtn = true"
                        class="ml-1"
                      >
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </v-list-item-title>
                    <div v-if="type != 'day' && event.timed">
                      <v-spacer />
                      <v-btn
                        icon
                        dark
                        color="white"
                        x-small
                        style="margin-bottom: 1px;"
                        @click="taskInfoBtn = true"
                      >
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                  <v-row
                    v-if="event.timed == true"
                    class="ma-0"
                  >
                    <div class="mr-1"> {{ timeFormat(event.start)}} </div>
                    <div class="mr-1">-</div>
                    <div>{{ timeFormat(event.end)}}</div>
                  </v-row>
                </div>
                <div
                  v-if="timed"
                  class="v-event-drag-bottom"
                  @mousedown.stop="extendBottom(event)"
                ></div>
              </div>
            </template>
          </v-calendar>

          <Event
            v-if="selectedEvent.type == 'event'"
            :selectedOpen.sync="selectedOpen"
            :selectedEvent.sync="selectedEvent"
            :selectedElement="selectedElement"
            :recordDialogTab.sync="recordDialogTab"
            :disableTasks.sync="disableTasks"
            :disableEvents.sync="disableEvents"
            :calendarItem.sync="calendarItem"
            :createDialog.sync="createDialog"
            :action.sync="action"
            :selectedCalendarValue.sync="selectedCalendarValue"
            :selectedSectionValue.sync="selectedSectionValue"
          />

          <Task
            v-if="selectedEvent.type == 'task' || selectedEvent.type == 'subtask'"
            :selectedOpen.sync="selectedOpen"
            :selectedEvent.sync="selectedEvent"
            :selectedElement="selectedElement"
            :calendarItem.sync="calendarItem"
            :createDialog.sync="createDialog"
            :action.sync="action"
            :selectedCalendarValue.sync="selectedCalendarValue"
            :selectedSectionValue.sync="selectedSectionValue"
            :recordDialogTab.sync="recordDialogTab"
            :disableTasks.sync="disableTasks"
            :disableEvents.sync="disableEvents"
          />

          <RecordDialog
            :action.sync="action"
            :parent="parent"
            :createDialog.sync="createDialog"
            :calendarItem.sync="calendarItem"
            :timedEvent.sync="timedEvent"
            :selectedCalendarValue.sync="selectedCalendarValue"
            :selectedSectionValue.sync="selectedSectionValue"
            :recordDialogTab.sync="recordDialogTab"
            :disableTasks.sync="disableTasks"
            :disableEvents.sync="disableEvents"
            :type="type"
          />

        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import RecordDialog from "../../components/Calendar/Dialogs/RecordDialog.vue";
import { projectUtils } from "../../mixins/project";
import Task from "../../components/Calendar/Menu/Task.vue";
import Event from "../../components/Calendar/Menu/Event.vue";
import { calendar } from "../../mixins/calendar";

export default {
  props: {
    parent: String,
  },
  components: {
    RecordDialog,
    Task,
    Event,
  },
  data: () => ({
    recordDialogTab: "tab-1",
    selectedCalendarValue: {},
    selectedSectionValue: 1,
    calendarItem: {},
    timedEvent: false,
    createDialog: false,
    action: "new",
    loaded: false,
    type: "month",
    selectedElement: null,
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedOpen: false,
    activityType: ["event", "task"],
    key: 0,
    timeLinePosition: "",
    interval: null,
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    taskInfoBtn: false,
    disableTasks: false,
    disableEvents: false,
  }),

  watch: {
    type: function () {
      this.interval = setInterval(this.incrementTime, 60000);
      setTimeout(() => {
        this.timeLinePosition =
          this.$refs.calendar.timeToY(this.$refs.calendar.times.now) + "px";
        this.scrollToTime();
        this.updateTime();
      }, 100);
    },

    selectedOpen: function () {
      if (!this.selectedOpen) {
        this.taskInfoBtn = false;
      }
    },

    "$store.state.projects.editing": function () {
      if (this.getEditing == "calendar") {
        this.getProjectTasks();
      }
    },

    "$store.state.calendar.calendarsLoaded": function () {
      if (this.getCalendarsLoaded == true) {
        this.$store.commit("calendar/setCalendarTasks", []);
        this.getRecords();
        this.$store.commit("calendar/setCalendarsLoaded", false);
      }
    },
  },

  mixins: [projectUtils, calendar],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("user", ["getClockDisplay"]),
    ...mapGetters("projects", [
      "getTasks",
      "getProject",
      "getEditing",
      "getColumns",
    ]),
    ...mapGetters("calendar", [
      "getCalendarTasks",
      "getCalendarDate",
      "getCalendarRecords",
      "getCalendarProjectRecords",
      "getCalendarsLoaded",
      "getNotSortedCalendars",
      "getRecordDeleteKey",
      "getCalendars",
    ]),
    ...mapGetters("user", ["getTimezone"]),

    calendarDate: {
      get() {
        return this.getCalendarDate;
      },
      set(value) {
        this.$store.commit("calendar/setCalendarDate", value);
      },
    },
  },

  mounted() {
    this.$refs.calendar.checkChange();
    if (this.parent == "projects") {
      this.getProjectTasks();
      this.$refs.calendar.checkChange();
    }
  },

  methods: {
    getRecords() {
      this.getProjectTasks(true);

      var startTimestamp = "";
      var endTimestamp = "";

      if (this.getCalendarRecords != undefined) {
        for (let i = 0; i < this.getCalendarRecords.length; i++) {
          startTimestamp = this.dateFormater(
            this.getCalendarRecords[i].start_date
          );
          endTimestamp = this.dateFormater(this.getCalendarRecords[i].end_date);
          this.addToCalendarList(
            this.getCalendarRecords[i],
            startTimestamp,
            endTimestamp
          );
        }
      }
    },

    getProjectTasks(calendar) {
      var startTimestamp = "";
      var endTimestamp = "";
      var tasks = [];
      if (calendar) {
        tasks = this.getCalendarProjectRecords;
      } else {
        tasks = this.getTasks;
      }
      this.$store.commit("calendar/setCalendarTasks", []);
      if (tasks != undefined) {
        for (let i = 0; i < tasks.length; i++) {
          if (tasks[i].trash == false) {
            if (tasks[i].start_date != "" && tasks[i].end_date != "") {
              startTimestamp = this.dateFormater(tasks[i].start_date);
              endTimestamp = this.dateFormater(tasks[i].end_date);
              this.addToProjectCalendarList(
                tasks[i],
                startTimestamp,
                endTimestamp
              );
            } else if (tasks[i].end_date == "" && tasks[i].start_date != "") {
              startTimestamp = this.dateFormater(tasks[i].start_date);
              endTimestamp = this.dateFormater(tasks[i].start_date);
              this.addToProjectCalendarList(
                tasks[i],
                startTimestamp,
                endTimestamp
              );
            } else if (tasks[i].start_date == "" && tasks[i].end_date != "") {
              startTimestamp = this.dateFormater(tasks[i].end_date);
              endTimestamp = this.dateFormater(tasks[i].end_date);
              this.addToProjectCalendarList(
                tasks[i],
                startTimestamp,
                endTimestamp
              );
            }
          }
        }
      }
    },

    incrementTime() {
      this.updateTime();
      this.timeLinePosition =
        this.$refs.calendar.timeToY(this.$refs.calendar.times.now) + "px";
      this.key += 1;
    },

    openCreateDialog(item) {
      if (this.$route.name == "project") {
        this.recordDialogTab = "tab-2";
        this.disableEvents = true;
      } else {
        this.recordDialogTab = "tab-1";
        this.disableTasks = false;
        this.disableEvents = false;
      }

      var sections = [];
      if (this.$route.name == "project") {
        for (let i = 0; i < this.getColumns.length; i++) {
          sections.push(this.getColumns[i]);
        }
      }

      var calendar = "";
      for (let i = 0; i < this.getCalendars.length; i++) {
        if (this.getCalendars[i].default_calendar) {
          calendar = this.getCalendars[i];
        }
      }

      var task = {
        title: "",
        start: item.date,
        end: item.date,
        description: "",
        files: [],
        parentType: "",
        calendar: calendar,
        selectedSection: 1,
        sections: sections,
        type: "",
        timed: false,
        guests: [],
      };
      this.calendarItem = task;
      this.createDialog = true;
    },

    changeType(item) {
      this.type = item;
    },

    viewDay({ date }) {
      this.calendarDate = date;
      this.type = "day";
    },
    getEventColor(event) {
      if (event.timed == true && this.type == "month" && event.days == 1) {
        return "white";
      } else {
        return event.color;
      }
    },

    timeFormat(item) {
      var format = "";
      if (this.getClockDisplay == "24h") {
        format = "HH:mm";
      } else {
        format = "LT";
      }

      return moment(item).format(format);
    },

    setToday() {
      this.calendarDate = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      if (this.type == "month" || this.taskInfoBtn || event.timed == false) {
        if (this.dragTime == undefined && this.extendOriginal == undefined) {
          const open = () => {
            this.selectedEvent = event;
            this.selectedElement = nativeEvent.target;
            requestAnimationFrame(() =>
              requestAnimationFrame(() => (this.selectedOpen = true))
            );
          };
          if (this.selectedOpen) {
            this.selectedOpen = false;
            requestAnimationFrame(() => requestAnimationFrame(() => open()));
          } else {
            open();
          }
          nativeEvent.stopPropagation();
        }
      }
    },

    updateRange({ start, end }) {
      console.log("start,end", start, end);
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    getCurrentTime() {
      return this.$refs.calendar
        ? this.$refs.calendar.times.now.hour * 60 +
            this.$refs.calendar.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);
      this.$refs.calendar.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.$refs.calendar.updateTimes(), 60 * 1000);
    },

    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },

    startDrag({ event, timed }) {
      this.selectedOpen = false;
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },

    startDragTime(tms) {
      const mouse = this.toTime(tms);
      var title = "";
      var parent = "";
      var parentTitle = "";

      title = `Record #${this.getCalendarTasks.length}`;
      parent = "calendar";
      parentTitle = "No parent";

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          name: title,
          color: "#00897B",
          start: this.createStart,
          end: this.createStart,
          timed: true,
          type: "task",
          days: 1,
          parentTitle: parentTitle,
          parentType: parent,
          status: "open",
          calendar_id: undefined,
          files: [],
        };
        if (this.createEvent.end == this.createEvent.start) {
          this.createEvent.end += 900000;
        }
        this.getCalendarTasks.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },

    testLOG(item) {
      console.log("testLOG", item);
    },

    mouseMove(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },

    endDrag() {
      this.timedEvent = true;
      if (this.extendOriginal !== null) {
        // EXTEND
        if (
          this.createEvent.id == undefined &&
          this.createEvent.calendar_id == undefined
        ) {
          const i = this.getCalendarTasks.indexOf(this.createEvent);
          if (i !== -1) {
            this.getCalendarTasks.splice(i, 1);
          }
        } else {
          if (this.$route.name == "project") {
            this.getProject.id = this.createEvent.project_id;
            this.editTask(this.createEvent, true);
          } else if (
            this.$route.name == "calendar" ||
            this.createEvent.parentType == "project"
          ) {
            if (this.createEvent.parentType == "project") {
              this.getProject.id = this.createEvent.project_id;
              this.createEvent.calendar_id = this.createEvent.project_id;
            }
            this.updateCalendarRecord(this.createEvent, "date");
          }
        }
      } else if (this.dragEvent && this.dragTime !== null) {
        // DRAG
        if (
          this.dragEvent.id == undefined &&
          this.dragEvent.calendar_id == undefined
        ) {
          const i = this.getCalendarTasks.indexOf(this.dragEvent);
          if (i !== -1) {
            this.getCalendarTasks.splice(i, 1);
          }
        } else {
          if (this.$route.name == "project") {
            this.editTask(this.dragEvent, true);
          } else if (
            this.$route.name == "calendar" ||
            this.dragEvent.parentType == "project"
          ) {
            if (this.dragEvent.parentType == "project") {
              this.getProject.id = this.dragEvent.project_id;
              this.dragEvent.calendar_id = this.dragEvent.project_id;
            }
            this.updateCalendarRecord(this.dragEvent, "date");
          }
        }
      } else if (this.createEvent !== null) {
        // NEW TASK
        if (this.$route.name == "project") {
          var sections = [];
          for (let i = 0; i < this.getColumns.length; i++) {
            sections.push(this.getColumns[i]);
          }
          this.createEvent.selectedSection = 1;
          this.createEvent.sections = sections;
        }

        var calendar = "";
        for (let i = 0; i < this.getCalendars.length; i++) {
          if (this.getCalendars[i].default_calendar) {
            calendar = this.getCalendars[i];
          }
        }

        this.createEvent.calendar = calendar;
        this.setTime(this.createEvent);
        this.calendarItem = this.createEvent;
        this.calendarItem.fromTimeline = true;
        this.createDialog = true;
      }

      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },

    setTime(item) {
      this.$store.commit("calendar/setStartTime", this.timeFormat(item.start));
      this.$store.commit("calendar/setEndTime", this.timeFormat(item.end));
    },

    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.getCalendarTasks.indexOf(this.createEvent);
          if (i !== -1) {
            this.getCalendarTasks.splice(i, 1);
          }
        }
      }
      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;
      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
  },
};
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.v-event-draggable {
  padding-left: 6px;
}
.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}
.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }
  &:hover::after {
    display: block;
  }
}

.v-event {
  margin-left: 1px;
}

.v-event-more {
  margin-left: 1px;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 13px;
}

.fill-width {
  overflow-x: auto;
  overflow-y: auto;
  flex-wrap: nowrap;
  height: 83vh;
}
</style>