<template>
  <v-container
    fluid
    class="fill-height container-styling"
  >
    <v-row class="container-layout">
      <v-col cols="12">
        <v-row
          v-if="screenShared"
          align="center"
          justify="center"
        >
          <v-col cols="10">
            <VideoShareTile />
          </v-col>
          <v-col cols="1">
            <v-col
              cols="12"
              v-for="(participant, index) in participants"
              :key="index"
              class="pa-0 ma-0"
            >
              <v-responsive max-height="100">

                <VideoTile
                  :participant="participant"
                  @videoMounted="videoMounted"
                  :screenShared.sync="screenShared"
                  class="pa-2"
                />

              </v-responsive>
            </v-col>
          </v-col>
        </v-row>

        <div v-if="!screenShared">
          <GridDefault
            v-if="gridToggle"
            :participants.sync="participants"
            @videoMounted="videoMounted"
            :screenShared.sync="screenShared"
          />
          <GridGallery
            v-if="!gridToggle"
            :participants.sync="participants"
            @videoMounted="videoMounted"
            :screenShared.sync="screenShared"
          />
        </div>

      </v-col>
    </v-row>
    <v-row
      class="pa-3 ma-0"
      align="center"
      justify="center"
    >
      <v-btn
        @click="shareScreen"
        :disabled="!allowShareScreen"
        class="mx-2"
        fab
        dark
        color="gray"
      >
        <v-icon>
          {{ share == true ? "mdi-monitor" : "mdi-monitor-off" }}
        </v-icon>
      </v-btn>

      <v-btn
        :disabled="disableVIdeoActionButtons"
        @click="disableVideo"
        class="mx-2"
        fab
        dark
        color="gray"
      >
        <v-icon>
          {{ video == true ? "mdi-video-box" : "mdi-video-box-off" }}
        </v-icon>
      </v-btn>

      <v-btn
        @click="disableAudio"
        :disabled="disableVIdeoActionButtons"
        class="mx-2"
        fab
        dark
        color="gray"
      >
        <v-icon>
          {{ audio == true ? "mdi-microphone" : "mdi-microphone-off" }}
        </v-icon>
      </v-btn>

      <v-btn
        @click="disconnect"
        class="mx-2"
        fab
        dark
        color="red"
      >
        <v-icon> mdi-phone-hangup </v-icon>
      </v-btn>
      <v-btn
        :disabled="screenShared || participants.length === 1"
        @click="toggleGrid"
        class="mx-2"
        fab
        dark
      >
        <v-icon>
          {{ gridToggle == true ? "mdi-view-gallery" : "mdi-view-grid" }}
        </v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import VideoTile from "./VideoTile.vue";
import VideoShareTile from "./VideoShareTile.vue";
import GridDefault from "./GridDefault.vue";
import GridGallery from "./GridGallery.vue";
import {
  Room,
  RoomEvent,
  VideoPresets,
  LocalParticipant,
  // Track,
  // ParticipantEvent,
} from "livekit-client";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  components: { VideoTile, VideoShareTile, GridDefault, GridGallery },
  data: function () {
    return {
      gridToggle: true,
      disableVIdeoActionButtons: true,
      loaded: false,
      participants: [],
      token: "",
      // room: null,
      video: true,
      audio: true,
      share: true,
      screenShared: false,
      allowShareScreen: true,
    };
  },

  async created() {
    if (this.getCurrentChannel != null) {
      let id = this.getCurrentChannel.id;
      const options = {
        url: "/api/channels/" + id + "/video/token",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      };

      axios(options)
        .then((response) => {
          this.token = response.data;
          this.load();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },

  computed: {
    ...mapGetters("channels", ["getCurrentChannel", "getRoom"]),

    setRoom: {
      get() {
        return this.getRoom;
      },
      set(value) {
        this.$store.commit("channels/setRoom", value);
      },
    },
  },

  methods: {
    toggleGrid() {
      this.gridToggle = !this.gridToggle;
    },
    // kāmēr  videTile nav mounted audio/video pogas nav aktīvas
    videoMounted() {
      this.disableVIdeoActionButtons = false;
      // console.log("video mounted", this.disableVIdeoActionButtons);
    },

    isLocal(user) {
      if (user instanceof LocalParticipant == true) {
        return true;
      }
    },

    async load() {
      const room = new Room({
        adaptiveStream: true,
        dynacast: true,
        videoCaptureDefaults: {
          resolution: VideoPresets.h720.resolution,
        },
        publishDefaults: {
          screenShareEncoding: {
            maxFramerate: 30,
          },
        },
      });

      this.setRoom = room;

      // set up event listeners
      this.setRoom
        // .on(RoomEvent.ParticipantConnected, this.participantConnected)
        .on(RoomEvent.ParticipantDisconnected, this.handleParticipantDisconnect)
        .on(RoomEvent.LocalTrackPublished, (track) => {
          //TODO: Pārvietot uz funkciju
          if (track.source == "screen_share") {
            this.screenShared = true;
            this.allowShareScreen = true;
            setTimeout(() => {
              const element = document.getElementById("video-share");
              track.videoTrack.attach(element);
            }, 100);
          }

          this.addParticipant(room.localParticipant);
        })
        .on(RoomEvent.LocalTrackUnpublished, (track) => {
          console.log("unpublished from local track");

          if (track.source == "screen_share") {
            const element = document.getElementById("video-share");
            track.videoTrack.detach(element);

            this.allowShareScreen = true;
            this.screenShared = false;
          }

          this.addParticipant(room.localParticipant);
        })
        .on(RoomEvent.TrackSubscribed, (track, pub, participant) => {
          console.log(
            "subscribed to track",
            pub.trackSid,
            participant.identity
          );

          if (track.source == "screen_share") {
            this.screenShared = true;
            this.allowShareScreen = false;
            setTimeout(() => {
              const element = document.getElementById("video-share");
              track.attach(element);
            }, 100);
          }

          this.addParticipant(participant);
        })
        .on(RoomEvent.TrackUnsubscribed, (track, pub, participant) => {
          console.log("unsubscribed from track", pub.trackSid);
          if (track.source == "screen_share") {
            const element = document.getElementById("video-share");
            track.detach(element);

            this.screenShared = false;
            this.allowShareScreen = true;
          }
          this.addParticipant(participant);
        });

      await this.setRoom.connect("wss://k3stest-chat.twigex.com", this.token);

      await this.setRoom.localParticipant.enableCameraAndMicrophone();
    },

    handleParticipantDisconnect(participant) {
      this.participants.splice(this.participants.indexOf(participant), 1);
    },

    addParticipant(participant) {
      let index = this.participants.indexOf(participant);
      if (index >= 0) {
        this.participants[index] = participant;
        return;
      }

      this.participants.push(participant);
    },

    async disconnect() {
      await this.setRoom.disconnect();

      this.participants = [];

      this.$router.push({
        name: "messages",
        params: { id: this.getCurrentChannel.id },
      });
    },

    async disableVideo() {
      this.video = !this.video;

      if (!this.video) {
        await this.setRoom.localParticipant.setCameraEnabled(false);
        return;
      }

      await this.setRoom.localParticipant.setCameraEnabled(true);
    },

    async disableAudio() {
      this.audio = !this.audio;

      if (!this.audio) {
        await this.setRoom.localParticipant.setMicrophoneEnabled(false);
        return;
      }

      await this.setRoom.localParticipant.setMicrophoneEnabled(true);
    },

    async shareScreen() {
      this.share = !this.share;

      if (!this.share) {
        await this.setRoom.localParticipant.setScreenShareEnabled(true);
        return;
      }

      await this.setRoom.localParticipant.setScreenShareEnabled(false);
    },
  },
};
</script>

<style scoped>
.container-styling {
  background: black;
}
</style>
