<template>
  <div v-if="loaded">
    <div class="calWidth">
      <v-date-picker
        class="mt-2"
        v-model="calendarDate"
        :color="getAppearances.btn_color"
        width="100%"
        no-title
      ></v-date-picker>
    </div>
    <v-list
      dense
      class="py-0"
    >
      <v-list-group
        v-for="item in getCalendarList"
        :key="item.title"
        v-model="item.active"
        color="grey darken-3"
        no-action
        sub-group
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 14px"
              v-text="item.title"
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            v-if="item.title == 'My Calendars'"
            class="pa-0 ma-0"
          >
            <v-menu
              v-model="createNewMenu"
              offset-y
              :close-on-click="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="20">mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  link
                  @click.stop="openCreateNewDialog('calendar')"
                >
                  <v-list-item-title>
                    Create calendar
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click.stop="openCreateNewDialog('resource')"
                >
                  <v-list-item-title>
                    Create resource
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </template>
        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          class="pl-13 pr-0"
          @mouseover="hoverOver(child.id)"
          @mouseleave="hoverLeave()"
          @click="testLOG(child)"
        >
          <v-list-item-action class="ma-0 mr-2 pa-0">
            <v-checkbox
              v-model="child.active"
              :color="child.color"
              @click="activeState(child, item.title)"
              :disabled="child.resource"
              dense
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              class="text-truncate"
              :style="child.id == hovering ? 'max-width: 126px;' : 'max-width: 174px;'"
              v-text="child.title"
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            v-if="(child.id == hovering)"
            class="ma-0 mr-2 pa-0"
          >
            <v-row class="ma-0 pa-0">
              <v-btn
                v-if="item.title != 'Project Calendars' && child.default_calendar != true"
                icon
                small
                @click="deleteCalendar(child)"
              >
                <v-icon size="20">mdi-delete</v-icon>
              </v-btn>
              <v-menu
                :close-on-click="true"
                offset-y
                v-model="menuOpen"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="20">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <div
                    v-for="(cal, index) in calendarOptions"
                    :key="index"
                  >
                    <v-list-item
                      v-if="cal.title == 'Share' && item.title != 'Project Calendars' && item.title != 'Shared Calendars'"
                      link
                      @click="openShare(child)"
                    >
                      <v-list-item-title>{{ cal.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-else-if="cal.title == 'Edit' && item.title != 'Project Calendars'"
                      link
                      @click="editCalendar(child)"
                    >
                      <v-list-item-title>{{ cal.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-else-if="cal.title == 'Go to Project' && item.title == 'Project Calendars'"
                      link
                      @click="goToProject(child)"
                    >
                      <v-list-item-title>{{ cal.title }}</v-list-item-title>
                    </v-list-item>
                    <v-menu
                      v-else-if="cal.title == 'Change Color' && item.title == 'Project Calendars'"
                      top
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                          link
                        >
                          <v-list-item-title>
                            {{ cal.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-color-picker
                        hide-canvas
                        hide-sliders
                        hide-inputs
                        :swatches="swatches"
                        value="#000"
                        show-swatches
                        @update:color="updateProjectColor($event, child)"
                      ></v-color-picker>
                    </v-menu>
                  </div>
                </v-list>
              </v-menu>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>
    </v-list>
    <v-form
      ref="formCalendar"
      v-model="valid"
    >
      <NewCalendarDialog
        :addNewCalendar.sync="addNewCalendar"
        :calendarTitle.sync="calendarTitle"
        :calendarDescription.sync="calendarDescription"
        :newCalendarColor.sync="newCalendarColor"
        :swatches.sync="swatches"
        :updateColor.sync="updateColor"
        :closeCalendarDialog.sync="closeCalendarDialog"
        :calendarEditing.sync="calendarEditing"
        :updateCalendar.sync="updateCalendar"
        :calendarDialogDisable.sync="calendarDialogDisable"
        :createCalendar.sync="createCalendar"
        :calendarRules.sync="calendarRules"
        :parentType.sync="parentType"
      />
    </v-form>
    <v-dialog
      v-model="dialogDelete"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete <br> <b class="mr-2">"{{ selectedCalendar.title }}"</b> Calendar?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialogDelete = false"
            class="mb-2"
          >
            Close
          </v-btn>
          <v-btn
            :color="getAppearances.btn_color"
            dark
            @click="deleteConfirm(selectedCalendar)"
            class="mb-2"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ShareDialog
      :addMember.sync="addMember"
      :sourceType="'calendar'"
      :selectedCalendar.sync="selectedCalendar"
    />
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import ShareDialog from "../../components/Projects/Dialogs/AddMember.vue";
import { projectUtils } from "../../mixins/project";
import { calendar } from "../../mixins/calendar";
import NewCalendarDialog from "../../components/Calendar/Dialogs/NewCalendarDialog.vue";

export default {
  components: {
    ShareDialog,
    NewCalendarDialog,
  },
  data: function () {
    return {
      parentType: "",
      addNewResource: false,
      createNewMenu: false,
      calendarId: "",
      calendarActive: "",
      calendarEditing: false,
      addMember: false,
      loaded: false,
      selectedCalendar: {},
      dialogDelete: false,
      hovering: false,
      addNewCalendar: false,
      valid: true,
      calendarRules: [(v) => !!v || "Calendar name is required"],
      calendarTitle: "",
      calendarDescription: "",
      newCalendarColor: "#4CAF50",
      calendarOptions: [
        { title: "Edit" },
        { title: "Share" },
        { title: "Go to Project" },
        { title: "Change Color" },
      ],
      menuOpen: false,
      swatches: [
        ["#B71C1C", "#E91E63", "#9C27B0"],
        ["#673AB7", "#3F51B5", "#2196F3"],
        ["#03A9F4", "#00BCD4", "#009688"],
        ["#4CAF50", "#FFC107", "#FF9800"],
        ["#FF5722", "#795548", "#607D8B"],
      ],
    };
  },

  watch: {
    "$store.state.projects.taskCreated": function () {
      if (this.getTaskCreated == true) {
        this.openSocket(
          this.getCalendarWsTask.task,
          "calendarRecordCreate",
          this.getCalendarWsTask.index
        );
        this.$store.commit("projects/setTaskCreated", false);
      }
    },

    "$store.state.calendar.calendarRecordUpdated": function () {
      if (this.getCalendarRecordUpdated == true) {
        this.openSocket(this.getCalendarWsTask.task, "calendarRecordUpdate");
        this.$store.commit("calendar/setCalendarRecordUpdated", false);
      }
    },

    "$store.state.calendar.wsTrashTask": function () {
      if (this.getWsTrashTask == true) {
        this.openSocket(this.getCalendarWsTask, "trashTask");
        this.$store.commit("calendar/setWsTrashTask", false);
      }
    },
  },

  mixins: [projectUtils, calendar],

  created() {
    this.clearCalendarData().then(() => {
      this.createCalendarList();
      this.$store.commit("calendar/setNotSortedCalendars", []);
    });
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("calendar", [
      "getCalendars",
      "getCalendarList",
      "getCalendarDate",
      "getNotSortedCalendars",
      "getCalendarTasks",
      "getCalendarWsTask",
      "getCalendarRecordUpdated",
      "getWsTrashTask",
    ]),
    ...mapGetters("user", ["getUser"]),
    ...mapGetters("projects", ["getProjects", "getTaskCreated", "getSocket"]),

    webSocket: {
      get() {
        return this.getSocket;
      },
      set(value) {
        this.$store.commit("projects/setSocket", value);
      },
    },

    calendarDate: {
      get() {
        return this.getCalendarDate;
      },
      set(value) {
        this.$store.commit("calendar/setCalendarDate", value);
      },
    },

    calendarRecords: {
      get() {
        return this.getCalendarTasks;
      },
      set(value) {
        this.$store.commit("calendar/setCalendarTasks", value);
      },
    },
  },

  methods: {
    openSocket(item, action, index) {
      var protocol = "wss://";
      if (window.location.hostname == "localhost") {
        protocol = "ws://";
      }
      let address =
        protocol + window.location.host + "/api/projects/ws/" + item.project_id;
      this.webSocket = new WebSocket(address);
      var that = this;
      this.webSocket.onopen = function () {
        that.sendMessage(item, action, index);
      };
    },

    sendMessage(item, action, index) {
      var project = [
        {
          action: action,
          projectID: item.project_id,
          task: item,
          index: index,
        },
      ];
      let msg = {
        properties: JSON.stringify(project),
      };
      this.webSocket.send(JSON.stringify(msg));
      this.webSocket.close();
      if (action == "calendarRecordCreate") {
        this.updateProjectProperties(item, index);
        for (let i = 0; i < this.getNotSortedCalendars.length; i++) {
          if (this.getNotSortedCalendars[i].id == item.project_id) {
            if (this.getNotSortedCalendars[i].active) {
              this.addRecordToCalendar(item);
            }
          }
        }
      }
    },

    addRecordToCalendar(item) {
      var startTimestamp = this.dateFormater(item.start_date);
      var endTimestamp = this.dateFormater(item.end_date);
      this.addToProjectCalendarList(item, startTimestamp, endTimestamp);
    },

    async createCalendarList() {
      this.$store.dispatch("calendar/getCalendars").then(() => {
        for (let i = 0; i < this.getCalendars.length; i++) {
          for (let j = 0; j < this.getCalendars[i].members.length; j++) {
            if (this.getUser.id == this.getCalendars[i].members[j].user_id) {
              if (this.getCalendars[i].members[j].role == "owner") {
                if (this.getCalendars[i].default_calendar) {
                  this.getCalendarList[0].items.unshift(this.getCalendars[i]);
                } else {
                  this.getCalendarList[0].items.push(this.getCalendars[i]);
                }
                this.getNotSortedCalendars.push(this.getCalendars[i]);
              } else if (this.getCalendars[i].members[j].role == "member") {
                this.getCalendarList[1].items.push(this.getCalendars[i]);
                this.getNotSortedCalendars.push(this.getCalendars[i]);
              }
            }
          }
        }

        this.$store.dispatch("projects/getProjects").then(() => {
          for (let i = 0; i < this.getProjects.length; i++) {
            this.getCalendarList[2].items.push(this.getProjects[i]);
            this.getNotSortedCalendars.push(this.getProjects[i]);
          }
          this.loadCalendarRecords();
          this.loaded = true;
        });
      });
    },

    async clearCalendarData() {
      this.getCalendarList[0].items = [];
      this.getCalendarList[1].items = [];
      this.getCalendarList[2].items = [];
      this.getCalendarList[3].items = [];
    },

    openCreateNewDialog(item) {
      this.parentType = item;
      this.addNewCalendar = true;
      this.createNewMenu = false;
    },

    activeRecordChange(calendar) {
      if (calendar.active) {
        var activeCalendars = [];
        activeCalendars.push(calendar.id);
        this.$store
          .dispatch("calendar/getCalendarRecords", {
            ids: activeCalendars,
          })
          .then((response) => {
            for (let i = 0; i < response.data.length; i++) {
              this.addToCalendarList(
                response.data[i],
                response.data[i].start_date,
                response.data[i].end_date
              );
            }
          });
      } else {
        this.calendarRecords = this.calendarRecords.filter(
          (item) => item.calendar_id !== calendar.id
        );

        for (let i = 0; i < calendar.members.length; i++) {
          if (
            calendar.members[i].user_id == this.getUser.id &&
            calendar.members[i].default_calendar
          ) {
            for (let i = this.calendarRecords.length - 1; i >= 0; i--) {
              if (this.calendarRecords[i].shared_event) {
                this.calendarRecords.splice(i, 1);
              }
            }
          }
        }
      }
    },

    async loadCalendarRecords() {
      this.$store.commit("calendar/setCalendarRecords", []);
      this.$store.commit("calendar/setCalendarProjectRecords", []);

      var activeCalendars = [];
      var activeProjectCalendars = [];
      var list = JSON.parse(JSON.stringify(this.getNotSortedCalendars));
      for (let i = 0; i < list.length; i++) {
        if (list[i].active) {
          if (list[i].properties != undefined) {
            activeProjectCalendars.push(list[i].id);
          } else {
            activeCalendars.push(list[i].id);
          }
        }
      }

      this.$store
        .dispatch("projects/getTasksInProjects", {
          searchType: "project",
          ids: activeProjectCalendars,
        })
        .then((response) => {
          this.$store.commit(
            "calendar/setCalendarProjectRecords",
            response.data
          );
          this.$store
            .dispatch("calendar/getCalendarRecords", {
              ids: activeCalendars,
            })
            .then((response) => {
              this.$store.commit("calendar/setCalendarRecords", response.data);
              this.$store.commit("calendar/setCalendarsLoaded", true);
            });
        });
    },

    calendarDialogDisable() {
      if (this.calendarTitle <= 1) {
        return true;
      } else {
        return false;
      }
    },

    goToProject(item) {
      this.$router.push({
        name: "project",
        params: { id: item.id },
      });
    },

    editCalendar(item) {
      this.calendarTitle = item.title;
      this.calendarDescription = item.description;
      this.newCalendarColor = item.color;
      this.calendarActive = item.active;
      this.calendarId = item.id;
      this.parentType = "calendar";
      this.calendarEditing = true;
      this.addNewCalendar = true;
    },

    activeState(item, parent) {
      this.calendarActive = item.active;
      this.calendarId = item.id;
      this.calendarTitle = item.title;
      this.calendarDescription = item.description;
      this.newCalendarColor = item.color;

      if (parent == "Project Calendars") {
        this.updateProjectCalendar(item, true);
      } else {
        this.updateCalendar(true);
      }
    },

    updateProjectCalendar(item, active) {
      var data = {
        id: item.id,
        color: item.color,
        active: item.active,
      };

      this.$store
        .dispatch("calendar/patchProjectCalendar", data)
        .then((response) => {
          if (active) {
            if (response.data.active) {
              // add
              var activeCalendars = [];
              activeCalendars.push(response.data.id);
              this.$store
                .dispatch("projects/getTasksInProjects", {
                  ids: activeCalendars,
                  searchType: "project",
                })
                .then((response) => {
                  var startTimestamp = "";
                  var endTimestamp = "";
                  for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].trash == false) {
                      if (
                        response.data[i].start_date != "" &&
                        response.data[i].end_date != ""
                      ) {
                        startTimestamp = this.dateFormater(
                          response.data[i].start_date
                        );
                        endTimestamp = this.dateFormater(
                          response.data[i].end_date
                        );
                        this.addToProjectCalendarList(
                          response.data[i],
                          startTimestamp,
                          endTimestamp
                        );
                      } else if (
                        response.data[i].end_date == "" &&
                        response.data[i].start_date != ""
                      ) {
                        startTimestamp = this.dateFormater(
                          response.data[i].start_date
                        );
                        endTimestamp = this.dateFormater(
                          response.data[i].start_date
                        );
                        this.addToProjectCalendarList(
                          response.data[i],
                          startTimestamp,
                          endTimestamp
                        );
                      } else if (
                        response.data[i].start_date == "" &&
                        response.data[i].end_date != ""
                      ) {
                        startTimestamp = this.dateFormater(
                          response.data[i].end_date
                        );
                        endTimestamp = this.dateFormater(
                          response.data[i].end_date
                        );
                        this.addToProjectCalendarList(
                          response.data[i],
                          startTimestamp,
                          endTimestamp
                        );
                      }
                    }
                  }
                });
            } else {
              // delete
              this.calendarRecords = this.calendarRecords.filter(
                (item) => item.project_id !== response.data.id
              );
            }
          } else {
            for (let i = 0; i < this.getCalendarList.length; i++) {
              for (let j = 0; j < this.getCalendarList[i].items.length; j++) {
                if (this.getCalendarList[i].items[j].id == response.data.id) {
                  this.getCalendarList[i].items[j].color = response.data.color;
                }
              }
            }
            for (let i = 0; i < this.getNotSortedCalendars.length; i++) {
              if (this.getNotSortedCalendars[i].id == response.data.id) {
                this.getNotSortedCalendars[i].color = response.data.color;
              }
            }
            for (let i = 0; i < this.getCalendarTasks.length; i++) {
              if (this.getCalendarTasks[i].project_id == response.data.id) {
                this.getCalendarTasks[i].color = response.data.color;
              }
            }
          }
        });
    },

    closeCalendarDialog() {
      this.calendarActive = "";
      this.calendarId = "";
      this.addNewCalendar = false;
      this.calendarEditing = false;
      this.$refs.formCalendar.reset();
    },

    deleteCalendar(item) {
      this.selectedCalendar = item;
      this.dialogDelete = true;
    },

    openShare(item) {
      this.selectedCalendar = item;
      this.addMemberToProject("calendar", item);
    },

    deleteConfirm(item) {
      this.$store.dispatch("calendar/deleteCalendar", item.id).then(() => {
        for (let i = 0; i < this.getCalendarList.length; i++) {
          for (let j = 0; j < this.getCalendarList[i].items.length; j++) {
            if (this.getCalendarList[i].items[j].id == item.id) {
              this.getCalendarList[i].items.splice(
                this.getCalendarList[i].items.indexOf(item),
                1
              );
            }
          }
        }

        this.getNotSortedCalendars.splice(
          this.getNotSortedCalendars.indexOf(item),
          1
        );

        var removeFromCalendar = [];
        for (let i = 0; i < this.getCalendarTasks.length; i++) {
          if (this.getCalendarTasks[i].calendar_id == item.id) {
            removeFromCalendar.push(this.getCalendarTasks[i]);
          }
        }
        this.calendarRecords = this.getCalendarTasks.filter(function (obj) {
          return !this.has(obj.id);
        }, new Set(removeFromCalendar.map((obj) => obj.id)));
      });

      this.selectedCalendar = {};
      this.dialogDelete = false;
    },

    updateProjectColor(color, child) {
      if (color.hex != "#000000") {
        child.color = color.hex;
        this.updateProjectCalendar(child);
        this.menuOpen = false;
      }
    },

    updateColor(color) {
      if (color.hex != "#000000") {
        this.newCalendarColor = color.hex;
      }
    },

    hoverOver(item) {
      if (!this.menuOpen) {
        this.hovering = item;
      }
    },

    hoverLeave() {
      if (!this.menuOpen) {
        this.hovering = "";
      }
    },

    updateCalendar(active) {
      var data = {
        id: this.calendarId,
        title: this.calendarTitle,
        description: this.calendarDescription,
        color: this.newCalendarColor,
        active: this.calendarActive,
      };

      this.$store.dispatch("calendar/patchCalendar", data).then((response) => {
        for (let i = 0; i < this.getCalendarList.length; i++) {
          for (let j = 0; j < this.getCalendarList[i].items.length; j++) {
            if (this.getCalendarList[i].items[j].id == response.data.id) {
              this.getCalendarList[i].items[j] = response.data;
            }
          }
        }
        for (let i = 0; i < this.getNotSortedCalendars.length; i++) {
          if (this.getNotSortedCalendars[i].id == response.data.id) {
            this.getNotSortedCalendars[i] = response.data;
          }
        }
        for (let i = 0; i < this.getCalendarTasks.length; i++) {
          if (
            this.getCalendarTasks[i].calendar_id == response.data.id ||
            this.getCalendarTasks[i].project_id == response.data.id
          ) {
            this.getCalendarTasks[i].parentTitle = response.data.title;
            this.getCalendarTasks[i].color = response.data.color;
          } else if (
            response.data.default_calendar &&
            this.getCalendarTasks[i].shared_event
          ) {
            this.getCalendarTasks[i].parentTitle = response.data.title;
            this.getCalendarTasks[i].color = response.data.color;
          }
        }
        this.addNewCalendar = false;
        this.calendarActive = "";
        this.calendarId = "";
        this.calendarEditing = false;

        if (!active) {
          this.$refs.formCalendar.reset();
        } else {
          this.calendarTitle = "";
          this.calendarDescription = "";
          this.newCalendarColor = "#4CAF50";

          this.activeRecordChange(response.data);
        }
      });
    },

    testLOG(item) {
      console.log("iteeeeeem", item);
    },

    createCalendar() {
      if (this.newCalendarColor == "") {
        this.newCalendarColor = "#B71C1C";
      }
      var resource = false;
      if (this.parentType == "resource") {
        resource = true;
      }

      var data = {
        title: this.calendarTitle,
        description: this.calendarDescription,
        color: this.newCalendarColor,
        resource: resource,
      };
      this.$store.dispatch("calendar/createCalendar", data).then((response) => {
        if (resource) {
          this.getCalendarList[3].items.push(response.data);
        } else {
          this.getCalendarList[0].items.push(response.data);
          this.getNotSortedCalendars.push(response.data);
          this.getCalendars.push(response.data);
        }
        this.addNewCalendar = false;
        this.$refs.formCalendar.reset();
      });
    },
  },
};
</script>

<style lang="scss">
.calWidth {
  width: 100%;
}
.v-application--is-ltr
  .v-list--dense
  .v-list-group--sub-group
  .v-list-group__header {
  padding-left: 16px;
}
</style>