<template>
  <v-dialog
    v-model="addNewCalendar"
    persistent
    max-width="500"
  >
    <v-card class="pa-1">
      <div class="my-5">
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-text-field
            v-model="setCalendarTitle"
            :rules="calendarRules"
            :color="getAppearances.btn_color"
            required
            prepend-icon="mdi-calendar"
            type="text"
            :label="parentType == 'calendar' ? 'Calendar name' : 'Resource name'"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-textarea
            v-model="setCalendarDescription"
            :color="getAppearances.btn_color"
            prepend-icon="mdi-calendar-text"
            auto-grow
            rows="1"
            filled
            :label="parentType == 'calendar' ? 'Calendar description' : 'Resource description'"
          ></v-textarea>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-left pt-0"
        >
          <v-menu
            open-on-hover
            top
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="newCalendarColor"
                class="ml-8"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <div v-if="parentType == 'calendar'">Calendar color</div>
                <div v-else>Resource color</div>
              </v-btn>
            </template>
            <v-color-picker
              hide-canvas
              hide-sliders
              hide-inputs
              :swatches="swatches"
              value="#000"
              show-swatches
              @update:color="updateColor($event)"
            ></v-color-picker>
          </v-menu>
        </v-col>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="mr-1 mb-1"
          @click="closeCalendarDialog()"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="calendarEditing"
          :color="getAppearances.btn_color"
          class="white--text mr-1 mb-1"
          @click="updateCalendar()"
          :disabled="calendarDialogDisable()"
        >
          Update
        </v-btn>
        <v-btn
          v-else
          :color="getAppearances.btn_color"
          class="white--text mr-1 mb-1"
          @click="createCalendar()"
          :disabled="calendarDialogDisable()"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    addNewCalendar: Boolean,
    calendarTitle: String,
    calendarDescription: String,
    newCalendarColor: String,
    swatches: Array,
    updateColor: Function,
    closeCalendarDialog: Function,
    calendarEditing: Boolean,
    updateCalendar: Function,
    calendarDialogDisable: Function,
    createCalendar: Function,
    calendarRules: Array,
    parentType: String,
  },

  data() {
    return {
      //
    };
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    setCalendarTitle: {
      get() {
        return this.calendarTitle;
      },
      set(value) {
        this.$emit("update:calendarTitle", value);
      },
    },

    setCalendarDescription: {
      get() {
        return this.calendarDescription;
      },
      set(value) {
        this.$emit("update:calendarDescription", value);
      },
    },
  },
};
</script>

<style>
</style>