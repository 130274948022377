<template>
  <div class="text-center">
    <v-menu
      v-model="openMenu"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          icon
          v-bind="attrs"
          v-on="on"
          color="grey darken-1"
          @click.stop="selectChannel"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          link
          @click="shareChannel(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    actionStatus: Boolean,
    position: String,
    shareStatus: Boolean,
    thisChannel: Object,
    selectedChannel: Object,
  },

  data: () => ({
    items: [],
  }),

  computed: {
    ...mapGetters("channels", [
      "getDirectMsg",
      "getUnreadPosts",
      "getCurrentChannel",
    ]),
    ...mapGetters("user", ["getUser"]),

    openMenu: {
      get() {
        return this.actionStatus;
      },
      set(value) {
        this.$emit("update:actionStatus", value);
      },
    },

    openShare: {
      get() {
        return this.shareStatus;
      },
      set(value) {
        this.$emit("update:shareStatus", value);
      },
    },

    channelSelected: {
      get() {
        return this.selectedChannel;
      },
      set(value) {
        this.$emit("update:selectedChannel", value);
      },
    },
  },

  created() {
    if (this.position == "direct") {
      this.items.push({ title: "Close Conversation" });
    } else {
      this.items.push({ title: "Add Members" });
    }
  },

  methods: {
    selectChannel() {
      this.channelSelected = this.thisChannel;
      // this.setSelectedChannelStore = this.thisChannel;
    },

    shareChannel(channel) {
      switch (channel.title) {
        case "Close Conversation":
          console.log("Close Conversation");
          //jāpabeidz ar chanel id
          for (let i = 0; i < this.getDirectMsg.length; i++) {
            for (
              let j = 0;
              j < this.getDirectMsg[i].channel_members.length;
              j++
            ) {
              if (
                this.getDirectMsg[i].channel_members[j].user_id ==
                  this.getUser.id &&
                this.thisChannel.id ==
                  this.getDirectMsg[i].channel_members[j].channel_id
              ) {
                this.getDirectMsg[i].channel_members[j].deleted = true;
              }
            }
          }
          break;
        case "Add Members":
          this.openShare = true;
          console.log("Share");

          if (this.getCurrentChannel == null) {
            return;
          }

          if (this.getCurrentChannel.id == this.channelSelected.id) {
            return;
          }

          this.$store.commit("channels/setCurrentChannelPosts", {});
          this.$store.commit(
            "channels/setCurrentChannel",
            this.channelSelected
          );
          this.$router.push({
            name: "messages",
            params: { id: this.channelSelected.id },
          });

          break;
        case "Delete Channel":
          console.log("Delete");
          break;

        default:
          console.log("Default");
          break;
      }
    },
  },
};
</script>