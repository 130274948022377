<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    persistent
    transition
  >
    <v-card>
      <v-card-title class="headline">Choose a file</v-card-title>
      <v-card-text>
        <v-scroll-y-transition
          mode="out-in"
          hide-on-leave
        >
          <v-treeview
            :active.sync="active"
            :items="treeview"
            :load-children="loadChildren"
            :open.sync="open"
            v-on:update:active="activeChanged"
            item-key="id"
            item-disabled="locked"
            activatable
            hoverable
            return-object
            color="warning"
            transition
            :multiple-active="parent == 'calendar' ? true : false"
          >
            <template v-slot:prepend="{ item }">
              <v-icon
                v-if="fileTypes[item.type] !== undefined && fileTypes[item.type].fileType !== 'image'"
                size="24"
                dark
                :color="fileTypes[item.type].color"
              >
                {{ fileTypes[item.type].icon }}
              </v-icon>
              <v-img
                v-else-if="fileTypes[item.type] !== undefined && fileTypes[item.type].fileType == 'image'"
                max-height="24"
                max-width="24"
                :src="`/api/files/thumbnails/${item.id}`"
              >
              </v-img>
              <v-icon
                v-else
                size="24"
                dark
                :color="fileTypes['unknown'].color"
              >
                {{ fileTypes['unknown'].icon }}
              </v-icon>
            </template>
          </v-treeview>
        </v-scroll-y-transition>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="getAppearances.btn_color"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="disable"
          :color="getAppearances.btn_color"
          class="white--text"
          @click="selectItem"
        >
          Select
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { utils } from "../../mixins/utils";
import { mapGetters } from "vuex";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    parent: String
  },
  data: () => ({
    treeview: [],
    open: [],
    active: [],
    items: [],
    disable: true,
  }),

  mixins: [utils],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("files", ["fileTypes"]),
  },

  created() {
    this.$store.dispatch("files/getDrive").then((response) => {
      response.data[0].children = [];
      this.treeview = response.data;
    });
  },

  methods: {
    async loadChildren(item) {
      await this.$store.dispatch("files/getFiles", item.id).then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].isFolder) {
            response.data[i].children = [];
          }
        }
        item.children.push(...response.data);
      });
    },

    loadFiles() {},

    activeChanged(active) {
      if (this.parent == 'calendar') {
        for (let i = 0; i < active.length; i++) {
          if (active[i].isFolder || active[i].type == "cloud#drive") {
            active.splice(active.indexOf(active[i]), 1);
          }
        }
        this.disable = false;
      } else {
        if (active[0].isFolder || active[0].type == "cloud#drive") {
          this.disable = true;
          return;
        }
        this.disable = false;
      }
    },

    selectItem() {
      if (this.parent == 'calendar') {
        this.$emit("accept", this.active);
      } else {
        this.$emit("accept", this.active[0]);
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
