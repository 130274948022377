<template>
  <router-view
    v-if="isReadyToLoad"
    :key="$route.fullPath"
  ></router-view>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: function () {
    return {};
  },

  computed: {
    ...mapGetters("channels", ["isReadyToLoad"]),
  },
};
</script>
