<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!addUser"
        icon
        v-bind="attrs"
        v-on="on"
        @click="loadUsers"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        v-if="addUser && listShare"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account-group</v-icon>
      </v-btn>
      <v-list-item
        v-else-if="
          addUser && getCurrentChannel && getCurrentChannel.type == 'P'
        "
        v-bind="attrs"
        v-on="on"
        @click="loadUsers"
      >
        <v-list-item-avatar size="24">
          <v-icon>mdi-plus</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Add Members</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="text-h5"> {{ cardTitle }} </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="selected"
          :multiple="addUser"
          :items="users"
          filled
          chips
          color="blue-grey lighten-2"
          label="Select"
          return-object
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item)"
            >
              <v-avatar left>
                <v-img :src="`/api/users/photo/${data.item.photo}`"></v-img>
              </v-avatar>
              {{ getFullName(data.item) }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <img :src="`/api/users/photo/${data.item.photo}`" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  getFullName(data.item)
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="cancel"
        > Cancel </v-btn>
        <v-btn
          color="primary"
          @click="save"
        > Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    addUser: Boolean,
    listShare: Boolean,
  },

  data: function () {
    return {
      selected: null,
      users: [],
      dialog: false,
    };
  },

  computed: {
    ...mapGetters("channels", ["getCurrentChannel"]),

    cardTitle() {
      return this.addUser == true ? "Add user" : "Create a new direct message";
    },
  },

  methods: {
    loadUsers() {
      this.$store.dispatch("user/getUsers").then((response) => {
        this.users = response.data;
      });
    },

    getFullName(item) {
      return item.name + "  " + item.lastname;
    },

    save() {
      this.dialog = false;
      this.$emit("click", this.selected);
      this.selected = null;
    },

    cancel() {
      this.dialog = false;
      this.selected = null;
    },
  },
};
</script>
