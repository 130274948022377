<template>
  <div>
    <v-row :class="calendarItem.type != 'subtask' ? 'mx-0 my-4 mb-3 d-flex align-center' : 'mx-0 my-4 mb-3 d-flex align-center'">
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4 mt-n1"
          size="20"
          style="overflow: visible;"
        >
          mdi-calendar-clock-outline
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        class="py-0"
      >
        <v-row :class="$vuetify.breakpoint.mobile ? 'pa-0 mx-5' : 'pa-0 mx-3'">
          <v-menu
            v-model="setStartDateMenu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
            nudge-left='40'
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                style="font-size: 13px;"
                class="txt"
                v-bind="attrs"
                v-on="on"
              >
                {{ formatDialogDates(calendarItem.start) +' '+  timeFormat(setStartTime) }}
              </div>
            </template>
            <v-date-picker
              v-model="setFromDate"
              no-title
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                class="mr-1 mb-2"
                @click="setStartDateMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                :color="getAppearances.btn_color"
                class="mb-2 mr-2"
                dark
                @click="saveStartDate()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <div
            class="mx-2"
            style="margin-top: -2px;"
          >-</div>
          <v-menu
            v-model="setEndDateMenu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
            nudge-left='40'
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                style="font-size: 13px;"
                class="txt"
                v-bind="attrs"
                v-on="on"
              >
                {{ formatDialogDates(calendarItem.end) +' '+  timeFormat(setEndTime) }}
              </div>
            </template>
            <v-date-picker
              v-model="setTillDate"
              no-title
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                class="mr-1 mb-2"
                @click="setEndDateMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                :color="getAppearances.btn_color"
                class="mb-2 mr-2"
                dark
                @click="saveEndDate()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-spacer />
          <div class="mt-n1">
            <TimeDialog :calendarItem="calendarItem" />
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="calendarItem.type != 'subtask'"
      class="mx-0 my-3 d-flex align-center"
    >
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4"
          size="20"
          style="overflow: visible;"
        >
          mdi-text
        </v-icon>
      </v-col>
      <v-col
        v-if="setShowFields == false"
        cols="11"
        class="py-0"
      >
        <v-row :class="$vuetify.breakpoint.mobile ? 'ma-0 mx-5' : 'ma-0 mx-3'">
          <div
            style="font-size: 13px;"
            class="mr-1"
          >
            Add
          </div>
          <div
            style="font-size: 13px;"
            class="txt mr-1"
            @click="setShowFields = true"
          >
            description
          </div>
          <div
            class="mr-1"
            style="font-size: 13px;"
          >
            or
          </div>
          <div
            style="font-size: 13px;"
            class="txt"
            @click="setShowFields = true"
          >
            attachment
          </div>
        </v-row>
      </v-col>
      <v-row
        v-else
        class="row ma-0 pb-2"
        :class="$vuetify.breakpoint.mobile ? 'ma-0 mr-4 ml-8' : 'ma-0 ml-6 mr-4'"
      >
        <v-textarea
          v-model="calendarItem.description"
          class="placeholderColor"
          hide-details="auto"
          rows="1"
          filled
          dense
          flat
        ></v-textarea>
      </v-row>
    </v-row>
    <v-row
      v-if="setShowFields || showFieldsChecker()"
      class="ma-0 pa-0 mt-n2 mb-4"
    >
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4"
          size="20"
          style="overflow: visible;"
        >
          mdi-paperclip
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        class="py-0"
      >
        <div
          style="font-size: 13px;"
          @click="setFileDialog = true"
          :class="$vuetify.breakpoint.mobile ? 'ma-0 mr-2 ml-4' : 'ma-0 mr-1 ml-3 mt-1'"
        >
          Add attachment
        </div>
        <FileSelector
          :dialog="setFileDialog"
          @close="closeFileSelector"
          @accept="attachFile"
          :parent="'calendar'"
        />
        <FileList :files.sync="calendarItem.files" />
      </v-col>
    </v-row>
    <v-row
      v-if="$route.name == 'calendar'"
      class="mx-0 mt-n2 mb-1 d-flex align-center"
    >
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4"
          size="20"
          style="overflow: visible;"
        >
          mdi-calendar
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        class="py-0"
      >
        <v-select
          v-model="calendarItem.calendar"
          :items="getNotSortedCalendars"
          :class="$vuetify.breakpoint.mobile ? 'placeholderColor ml-2' : 'placeholderColor'"
          @change="calendarSlected"
          :rules="calendarListRules"
          :disabled="action == 'update' ? true : false"
          required
          hide-details="auto"
          style="font-size: 13px;"
          placeholder="Select calendar"
          return-object
          item-text="title"
          dense
          flat
          solo
        ></v-select>
      </v-col>
    </v-row>
    <v-row
      v-if="sectionShow || calendarItem.parentType == 'project'"
      class="mx-0 mt-n1 mb-1 d-flex align-center"
    >
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4"
          size="20"
          style="overflow: visible;"
        >
          mdi-format-list-bulleted-square
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        class="py-0"
      >
        <v-row class="row ma-0">
          <v-select
            v-model="calendarItem.selectedSection"
            :items="calendarItem.sections"
            :class="$vuetify.breakpoint.mobile ? 'placeholderColor ml-2' : 'placeholderColor'"
            hide-details="auto"
            style="font-size: 13px;"
            placeholder="Select section"
            :disabled="calendarItem.parentType == 'project' ? true : false"
            item-value="index"
            item-text="name"
            dense
            flat
            solo
          ></v-select>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FileSelector from "../../Dialogs/fileSelector.vue";
import FileList from "../../Calendar/FileList.vue";
import TimeDialog from "../../Calendar/Dialogs/TimeDialog.vue";
export default {
  components: {
    FileSelector,
    FileList,
    TimeDialog,
  },
  props: {
    date: String,
    startDateMenu: Boolean,
    fromDate: String,
    formatDialogDates: Function,
    calendarItem: Object,
    saveStartDate: Function,
    endDateMenu: Boolean,
    tillDate: String,
    saveEndDate: Function,
    calendarSlected: Function,
    showFields: Boolean,
    showFieldsChecker: Function,
    fileDialog: Boolean,
    attachFile: Function,
    action: String,
    sectionShow: Boolean,
  },

  data() {
    return {
      places: ["Ogre", "Rīga"],
      calendarListRules: [(v) => !!v || "Calendar is required"],
    };
  },

  computed: {
    ...mapGetters("user", ["getClockDisplay"]),
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("calendar", [
      "getNotSortedCalendars",
      "getStartTime",
      "getEndTime",
    ]),

    setStartTime: {
      get() {
        return this.getStartTime;
      },
      set(value) {
        this.$store.commit("calendar/setStartTime", value);
      },
    },

    setEndTime: {
      get() {
        return this.getEndTime;
      },
      set(value) {
        this.$store.commit("calendar/setEndTime", value);
      },
    },

    setFileDialog: {
      get() {
        return this.fileDialog;
      },
      set(value) {
        this.$emit("update:fileDialog", value);
      },
    },

    setShowFields: {
      get() {
        return this.showFields;
      },
      set(value) {
        this.$emit("update:showFields", value);
      },
    },

    setEndDateMenu: {
      get() {
        return this.endDateMenu;
      },
      set(value) {
        this.$emit("update:endDateMenu", value);
      },
    },

    setStartDateMenu: {
      get() {
        return this.startDateMenu;
      },
      set(value) {
        this.$emit("update:startDateMenu", value);
      },
    },

    setFromDate: {
      get() {
        return this.fromDate;
      },
      set(value) {
        this.$emit("update:fromDate", value);
      },
    },

    setTillDate: {
      get() {
        return this.tillDate;
      },
      set(value) {
        this.$emit("update:tillDate", value);
      },
    },
  },

  methods: {
    timeFormat(item) {
      if (this.getClockDisplay === "24h") {
        return item;
      }

      if (!item) {
        return item;
      }

      const [hours, minutes] = item.split(":");
      let hoursInt = parseInt(hours);
      const amOrPm = hoursInt >= 12 ? "PM" : "AM";
      if (hoursInt > 12) {
        hoursInt -= 12;
      } else if (hoursInt === 0) {
        hoursInt = 12;
      }
      const formattedTime = `${hoursInt}:${minutes} ${amOrPm}`;

      return formattedTime;
    },

    closeFileSelector() {
      this.setFileDialog = false;
    },
  },
};
</script>

<style scoped>
.placeholderColor ::placeholder {
  color: #000000 !important;
}

.txt:hover {
  text-decoration: underline;
}
</style>