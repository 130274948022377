<template>
  <v-row
    v-if="loaded"
    justify="center"
  >
    <v-dialog
      v-model="dialog"
      persistent
      max-width="450"
    >
      <v-card rounded>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-card-actions class="my-0 pb-0">
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-list class="pt-0">
            <v-list-item>
              <v-list-item-icon class="mr-4">
              </v-list-item-icon>
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <v-text-field
                    v-model="calendarItem.title"
                    :rules="titleRules"
                    required
                    label="Add title"
                  ></v-text-field>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-tabs
              v-model="setRecordDialogTab"
              hide-slider
              align-with-title
              active-class="active"
              class="pl-3"
              height="25"
            >
              <v-tab
                href="#tab-1"
                class="text-uppercase"
                style="min-width:23px; padding: 6px"
                :disabled="setDisableEvents"
                @click="clearSeletedCalendar()"
              >
                EVENT
              </v-tab>
              <v-tab
                href="#tab-2"
                class="text-uppercase"
                style="min-width:23px; padding: 6px"
                :disabled="setDisableTasks"
              >
                TASK
              </v-tab>
              <v-tab
                href="#tab-3"
                class="text-uppercase"
                style="min-width:23px; padding: 6px"
                :disabled="true"
              >
                REMINDER
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="setRecordDialogTab"
              touchless
            >
              <v-tab-item value="tab-1">
                <div v-if="setRecordDialogTab == 'tab-1'">
                  <EventTab
                    :date.sync="date"
                    :startDateMenu.sync="startDateMenu"
                    :fromDate.sync="fromDate"
                    :formatDialogDates.sync="formatDialogDates"
                    :calendarItem.sync="calendarItem"
                    :saveStartDate.sync="saveStartDate"
                    :endDateMenu.sync="endDateMenu"
                    :tillDate.sync="tillDate"
                    :saveEndDate.sync="saveEndDate"
                    :location.sync="location"
                    :calendarSlected.sync="calendarSlected"
                    :showFields.sync="showFields"
                    :showFieldsChecker.sync="showFieldsChecker"
                    :fileDialog.sync="fileDialog"
                    :attachFile.sync="attachFile"
                    :action.sync="action"
                    :videoMeetingText.sync="videoMeetingText"
                    :type="type"
                  />
                </div>
              </v-tab-item>
              <!-- TAB 2 -->
              <v-tab-item value="tab-2">
                <div v-if="setRecordDialogTab == 'tab-2'">
                  <TaskTab
                    :date.sync="date"
                    :startDateMenu.sync="startDateMenu"
                    :fromDate.sync="fromDate"
                    :formatDialogDates.sync="formatDialogDates"
                    :calendarItem.sync="calendarItem"
                    :saveStartDate.sync="saveStartDate"
                    :endDateMenu.sync="endDateMenu"
                    :tillDate.sync="tillDate"
                    :saveEndDate.sync="saveEndDate"
                    :calendarSlected.sync="calendarSlected"
                    :showFields.sync="showFields"
                    :showFieldsChecker.sync="showFieldsChecker"
                    :fileDialog.sync="fileDialog"
                    :attachFile.sync="attachFile"
                    :action.sync="action"
                    :sectionShow.sync="sectionShow"
                  />
                </div>
              </v-tab-item>
              <v-tab-item
                v-if="setRecordDialogTab == 'tab-3'"
                value="tab-3"
              >
                <ReminderTab />
              </v-tab-item>
            </v-tabs-items>
          </v-list>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="action == 'new'"
              :color="getAppearances.btn_color"
              class="text-capitalize mb-2 mr-2"
              dark
              @click="save"
            >
              Save
            </v-btn>
            <v-btn
              v-else
              :color="getAppearances.btn_color"
              class="text-capitalize mb-2 mr-2"
              dark
              @click="update"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { projectUtils } from "../../../mixins/project";
import { calendar } from "../../../mixins/calendar";
import EventTab from "../Dialogs/EventTab.vue";
import TaskTab from "../Dialogs/TaskTab.vue";
import ReminderTab from "../Dialogs/ReminderTab.vue";

export default {
  components: {
    EventTab,
    TaskTab,
    ReminderTab,
  },
  props: {
    action: String,
    parent: String,
    createDialog: Boolean,
    calendarItem: Object,
    timedEvent: Boolean,
    selectedCalendarValue: Object,
    selectedSectionValue: Number,
    recordDialogTab: String,
    disableEvents: Boolean,
    disableTasks: Boolean,
    type: String,
  },

  data() {
    return {
      location: "",
      videoMeetingText: "Add video meeting",
      valid: true,
      loaded: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      fromDate: "",
      tillDate: "",
      startDateMenu: false,
      endDateMenu: false,
      calendar: "Project select",
      repeat: "Daily",
      repeatCheckbox: true,
      sectionShow: false,
      titleRules: [(v) => !!v || "Title is required"],
      calendarListRules: [(v) => !!v || "Calendar is required"],
      showFields: false,
      file: null,
      fileDialog: false,
      fileIDS: [],
    };
  },

  mixins: [projectUtils, calendar],

  watch: {
    dialog() {
      if (this.loaded && this.$refs.form != undefined) {
        this.$refs.form.resetValidation();
        this.calendarItem.selectedSection = 1;
      }
    },
  },

  computed: {
    ...mapGetters("user", ["getUsers"]),
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("projects", ["getColumns", "getTask"]),
    ...mapGetters("calendar", [
      "getCalendarTasks",
      "getNotSortedCalendars",
      "getCalendars",
      "getStartTime",
      "getEndTime",
    ]),

    setStartTime: {
      get() {
        return this.getStartTime;
      },
      set(value) {
        this.$store.commit("calendar/setStartTime", value);
      },
    },

    setEndTime: {
      get() {
        return this.getEndTime;
      },
      set(value) {
        this.$store.commit("calendar/setEndTime", value);
      },
    },

    dialog: {
      get() {
        return this.createDialog;
      },
      set(value) {
        this.$emit("update:createDialog", value);
      },
    },

    setTimedEvent: {
      get() {
        return this.timedEvent;
      },
      set(value) {
        this.$emit("update:timedEvent", value);
      },
    },

    setRecordDialogTab: {
      get() {
        return this.recordDialogTab;
      },
      set(value) {
        this.$emit("update:recordDialogTab", value);
      },
    },

    setDisableEvents: {
      get() {
        return this.disableEvents;
      },
      set(value) {
        this.$emit("update:disableEvents", value);
      },
    },

    setDisableTasks: {
      get() {
        return this.disableTasks;
      },
      set(value) {
        this.$emit("update:disableTasks", value);
      },
    },
  },

  mounted() {
    this.buildCalendarData();
  },

  methods: {
    showFieldsChecker() {
      if (this.action == "update") {
        if (
          this.calendarItem.description != "" ||
          this.calendarItem.files.length != 0
        ) {
          this.showFields = true;
          return true;
        }
      } else {
        if (this.showFields) {
          return true;
        } else {
          return false;
        }
      }
    },

    clearSeletedCalendar() {
      let matchFound = false;
      for (let i = 0; i < this.getCalendars.length; i++) {
        if (this.calendarItem.calendar.id === this.getCalendars[i].id) {
          matchFound = true;
          break;
        }
      }
      if (!matchFound) {
        this.calendarItem.calendar = "";
        this.sectionShow = false;
      }
    },

    update() {
      var guests = [];

      if (this.calendarItem.guests != undefined) {
        for (let i = 0; i < this.calendarItem.guests.length; i++) {
          guests.push(this.calendarItem.guests[i].id);
        }
      }

      var jsonGuests = JSON.stringify(guests);
      var timed = this.calendarItem.timed;
      var startTime = moment(this.calendarItem.start).format("X");
      var endTime = moment(this.calendarItem.end).format("X");

      if (this.setStartTime != "" && this.setEndTime != "") {
        timed = true;

        let startDate = moment(this.calendarItem.start);
        startDate.hour(parseInt(this.setStartTime.split(":")[0]));
        startDate.minute(parseInt(this.setStartTime.split(":")[1]));
        startTime = startDate.unix();

        let endDate = moment(this.calendarItem.start);
        endDate.hour(parseInt(this.setEndTime.split(":")[0]));
        endDate.minute(parseInt(this.setEndTime.split(":")[1]));
        endTime = endDate.unix();
      }

      var calendarID = this.calendarItem.calendar.id;
      if (this.calendarItem.shared_event) {
        calendarID = this.calendarItem.originalCalendarID;
      }

      var data = {
        id: this.calendarItem.id,
        calendar_id: calendarID,
        type: this.calendarItem.type,
        description: this.calendarItem.description,
        name: this.calendarItem.title,
        start: startTime * 1000,
        end: endTime * 1000,
        timed: timed,
        guests: jsonGuests,
        status: this.calendarItem.status,
        files: this.calendarItem.files,
        parentType: this.calendarItem.parentType,
        shared_event: this.calendarItem.shared_event,
      };

      this.updateCalendarRecord(data, "date");
      this.closeDialog();
    },

    calendarSlected() {
      this.calendarItem.sections = [];
      if (this.calendarItem.calendar.properties != undefined) {
        var properties = JSON.parse(this.calendarItem.calendar.properties);
        for (let i = 0; i < properties[0].length; i++) {
          this.calendarItem.sections.push(properties[0][i]);
        }
        this.sectionShow = true;
      } else {
        this.sectionShow = false;
      }
    },

    buildCalendarData() {
      if (this.$route.name == "project") {
        this.loadDate();
      } else {
        this.loadCalendarList();
      }
    },

    loadCalendarList() {
      this.setRecordDialogTab = "tab-1";
      this.setDisableEvents = false;
      this.loaded = true;
    },

    saveStartDate() {
      var item = this.calendarItem;
      item.start = this.fromDate;

      this.$emit("update:calendarItem", item);
      this.startDateMenu = false;
    },

    saveEndDate() {
      var item = this.calendarItem;
      item.end = this.tillDate;

      this.$emit("update:calendarItem", item);
      this.endDateMenu = false;
    },

    attachFile(data) {
      this.calendarItem.files.push(...data);
      for (let i = 0; i < data.length; i++) {
        this.fileIDS.push(data[i].id);
      }
      this.fileDialog = false;
    },

    loadDate() {
      this.setRecordDialogTab = "tab-2";
      this.setDisableEvents = true;
      this.sectionShow = true;
      this.loaded = true;
    },

    formatDialogDates(item) {
      return moment(item).format("D MMM, YYYY");
    },

    closeDialog() {
      this.$emit("update:action", "new");
      this.videoMeetingText = "Add video meeting";
      this.location = "";
      this.fileIDS = [];
      this.showFields = false;
      this.dialog = false;
      this.setDisableEvents = false;
      this.setDisableTasks = false;
      this.setStartTime = "";
      this.setEndTime = "";
      this.setTimedEvent = false;
      const i = this.getCalendarTasks.indexOf(this.calendarItem);
      if (i !== -1) {
        this.getCalendarTasks.splice(i, 1);
      }
    },

    save() {
      if (this.$refs.form.validate()) {
        if (
          this.setRecordDialogTab == "tab-2" &&
          this.calendarItem.title != ""
        ) {
          var task = {};
          if (this.$route.name == "calendar") {
            task = {
              title: this.calendarItem.title,
              position: "top",
              date: moment(this.calendarItem.end).format("X"),
              index: this.calendarItem.selectedSection,
              type: "task", // jāizlabo uz dinamisku
              startDate: moment(this.calendarItem.start).format("X"),
              endDate: moment(this.calendarItem.end).format("X"),
              timed: this.setTimedEvent,
              description: this.calendarItem.description,
              file_ids: this.fileIDS,
              files: this.calendarItem.files,
              calendarID: this.calendarItem.calendar.id,
            };
          } else {
            task = {
              title: this.calendarItem.title,
              position: "top",
              date: moment(this.calendarItem.end).format("X"),
              index: this.calendarItem.selectedSection,
              type: "task", // jāizlabo uz dinamisku
              startDate: moment(this.calendarItem.start).format("X"),
              endDate: moment(this.calendarItem.end).format("X"),
              timed: this.setTimedEvent,
              description: this.calendarItem.description,
              file_ids: this.fileIDS,
              files: this.calendarItem.files,
            };
          }

          const i = this.getCalendarTasks.indexOf(this.calendarItem);
          if (i !== -1) {
            this.getCalendarTasks.splice(i, 1);
          }

          if (this.sectionShow == true) {
            if (this.$route.name == "calendar") {
              this.$store.commit(
                "projects/setProject",
                this.calendarItem.calendar
              );
            }
            this.createTask(task, true);
          } else {
            // calendar items
            this.$store
              .dispatch("calendar/createCalendarRecord", task)
              .then((response) => {
                if (
                  response.data != null &&
                  this.calendarStatusActive(response.data.calendar_id)
                ) {
                  if (response.data.files == null) {
                    response.data.files = [];
                  }
                  this.addToCalendarList(
                    response.data,
                    response.data.start_date,
                    response.data.end_date
                  );
                }
                // šito te vajag? kka neiebraucu kapec te x2
                const i = this.getCalendarTasks.indexOf(this.calendarItem);
                if (i !== -1) {
                  this.getCalendarTasks.splice(i, 1);
                }
              });
          }
        } else if (
          this.setRecordDialogTab == "tab-1" &&
          this.calendarItem.title != ""
        ) {
          var guests = [];

          if (
            this.calendarItem.guests != undefined &&
            this.calendarItem.guests.length != 0
          ) {
            for (let i = 0; i < this.calendarItem.guests.length; i++) {
              guests.push(this.calendarItem.guests[i].id);
            }
          }

          var timed = this.setTimedEvent;
          var startTime = moment(this.calendarItem.start).format("X");
          var endTime = moment(this.calendarItem.end).format("X");

          if (this.setStartTime != "" && this.setEndTime != "") {
            timed = true;

            let startDate = moment(this.calendarItem.start);
            startDate.hour(parseInt(this.setStartTime.split(":")[0]));
            startDate.minute(parseInt(this.setStartTime.split(":")[1]));
            startTime = startDate.unix();

            let endDate = moment(this.calendarItem.start);
            endDate.hour(parseInt(this.setEndTime.split(":")[0]));
            endDate.minute(parseInt(this.setEndTime.split(":")[1]));
            endTime = endDate.unix();
          }

          var jsonGuests = JSON.stringify(guests);

          var event = {
            title: this.calendarItem.title,
            position: "top",
            date: moment(this.calendarItem.end).format("X"),
            index: this.calendarItem.selectedSection,
            type: "event",
            location: "Ogre",
            guests: jsonGuests,
            startDate: startTime.toString(),
            endDate: endTime.toString(),
            timed: timed,
            description: this.calendarItem.description,
            file_ids: this.fileIDS,
            files: this.calendarItem.files,
            calendarID: this.calendarItem.calendar.id,
          };

          const i = this.getCalendarTasks.indexOf(this.calendarItem);
          if (i !== -1) {
            this.getCalendarTasks.splice(i, 1);
          }

          this.$store
            .dispatch("calendar/createCalendarRecord", event)
            .then((response) => {
              if (
                response.data != null &&
                this.calendarStatusActive(response.data.calendar_id)
              ) {
                if (response.data.files == null) {
                  response.data.files = [];
                }
                this.addToCalendarList(
                  response.data,
                  response.data.start_date,
                  response.data.end_date
                );
              }
            });
        }
        this.fileIDS = [];
        if (this.$route.name == "calendar") {
          this.sectionShow = false;
        }
        this.videoMeetingText = "Add video meeting";
        this.location = "";
        this.showFields = false;
        this.dialog = false;

        this.setTimedEvent = false;
        this.setStartTime = "";
        this.setEndTime = "";
      }
    },
  },
};
</script>

<style scoped>
.active {
  background-color: rgb(245, 245, 245) !important;
}
</style>