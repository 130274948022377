import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    calendarTasks: [],
    websocketTask: {},
    calendars: [],
    notSortedCalendars: [],
    calendarList: [
      {
        items: [],
        title: "My Calendars",
        active: true,
      },
      {
        items: [],
        title: "Shared Calendars",
        active: true,
      },
      {
        items: [],
        title: "Project Calendars",
        active: true,
      },
      {
        items: [],
        title: "Resources",
        active: true,
      },
    ],
    calendarRecords: [],
    calendarProjectRecords: [],
    calendarsLoaded: false,
    calendarRecordUpdated: false,
    wsTrashTask: false,
    recordDeleteKey: 66,
    workloadRecoreds: [],
    startTime: '',
    endTime: '',
  },

  getters: {
    getCalendarTasks(state) {
      return state.calendarTasks;
    },
    getCalendarWsTask(state) {
      return state.websocketTask;
    },
    getCalendars(state) {
      return state.calendars;
    },
    getCalendarList(state) {
      return state.calendarList;
    },
    getCalendarDate(state) {
      return state.date;
    },
    getNotSortedCalendars(state) {
      return state.notSortedCalendars;
    },
    getCalendarRecords(state) {
      return state.calendarRecords;
    },
    getCalendarProjectRecords(state) {
      return state.calendarProjectRecords;
    },
    getCalendarsLoaded(state) {
      return state.calendarsLoaded;
    },
    getCalendarRecordUpdated(state) {
      return state.calendarRecordUpdated;
    },
    getWsTrashTask(state) {
      return state.wsTrashTask;
    },
    getRecordDeleteKey(state) {
      return state.recordDeleteKey;
    },
    getWorkloadRecoreds(state) {
      return state.workloadRecoreds;
    },
    getStartTime(state) {
      return state.startTime;
    },
    getEndTime(state) {
      return state.endTime;
    },
  },

  mutations: {
    addResourceCalendar(state, calendar) {
      state.calendarList[3].items.push(calendar);
    },
    setCalendarTasks(state, item) {
      state.calendarTasks = item;
    },
    setCalendarWsTask(state, item) {
      state.websocketTask = item;
    },
    setCalendars(state, item) {
      state.calendars = item;
    },
    setCalendarList(state, item) {
      state.calendarList = item;
    },
    setCalendarDate(state, item) {
      state.date = item;
    },
    setNotSortedCalendars(state, item) {
      state.notSortedCalendars = item;
    },
    setCalendarRecords(state, item) {
      state.calendarRecords = item;
    },
    setCalendarProjectRecords(state, item) {
      state.calendarProjectRecords = item;
    },
    setCalendarsLoaded(state, item) {
      state.calendarsLoaded = item;
    },
    setCalendarRecordUpdated(state, item) {
      state.calendarRecordUpdated = item;
    },
    setWsTrashTask(state, item) {
      state.wsTrashTask = item;
    },
    setRecordDeleteKey(state, item) {
      state.recordDeleteKey = item;
    },
    setWorkloadRecoreds(state, item) {
      state.workloadRecoreds = item;
    },
    setStartTime(state, item) {
      state.startTime = item;
    },
    setEndTime(state, item) {
      state.endTime = item;
    },
  },

  actions: {
    createCalendar(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/calendar/create',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            title: data.title,
            description: data.description,
            color: data.color,
            resource: data.resource
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    patchCalendar(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/calendar/' + data.id + '/patch',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            title: data.title,
            description: data.description,
            color: data.color,
            active: data.active
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    patchProjectCalendar(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/calendar/' + data.id + '/project/patch',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            color: data.color,
            active: data.active
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    async getCalendars(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/calendar/my-calendars',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            const calendars = response.data.filter(calendar => !calendar.resource);
            context.commit('setCalendars', calendars);
            for (let i = 0; i < response.data.length; i++) {
              const calendar = response.data[i];
              if (calendar.resource) {
                context.commit('addResourceCalendar', calendar);
              }
            }
            resolve();
          }).catch(error => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      });
    },

    addMemberToCalendar(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/calendar/' + data.calendar_id + '/share',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            members: data.members,
          }
        };
        axios(options)
          .then((response) => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    deleteCalendar(context, id) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/calendar/' + id + '/delete',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(() => {
            resolve();
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    removeCalendarMember(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/calendar/' + data.calendar_id + '/member/remove',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            member: data.member,
          }
        };
        axios(options)
          .then((response) => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    createCalendarRecord(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/calendar/' + data.calendarID + '/create',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            type: data.type,
            description: data.description,
            title: data.title,
            assigned_to: data.assigned_to,
            start_date: data.startDate,
            end_date: data.endDate,
            timed: data.timed,
            file_ids: data.file_ids,
            guests: data.guests
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
            if (response.data == null) {
              context.dispatch('alert/showAlert', {
                color: 'red',
                text: 'null response'
              }, { root: true })
            }
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    getCalendarRecords(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/calendar/records',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            ids: data.ids
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    getWorkloadRecords(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/calendar/workload',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            ids: data.ids,
            start: data.start,
            end: data.end
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
            // context.commit('setWorkloadRecoreds', response.data)
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    deleteRecord(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/calendar/' + data.calendarID + '/record/' + data.recordID + '/delete',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },

    patchRecord(context, data) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/calendar/' + data.calendarID + '/record/' + data.id + '/patch',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            type: data.type,
            description: data.description,
            title: data.title,
            startDate: data.startDate,
            endDate: data.endDate,
            timed: data.timed,
            guests: data.guests,
            status: data.status,
            files: data.files,
            sharedEvent: data.shared_event
          }
        };
        axios(options)
          .then(response => {
            resolve(response);
          }).catch((error) => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: error.response.data
            }, { root: true })
          });
      })
    },
  },

  namespaced: true,
})
