<template>
  <v-container
    class="py-0 px-0"
    fluid
  >
    <v-row
      v-if="!connectionFailed"
      class="fill-height"
      no-gutters
      fluid
    >
      <template>
        <v-col>
          <ProjectToolbar />
          <v-tabs
            show-arrows
            v-model="model"
            :slider-color="getAppearances.btn_color"
            color="grey darken-1"
            height="30"
            class="d-none d-sm-block"
          >
            <v-tab
              class="mt-n1 text-capitalize"
              href="#tab-1"
              @click="closeSideBars(), setEditing('overview')"
            >
              OVERVIEW
            </v-tab>
            <v-tab
              class="mt-n1 text-capitalize"
              @click="setEditing('list')"
              href="#tab-2"
            >
              LIST
            </v-tab>
            <v-tab
              class="mt-n1 text-capitalize"
              @click="setEditing('board')"
              href="#tab-3"
            >
              BOARD
            </v-tab>
            <v-tab
              class="mt-n1 text-capitalize"
              href="#tab-4"
              @click="closeSideBars(), setEditing('calendar')"
            >
              CALENDAR
            </v-tab>
            <v-row class="mt-1 mr-1">
              <v-spacer></v-spacer>
              <v-menu
                v-if="getEditing == 'list' || getEditing == 'board'"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    text
                    color="grey darken-2"
                    class="mr-3 d-none d-sm-block"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left>
                      mdi-check-circle-outline
                    </v-icon>
                    {{ getSelectedStatusFilter }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in taskStatusFilter"
                    :key="index"
                    link
                    @click="changeStatusFilter(item)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div v-if="getEditing == 'list' || getEditing == 'board'">
                <TaskFilter />
              </div>
              <v-btn
                :disabled="getLicense.isLicensed && !getLicense.project_fields"
                x-small
                text
                color="grey darken-2"
                class="mr-3 d-none d-sm-block"
                @click="openCustomFields"
              >
                <v-icon left>
                  mdi-view-grid-plus-outline
                </v-icon>
                Customize
              </v-btn>
            </v-row>
          </v-tabs>
          <div class="d-sm-none">
            <v-row class="my-1">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="grey darken-3"
                    dark
                    small
                    text
                    v-bind="attrs"
                    class="ml-4"
                    v-on="on"
                  >
                    {{ selectedTab }}
                    <v-icon
                      right
                      dark
                    >
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    @click="tabSwitch(item.action)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer />
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    text
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-check-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in taskStatusFilter"
                    :key="index"
                    link
                    @click="changeStatusFilter(item)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <TaskFilter />
              <v-btn
                x-small
                text
                color="grey darken-2"
                class="mr-5"
                @click="openCustomFields"
              >
                <v-icon>
                  mdi-view-grid-plus-outline
                </v-icon>
              </v-btn>
            </v-row>
          </div>
          <v-divider />
          <v-row class="pt-3 px-3">
            <v-col
              :cols="getTaskNavigation == true && getMobileView == false ||
              getCustomFields == true && getMobileView == false ? '8' : '12'"
              class="pa-0"
            >
              <v-tabs-items
                v-if="loaded"
                v-model="model"
                touchless
              >
                <v-tab-item
                  value="tab-1"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <v-card
                    class="gradient pa-3"
                    flat
                    rounded="0"
                  >
                    <ProjectOverview />
                  </v-card>
                </v-tab-item>

                <v-tab-item
                  value="tab-2"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <v-card
                    v-if="tasksLoaded"
                    class="gradient pa-3 mb-3"
                    flat
                    rounded="0"
                  >
                    <ListView @websocket="sendMessage()" />
                  </v-card>
                  <v-col
                    v-else
                    class="text-subtitle-1 text-center pa-0"
                    cols="12"
                  >
                    Loading...
                  </v-col>
                </v-tab-item>
                <v-tab-item
                  value="tab-3"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <v-card
                    v-if="tasksLoaded"
                    class="gradient pa-3 mb-3"
                    flat
                  >
                    <BoardView @websocket="sendMessage()" />
                  </v-card>
                  <v-col
                    v-else
                    class="text-subtitle-1 text-center pa-0"
                    cols="12"
                  >
                    Loading...
                  </v-col>
                </v-tab-item>
                <v-tab-item
                  value="tab-4"
                  :transition="false"
                  :reverse-transition="false"
                >
                  <v-card
                    class="gradient pa-3"
                    flat
                  >
                    <Calendar :parent="'projects'" />
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
              <div
                v-else
                class="ma-3"
              >
                <h3> {{ delayedMsg() }}</h3>
              </div>
            </v-col>
            <v-col
              v-if="getTaskNavigation == true && getMobileView == false"
              cols="4"
              class="pa-0"
            >
              <FullTaskInfo />
            </v-col>
            <v-col
              v-if="getCustomFields == true && getMobileView == false"
              cols="4"
              class="pa-0"
            >
              <CustomFields />
            </v-col>
            <v-dialog
              v-if="getMobileView == true"
              v-model="getTaskNavigation"
              persistent
            >
              <v-card>
                <FullTaskInfo />
              </v-card>
            </v-dialog>
            <v-dialog
              v-if="getMobileView == true"
              v-model="getCustomFields"
              persistent
            >
              <v-card>
                <CustomFields />
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
      </template>
    </v-row>
    <div
      v-else
      class="ma-3"
    >
      <h3>{{ delayedMsg() }}</h3>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ProjectToolbar from "../../components/Projects/ProjectToolbar.vue";
import BoardView from "../../components/Projects/BoardView";
import ListView from "../../components/Projects/ListView";
import { projectUtils } from "../../mixins/project";
import FullTaskInfo from "../../components/Projects/FullTaskInfo";
import CustomFields from "../../components/Projects/CustomFields.vue";
import TaskFilter from "../../components/Projects/Menu/TaskFilter.vue";
import ProjectOverview from "../../components/Projects/ProjectOverview.vue";
import Calendar from "../../components/Calendar/CalendarView.vue";
import { calendar } from "../../mixins/calendar";

export default {
  components: {
    ProjectToolbar,
    BoardView,
    ListView,
    FullTaskInfo,
    CustomFields,
    TaskFilter,
    ProjectOverview,
    Calendar,
  },

  data: () => ({
    loading: true,
    connectionFailed: false,
    tasksLoaded: false,
    reloadTasks: 0,
    loaded: false,
    model: "tab-2",
    subtasks: [],
    selectedTab: "List view",
    disableItemDrop: false,
    items: [
      { title: "Overview", action: "tab-1" },
      { title: "List view", action: "tab-2" },
      { title: "Board view", action: "tab-3" },
    ],
    taskStatusFilter: [
      { title: "Incomplete tasks" },
      { title: "Completed tasks" },
      { title: "All tasks" },
    ],
  }),

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  watch: {
    "$store.state.projects.isAssigned": function () {
      if (this.getIsAssigned == true) {
        this.sendMessage();
        this.setIsAssigned(false);
      }
    },
    "$store.state.projects.date": function () {
      if (this.getDate == true) {
        this.sendMessage();
        this.setDate(false);
      }
    },
    "$store.state.projects.taskDeleted": function () {
      if (this.getTaskDeleted == true) {
        this.onEnd();
        this.sendMessage();
        this.setTaskDeleted(false);
      }
    },
    "$store.state.projects.taskEdited": function () {
      if (this.getTaskEdited == true) {
        this.sendMessage();
        this.setTaskEdited(false);
      }
    },
    "$store.state.projects.taskCreated": function () {
      if (this.getTaskCreated == true) {
        this.onEnd();
        this.sendMessage();
        this.setTaskCreated(false);
      }
    },
    "$store.state.projects.fieldUpdate": function () {
      if (this.getFieldUpdate == true) {
        this.onEnd();
        this.sendMessage();
        this.setFieldUpdate(false);
      }
    },
    "$store.state.projects.fileActions": function () {
      if (this.getFileActions == true) {
        this.onEnd();
        this.sendMessage();
        this.setFileActions(false);
      }
    },
    "$store.state.projects.memberDataUpdate": function () {
      if (this.getMemberDataUpdate == true) {
        this.shareMessage();
        this.$store.commit("projects/setMemberDataUpdate", false);
      }
    },

    webSocket() {
      const intervalId = setInterval(() => {
        if (this.webSocket.readyState === 1) {
          this.loaded = true;
          clearInterval(intervalId);
          this.connectionFailed = false;
        } else if (this.webSocket.readyState === 0) {
          this.loaded = false;
          this.connectionFailed = true;
        }
      }, 20);

      setTimeout(() => {
        clearInterval(intervalId);
      }, 2000);
    },
  },

  mixins: [projectUtils, calendar],

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("user", ["getUser"]),
    ...mapGetters("license", ["getLicense"]),
    ...mapGetters("projects", [
      "getTask",
      "getTasks",
      "getColumns",
      "getHeaders",
      "getProject",
      "getProjectMembers",
      "getRefreshDate",
      "getDate",
      "getTaskDeleted",
      "getTaskEdited",
      "getTaskCreated",
      "getIsAssigned",
      "getSocketPath",
      "getSocket",
      "getTaskNavigation",
      "getCustomFields",
      "getSelectedMeta",
      "getMetaList",
      "getFieldUpdate",
      "getRefreshCustomDate",
      "getRestoreKey",
      "getSubTaskReloadKey",
      "getFileActions",
      "getMobileView",
      "getMemberData",
      "getMemberDataUpdate",
      "getActiveUsers",
      "getSelectedStatusFilter",
      "getEditing",
    ]),

    ...mapGetters("calendar", ["getCalendarTasks", "getCalendarWsTask"]),

    dateKey: {
      get() {
        return this.getRefreshDate;
      },
      set(value) {
        this.$store.commit("projects/setRefreshDate", value);
      },
    },

    subTaskReloadKey: {
      get() {
        return this.getSubTaskReloadKey;
      },
      set(value) {
        this.$store.commit("projects/setSubTaskReloadKey", value);
      },
    },

    taskRestoreKey: {
      get() {
        return this.getRestoreKey;
      },
      set(value) {
        this.$store.commit("projects/setRestoreKey", value);
      },
    },

    customDateKey: {
      get() {
        return this.getRefreshCustomDate;
      },
      set(value) {
        this.$store.commit("projects/setRefreshCustomDate", value);
      },
    },

    webSocket: {
      get() {
        return this.getSocket;
      },
      set(value) {
        this.$store.commit("projects/setSocket", value);
      },
    },
  },

  mounted() {
    var protocol = "wss://";
    if (window.location.hostname == "localhost") {
      protocol = "ws://";
    }
    let address =
      protocol +
      window.location.host +
      "/api/projects/ws/" +
      this.$route.params.id;
    this.webSocket = new WebSocket(address);
    this.webSocket.onmessage = (msg) => {
      if (this.$route.name == "project") {
        var fromSocket = JSON.parse(msg.data);
        if (fromSocket[0].action == "restore") {
          this.restoreTask(fromSocket[0]);
        } else if (
          fromSocket[0].action == "removeUser" ||
          fromSocket[0].action == "addUser"
        ) {
          this.memberListChange(fromSocket[0]);
        } else if (fromSocket[0].action == "calendarRecordCreate") {
          this.addRecordToProject(fromSocket[0]);
        } else if (fromSocket[0].action == "calendarRecordUpdate") {
          this.updateProjectRecord(fromSocket[0]);
        } else if (fromSocket[0].action == "trashTask") {
          this.trashProjectTask(fromSocket[0]);
        } else if (fromSocket[0].action == "userStatusChange") {
          // future updates
        } else if (fromSocket[0].action == "delete") {
          this.deleteTaskFromList(fromSocket[0]);
        } else {
          if (fromSocket[2].projectID == this.$route.params.id) {
            this.setColumns(fromSocket[0]);
            this.setHeaders(fromSocket[1]);
            this.dateKey += 1;
            this.setTasks(fromSocket[4]);
            if (fromSocket[3].id == this.getTask.id) {
              this.setTask(fromSocket[3]);
            }
            this.setMetaList(fromSocket[5]);
            this.customDateKey += 1;
            this.calendarUpdate(fromSocket[6]);
          }
        }
      }
    };
    this.webSocket.onopen = function () {};
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  created() {
    this.$store.commit("projects/setFilterData", []);
    this.$store
      .dispatch("projects/getProject", this.$route.params.id)
      .then((response) => {
        this.setEditing("list");
        this.setProject(response.data);
        var properties = JSON.parse(this.getProject.properties);
        this.setColumns(properties[0]);
        this.setHeaders(properties[1]);
        this.customFields();
        this.$store
          .dispatch("projects/getTasks", this.getProject.id)
          .then(() => {
            if (this.$route.params.fullTask) {
              const fullTask = this.getTasks.find(
                (task) => task.id === this.$route.params.fullTask
              );
              if (fullTask) {
                this.fullTaskInfo(fullTask);
              }
            }
            this.$store
              .dispatch("projects/getProjectMembers", this.getProject.id)
              .then(() => {
                for (let i = 0; i < this.getProjectMembers.length; i++) {
                  if (this.getProjectMembers[i].deactivated_at == 0) {
                    this.getProjectMembers[i].disabled = false;
                  } else {
                    this.getProjectMembers[i].disabled = true;
                  }
                }
                this.$store
                  .dispatch("projects/getSelectedMeta", this.getProject.id)
                  .then(() => {
                    // getColumns (Main Project array with sections info, tasks info and task order)
                    const taskTable = {};
                    for (let task of this.getTasks) {
                      taskTable[task.id] = task;
                    }

                    // Iterate over the columns and tasks and replace the tasks with the corresponding task object from the hash table
                    for (let column of this.getColumns) {
                      for (let task of column.tasks) {
                        const taskId = task.id;
                        const taskObject = taskTable[taskId];
                        if (taskObject && !taskObject.trash) {
                          // Replace the task object with the corresponding task object from the hash table
                          Object.assign(task, taskObject);

                          // Set default values for metadata fields
                          for (let meta of this.getMetaList) {
                            task[meta.id.toString()] = "";
                          }

                          // Update metadata fields from selected metadata
                          for (let selectedMeta of this.getSelectedMeta) {
                            if (taskId == selectedMeta.item_id) {
                              const key = selectedMeta.metadata_id.toString();
                              task[key] = JSON.parse(selectedMeta.value);
                            }
                          }
                        }
                      }
                    }
                    this.tasksLoaded = true;
                  });
              });
          });
      });
  },

  methods: {
    ...mapMutations("projects", [
      "setColumns",
      "setHeaders",
      "setProject",
      "setIsAssigned",
      "setDate",
      "setTaskDeleted",
      "setTaskEdited",
      "setTaskCreated",
      "setEditing",
      "setSocket",
      "setTask",
      "setTasks",
      "setFieldUpdate",
      "setMetaList",
      "setFileActions",
      "setMobileView",
    ]),

    ...mapMutations("calendar", ["setCalendarTasks"]),

    delayedMsg() {
      setTimeout(() => {
        return "Failed to connect to project server";
      }, 2000);
    },

    calendarUpdate(item) {
      if (item.action == "delete") {
        this.deleteCalendarTask(item.taskID);
      } else if (item.action == "edit") {
        this.editCalendarTask(item.task, item.fromCalendar);
      } else if (item.action == "add") {
        this.addToCalendar(item.task);
      }

      this.$store.commit("calendar/setCalendarWsTask", {});
    },

    deleteTaskFromList(item) {
      for (let i = 0; i < this.getColumns.length; i++) {
        for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
          if (this.getColumns[i].tasks[j].id == item.task.id) {
            this.getColumns[i].tasks.splice(
              this.getColumns[i].tasks.indexOf(this.getColumns[i].tasks[j]),
              1
            );
            this.onEnd();
          }
        }
      }
    },

    tabSwitch(item) {
      if (item == "tab-1") {
        this.selectedTab = "Overview";
        this.setEditing("overview");
      } else if (item == "tab-2") {
        this.selectedTab = "List view";
        this.setEditing("list");
      } else if (item == "tab-3") {
        this.selectedTab = "Borad view";
        this.setEditing("board");
      } else if (item == "tab-4") {
        this.selectedTab = "Calendar view";
        this.setEditing("calendar");
      }
      this.model = item;
    },

    changeStatusFilter(item) {
      this.$store.commit("projects/setSelectedStatusFilter", item.title);
    },

    onResize() {
      this.disableItemDrop = window.innerWidth < 959;
      if (this.disableItemDrop == true) {
        this.setMobileView(true);
      } else {
        this.setMobileView(false);
      }
    },

    memberListChange(item) {
      if (item.action == "removeUser") {
        let i = this.getProjectMembers.map((t) => t.id).indexOf(item.user.id);
        this.getProjectMembers.splice(i, 1);

        let j = this.getActiveUsers.map((t) => t.id).indexOf(item.user.id);
        this.getActiveUsers.splice(j, 1);

        let n = this.getProject.members
          .map((t) => t.user_id)
          .indexOf(item.user.id);
        this.getProject.members.splice(n, 1);

        for (let i = 0; i < this.getColumns.length; i++) {
          for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
            if (this.getColumns[i].tasks[j].assigned_to == item.user.id) {
              this.getColumns[i].tasks[j].assigned_to = "";
            }
          }
        }
        for (let i = 0; i < this.getTasks.length; i++) {
          if (this.getTasks[i].assigned_to == item.user.id) {
            this.getTasks[i].assigned_to = "";
          }
        }
        if (this.getTask.assigned_to == item.user.id) {
          this.getTask.assigned_to = "";
        } else if (this.getTask.subtasks != undefined) {
          for (let i = 0; i < this.getTask.subtasks.length; i++) {
            if (this.getTask.subtasks[i].assigned_to == item.user.id) {
              this.getTask.subtasks[i].assigned_to = "";
            }
          }
        }
      } else if (item.action == "addUser") {
        this.getProject.members.push(...item.members);
        for (let i = 0; i < item.members.length; i++) {
          for (let j = 0; j < this.getUsers.length; j++) {
            if (this.getUsers[j].id == item.members[i].user_id) {
              this.getUsers[j].project_role = item.members[i].role;
              this.getProjectMembers.push(this.getUsers[j]);
            }
          }
        }
      }

      this.$store.commit("projects/setMemberData", {});
    },

    customFields() {
      var fields = [];
      this.$store
        .dispatch("projects/getMetaList", this.getProject.id)
        .then(() => {
          for (let i = 0; i < this.getMetaList.length; i++) {
            var header = {
              value: this.getMetaList[i].id,
              name: this.getMetaList[i].title,
              show: false,
            };
            fields.push(header);
          }
          const difference = fields.filter(
            ({ value: id1 }) =>
              !this.getHeaders.some(({ value: id2 }) => id2 === id1)
          );
          for (let j = 0; j < difference.length; j++) {
            this.getHeaders.push(difference[j]);
          }
        });
    },

    closeSideBars() {
      this.$store.commit("projects/setTaskNavigation", false);
      this.$store.commit("projects/setCustomFields", false);
    },

    restoreTask(item) {
      item.task.trash = false;
      this.addToCalendar(item.task);

      var inList = false;
      var add = true;
      var addSub = true;

      if (item.task.type == "task") {
        for (let k = 0; k < this.getMetaList.length; k++) {
          item.task[this.getMetaList[k].id.toString()] = "";
        }
        for (let l = 0; l < this.getSelectedMeta.length; l++) {
          if (item.task.id == this.getSelectedMeta[l].item_id) {
            var key = this.getSelectedMeta[l].metadata_id.toString();
            item.task[key] = JSON.parse(this.getSelectedMeta[l].value);
          }
        }
        for (let i = 0; i < this.getColumns.length; i++) {
          for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
            if (this.getColumns[i].tasks[j].id == item.task.id) {
              this.getColumns[i].tasks[j] = item.task;
              inList = true;
            }
          }
        }

        if (inList == false) {
          (item.task.trash = false),
            this.getColumns[0].tasks.unshift(item.task);
        }

        for (let i = 0; i < this.getTasks.length; i++) {
          if (this.getTasks[i].id == item.task.id) {
            this.getTasks[i].trash = false;
            add = false;
          }
        }

        if (add) {
          this.getTasks.push(item.task);
        }

        if (item.task.subtasks != undefined) {
          for (let i = 0; i < item.task.subtasks.length; i++) {
            for (let j = 0; j < this.getTasks.length; j++) {
              if (item.task.subtasks[i].id == this.getTasks[j].id) {
                this.getTasks[j].trash = false;
                this.addToCalendar(item.task.subtasks[i]);
              }
            }
          }
          const newObjects = item.task.subtasks.filter((obj) => {
            return !this.getTasks.some((item) => item.id === obj.id);
          });
          this.getTasks.push(...newObjects);
        }

        this.onEnd();

        this.taskRestoreKey += 1;
      } else if (item.task.type == "subtask") {
        if (
          this.getTask.subtasks != undefined &&
          this.getTask.id == item.parent_id
        ) {
          for (let i = 0; i < this.getTask.subtasks.length; i++) {
            if (this.getTask.subtasks[i].id == item.task.id) {
              this.getTask.subtasks[i].trash = false;
              addSub = false;
            }
          }
        }

        if (addSub && this.getTask.subtasks != undefined) {
          this.getTask.subtasks.push(item.task);
        }

        for (let i = 0; i < this.getTasks.length; i++) {
          if (this.getTasks[i].id == item.task.id) {
            this.getTasks[i].trash = false;
            add = false;
          }
        }

        if (add) {
          this.getTasks.push(item.task);
        }

        this.subTaskReloadKey += 1;
      }
    },

    openCustomFields() {
      var val = this.getCustomFields;
      this.$store.commit("projects/setTaskNavigation", false);
      this.$store.commit("projects/setCustomFields", !val);
    },

    shareMessage() {
      let msg = {
        properties: JSON.stringify(this.getMemberData),
      };
      this.webSocket.send(JSON.stringify(msg));
    },

    sendMessage() {
      var project = [
        this.getColumns,
        this.getHeaders,
        { projectID: this.getProject.id },
        this.getTask,
        this.getTasks,
        this.getMetaList,
        this.getCalendarWsTask,
      ];
      let msg = {
        properties: JSON.stringify(project),
      };
      this.webSocket.send(JSON.stringify(msg));
    },
  },
};
</script>