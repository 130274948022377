<template>
  <v-menu
    v-model="showTaskMenu"
    :close-on-content-click="false"
    :nudge-width="200"
    :activator="selectedElement"
    offset-y
    max-width="350"
    min-width="350"
    rounded
  >
    <v-card rounded>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="editSelectedRecord"
        >
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="deleteSelectedTask"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="closeTask"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>

      <v-list class="pt-0">
        <v-list-item>
          <v-list-item-icon class="mr-4">
          </v-list-item-icon>
          <v-list-item-content class="py-0">
            <v-list-item-title>{{selectedEvent.name}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-clock-time-five-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="py-0">
            <v-list-item-title
              v-if="dateFormat(selectedEvent.start, 'll') != dateFormat(selectedEvent.end, 'll')"
              style="font-size: 13px;"
            >
              {{ dateFormat(selectedEvent.start, 'll') +' - '+ dateFormat(selectedEvent.end, 'll') }}
            </v-list-item-title>
            <v-list-item-title
              v-else
              style="font-size: 13px;"
            >
              {{ dateFormat(selectedEvent.end, 'll') }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="selectedEvent.timed == true"
              style="font-size: 13px;"
            >
              {{ timeFormat(selectedEvent.start) +' - '+ timeFormat(selectedEvent.end) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-calendar-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="py-0">
            <v-list-item-title
              v-if="selectedEvent.parentTitle != ''"
              style="font-size: 13px;"
            >
              {{ selectedEvent.parentTitle }}
            </v-list-item-title>
            <v-list-item-title
              v-else
              style="font-size: 13px;"
            >
              {{ eventName() }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="selectedEvent.parentType == 'project' && $route.name == 'calendar'"
          dense
          link
          @click="redirectToProject(selectedEvent)"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-open-in-new</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="py-0">
            <v-list-item-title style="font-size: 13px;"> Go to Project </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="selectedEvent.parentType == 'project' && $route.name == 'project'"
          dense
          link
          @click="openProject(selectedEvent)"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-open-in-new</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="py-0">
            <v-list-item-title style="font-size: 13px;"> Open full task view </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
  
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { projectUtils } from "../../../mixins/project";

export default {
  props: {
    selectedOpen: Boolean,
    selectedEvent: Object,
    selectedElement: HTMLElement,
    calendarItem: Object,
    createDialog: Boolean,
    action: String,
    recordDialogTab: String,
    disableTasks: Boolean,
    disableEvents: Boolean,
  },
  data: () => ({
    //
  }),

  mixins: [projectUtils],

  computed: {
    ...mapGetters("user", ["getUsers", "getClockDisplay"]),
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("calendar", [
      "getCalendarList",
      "getCalendars",
      "getStartTime",
      "getEndTime",
    ]),

    setStartTime: {
      get() {
        return this.getStartTime;
      },
      set(value) {
        this.$store.commit("calendar/setStartTime", value);
      },
    },

    setEndTime: {
      get() {
        return this.getEndTime;
      },
      set(value) {
        this.$store.commit("calendar/setEndTime", value);
      },
    },

    showTaskMenu: {
      get() {
        return this.selectedOpen;
      },
      set(value) {
        this.$emit("update:selectedOpen", value);
      },
    },

    setDisableTasks: {
      get() {
        return this.disableTasks;
      },
      set(value) {
        this.$emit("update:disableTasks", value);
      },
    },

    setDisableEvents: {
      get() {
        return this.disableEvents;
      },
      set(value) {
        this.$emit("update:disableEvents", value);
      },
    },

    setRecordDialogTab: {
      get() {
        return this.recordDialogTab;
      },
      set(value) {
        this.$emit("update:recordDialogTab", value);
      },
    },
  },

  methods: {
    dateFormat(item, format) {
      return moment(item).format(format);
    },

    timeFormat(item) {
      var format = "";
      if (this.getClockDisplay == "24h") {
        format = "HH:mm";
      } else {
        format = "LT";
      }

      return moment(item).format(format);
    },

    editSelectedRecord() {
      console.log("editSelectedRecord", this.selectedEvent);
      const guests = JSON.parse(this.selectedEvent.guests);

      console.log(guests);

      var users = [];

      for (let i = 0; i < this.getUsers.length; i++) {
        for (let j = 0; j < guests.length; j++) {
          if (this.getUsers[i].id == guests[j]) {
            users.push(this.getUsers[i]);
          }
        }
      }

      this.setRecordDialogTab = "tab-1";
      this.setDisableTasks = true;
      this.setDisableEvents = false;

      this.showTaskMenu = false;
      this.$emit("update:action", "update");

      var calendar = {};

      var sharedCalendar = true;

      for (let i = 0; i < this.getCalendars.length; i++) {
        if (
          this.selectedEvent.parentType == "calendar" &&
          this.selectedEvent.calendar_id == this.getCalendars[i].id
        ) {
          calendar = this.getCalendars[i];
          console.log("cal obj", calendar);
          sharedCalendar = false;
        }
      }

      var originalCalendarID = "";

      if (sharedCalendar) {
        for (let i = 0; i < this.getCalendars.length; i++) {
          if (this.getCalendars[i].default_calendar == true) {
            calendar = this.getCalendars[i];
            originalCalendarID = this.selectedEvent.calendar_id;
          }
        }
      }

      if (this.selectedEvent.files == null) {
        this.selectedEvent.files = [];
      }

      var task = {
        id: this.selectedEvent.id,
        title: this.selectedEvent.name,
        start: this.selectedEvent.start,
        end: this.selectedEvent.end,
        description: this.selectedEvent.description,
        files: this.selectedEvent.files,
        parentType: this.selectedEvent.parentType,
        calendar: calendar,
        type: this.selectedEvent.type,
        timed: this.selectedEvent.timed,
        guests: users,
        shared_event: this.selectedEvent.shared_event,
        originalCalendarID: originalCalendarID,
        activeGuests: this.selectedEvent.activeGuests,
      };

      if (task.timed) {
        this.setStartTime = moment(this.selectedEvent.start).format("HH:mm");
        this.setEndTime = moment(this.selectedEvent.end).format("HH:mm");
      }

      this.$emit("update:calendarItem", task);
      this.$emit("update:createDialog", true);
    },

    eventName() {
      const calendarList = this.getCalendarList[0];
      for (const calendar of calendarList.items) {
        for (const member of calendar.members) {
          if (member.default_calendar) {
            return member.display_name;
          }
        }
      }
    },

    closeTask() {
      this.showTaskMenu = false;
    },

    deleteSelectedTask() {
      var data = {
        recordID: this.selectedEvent.id,
        calendarID: this.selectedEvent.calendar_id,
      };
      this.$store.dispatch("calendar/deleteRecord", data).then(() => {
        this.getCalendarTasks.splice(
          this.getCalendarTasks.indexOf(this.selectedEvent),
          1
        );
        this.recDeleteKey += 1;
        this.closeTask();
      });
    },
  },
};
</script>