<template>
  <v-dialog
    v-if="loaded == true"
    v-model="addMember"
    persistent
    max-width="555"
    width="555"
  >
    <v-card class="ma-0 pa-0">
      <v-col class="ma-0 pa-0">
        <v-card-title
          v-if="sourceType == 'projects'"
          class="text-h5"
        >
          Project share "{{ getProject.title }}"
        </v-card-title>
        <v-card-title
          v-else
          class="text-h5"
        >
          {{selectedCalendar.title}}
        </v-card-title>
      </v-col>
      <v-card-text class="ma-0 pa-0">
        <v-row
          align="center"
          justify="center"
          class="ma-1 pa-0"
        >
          <v-col>
            <v-autocomplete
              v-model="selectedUsers"
              :disabled="isUpdating"
              :color="getAppearances.btn_color"
              :items="getItemsToSelect"
              hide-selected
              chips
              label="Select members"
              item-text="name"
              item-value="id"
              return-object
              no-data-text="No users available"
              multiple
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  <v-avatar left>
                    <v-img :src="`/api/users/photo/${data.item.photo}`"></v-img>
                  </v-avatar>
                  {{ data.item.name }} {{data.item.lastname}}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-row class="pa-3">
                    <v-list-item-avatar>
                      <img :src="`/api/users/photo/${data.item.photo}`">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name + ' ' + data.item.lastname + ` (${data.item.email})`"></v-list-item-title>
                    </v-list-item-content>
                  </v-row>
                </template>
              </template>
            </v-autocomplete>

            <v-list>
              <v-subheader
                v-if="sourceType == 'projects'"
                class="ma-0 pa-0"
              >
                Project members
              </v-subheader>
              <v-subheader
                v-else
                class="ma-0 pa-0"
              >
                Calendar members
              </v-subheader>
              <v-list-item
                class="v-list-item--link ma-0 pa-0"
                v-for="(item, index) in getActiveUsers"
                :key="index"
              >
                <v-badge
                  color="error"
                  offset-x="25"
                  offset-y="22"
                  bordered
                  overlap
                  :value="item.deactivated_at == 0 ? false : true"
                >
                  <template v-slot:badge>
                    <v-icon style="margin-top: -1px;">mdi-archive-outline</v-icon>
                  </template>
                  <v-list-item-avatar>
                    <v-img :src="`/api/users/photo/${item.photo}`">
                    </v-img>
                  </v-list-item-avatar>
                </v-badge>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name + ' ' + item.lastname"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-menu
                    :close-on-content-click="true"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="item.project_role == 'owner'"
                        v-bind="attrs"
                        v-on="on"
                        depressed
                      >
                        {{ item.project_role }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-dialog
                        v-model="removeDialog"
                        persistent
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-title>unshare</v-list-item-title>
                          </v-list-item>
                        </template>
                        <v-card>
                          <v-card-title class="headline">
                            Unshare this user?
                          </v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              :color="getAppearances.btn_color"
                              text
                              @click="removeDialog = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              :color="getAppearances.btn_color"
                              text
                              @click="removeUser(item, sourceType, selectedCalendar), removeDialog = false"
                            >
                              Yes
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="close"
        >
          Close
        </v-btn>
        <v-btn
          :color="getAppearances.btn_color"
          dark
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { projectUtils } from "../../../mixins/project";

export default {
  name: "AddMember",
  props: {
    addMember: Boolean,
    sourceType: String,
    selectedCalendar: Object,
  },
  data: () => ({
    removeDialog: false,
    isUpdating: false,
    selectedUsers: [],
    loaded: true,
  }),

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("calendar", ["getCalendarList"]),
    ...mapGetters("projects", [
      "getProject",
      "getProjectMembers",
      "getColumns",
      "getActiveUsers",
      "getItemsToSelect",
    ]),
  },

  mixins: [projectUtils],

  methods: {
    remove(item) {
      const index = this.selectedUsers.indexOf(item);
      if (index >= 0) this.selectedUsers.splice(index, 1);
    },

    close() {
      this.$emit("update:addMember", false);
      this.$emit("update:selectedCalendar", {});
      this.selectedUsers = [];
    },

    save() {
      if (this.selectedUsers == "" || this.selectedUsers.length == 0) {
        this.close();
      } else {
        if (this.sourceType == "projects") {
          this.$store
            .dispatch("projects/addMemberToProject", {
              members: this.selectedUsers.map((e) => e.id),
              project_id: this.getProject.id,
            })
            .then((response) => {
              var member = [{ action: "addUser", members: response.data }];
              this.$store.commit("projects/setMemberData", member);
              this.$store.commit("projects/setMemberDataUpdate", true);
            });
        } else {
          this.$store
            .dispatch("calendar/addMemberToCalendar", {
              members: this.selectedUsers.map((e) => e.id),
              calendar_id: this.selectedCalendar.id,
            })
            .then((response) => {
              for (let i = 0; i < this.getCalendarList.length; i++) {
                for (let j = 0; j < this.getCalendarList[i].items.length; j++) {
                  for (let k = 0; k < response.data.length; k++) {
                    if (
                      this.getCalendarList[i].items[j].id ==
                      response.data[k].calendar_id
                    ) {
                      this.getCalendarList[i].items[j].members.push(
                        response.data[k]
                      );
                    }
                  }
                }
              }
            });
        }
        this.close();
      }
    },
  },
};
</script>

<style>
.border {
  border: 3px solid #ffffff;
  border-radius: 50%;
}

.gradient {
  background-image: linear-gradient(rgb(245, 245, 245), rgb(255, 255, 255));
}
</style>