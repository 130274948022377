<template>
  <v-container
    fluid
    class="pa-0 fill-height"
  >
    <v-row
      class="fill-height"
      justify="center"
    >
      <v-col cols="12">
        <div class="mx-3">
          <Calendar :parent="'calendar'" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Calendar from "../../components/Calendar/CalendarView.vue";

export default {
  components: {
    Calendar,
  },
  data: () => ({}),
};
</script>