<template>
  <div>
    <v-row class="d-inline-flex ma-0 pa-0">
      <v-btn
        class="text-capitalize mr-5"
        x-small
        outlined
        color="grey darken-2"
        @click.stop="dialog = true"
      >
        Add time
      </v-btn>
      <v-dialog
        v-model="dialog"
        max-width="620"
        persistent
      >
        <v-card>
          <v-card-actions class="my-0 pb-0">
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <div>
              <v-row
                align="center"
                class="ma-0 pa-0"
              >
                <v-col
                  cols="6"
                  class="pa-0 ma-0"
                >
                  <div class="mr-3">
                    <h2 class="mb-1">Start:</h2>
                    <v-time-picker
                      v-model="setStartTime"
                      :max="setEndTime"
                      :format="timeFormat()"
                      @change="timeChange()"
                    ></v-time-picker>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="pa-0 ma-0"
                >
                  <div class="ml-3">
                    <h2 class="mb-1">End:</h2>
                    <v-time-picker
                      v-model="setEndTime"
                      :min="setStartTime"
                      :format="timeFormat()"
                      @change="timeChange()"
                    ></v-time-picker>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- uzlikt to remove lai ir tikai pie edit -->
            <v-btn
              v-if="setStartTime != '' && setEndTime != ''"
              text
              @click="setStartTime = '', setEndTime = '', dialog = false"
              class="text-capitalize mb-2 mr-2"
            >
              Remove time
            </v-btn>
            <v-btn
              :color="getAppearances.btn_color"
              class="white--text text-capitalize mb-2"
              :disabled="disabledSave()"
              @click="save()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
  
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  props: {
    calendarItem: Object,
  },

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("user", ["getClockDisplay"]),
    ...mapGetters("calendar", [
      "getCalendarTasks",
      "getStartTime",
      "getEndTime",
      "getStartTime",
      "getEndTime",
    ]),

    setStartTime: {
      get() {
        return this.getStartTime;
      },
      set(value) {
        this.$store.commit("calendar/setStartTime", value);
      },
    },

    setEndTime: {
      get() {
        return this.getEndTime;
      },
      set(value) {
        this.$store.commit("calendar/setEndTime", value);
      },
    },
  },

  methods: {
    timeChange() {
      if (this.calendarItem.fromTimeline) {
        for (let i = 0; i < this.getCalendarTasks.length; i++) {
          if (this.getCalendarTasks[i].fromTimeline == true) {
            this.getCalendarTasks[i].start =
              moment(
                moment(this.calendarItem.start).format("D MMM, YYYY") +
                  " " +
                  this.setStartTime
              ).format("X") * 1000;
            this.getCalendarTasks[i].end =
              moment(
                moment(this.calendarItem.end).format("D MMM, YYYY") +
                  " " +
                  this.setEndTime
              ).format("X") * 1000;
          }
        }
      }
    },

    disabledSave() {
      if (this.setStartTime == "" || this.setEndTime == "") {
        return true;
      }

      return false;
    },

    timeFormat() {
      if (this.getClockDisplay == "24h") {
        return "24hr";
      } else {
        return "ampm";
      }
    },

    save() {
      this.dialog = false;
    },
  },
};
</script>