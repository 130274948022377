import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/store';

Vue.use(VueRouter);

import Main from '../views/Main';
import Login from '../views/Login';
import FilesMain from '../views/Files/FilesMain';
import Files from '../views/Files/Files';
import Shared from '../views/Files/Shared';
import Recent from '../views/Files/Recents';
import Metadata from '../views/Settings/Admin/Metadata';
import Favourites from '../views/Files/Favourites';
import Trash from '../views/Files/Trashed';
import PublicShare from '../views/PublicShare';
import NotFound from '../views/NotFound';
import PasswordProtect from '../views/PasswordProtect';
import Settings from '../views/Settings/Settings';
import UsersAndGroups from '../views/Settings/Admin/UsersAndGroups';
import Personal from '../views/Settings/User/Personal';
import UserNotifications from '../views/Settings/User/Notifications';
import AdminDashboard from '../views/Settings/Admin/Dashboard';
import GeneralSettings from '../views/Settings/Admin/GeneralSettings';
import Security from '../views/Settings/Admin/Security';
import UserSecurity from '../views/Settings/User/Security';
import LicenseView from '../views/Settings/Admin/LicenseView';
import CreateNewUser from '../components/Settings/Admin/UsersAndGroups/CreateNewUser';
import EditUser from '../components/Settings/Admin/UsersAndGroups/EditUser';
import Registration from '../views/Registration.vue';
import Office from '../components/Office';
import Chat from '../views/Chat/Chat';
import Dashboard from '../views/Dashboard/Dashboard';
import Calendar from '../views/Calendar/Calendar';
import Projects from '../views/Projects/Projects';
import ProjectView from '../views/Projects/ProjectView';
import ProjectFolderView from '../views/Projects/ProjectFolderView';
import ProjectFolder from '../views/Projects/ProjectFolder';
import MyTaskView from '../views/Projects/MyTaskView';
import ProjectFavouritesView from '../views/Projects/FavouritesView';
import ProjectTrash from '../views/Projects/TrashView';
import ProjectRecents from '../views/Projects/RecentView';
import ChatContainer from '../components/Chat/ChatContainer';
import VideoView from '../components/Chat/video/VideoView';

const routes = [
    {
        component: Main,
        name: "main",
        path: "/",
        meta: {
            requireAuth: true
        },
        redirect: {
            path: 'drive'
        },
        children: [
            {
                component: FilesMain,
                name: "drive",
                path: "drive",
                redirect: {
                    name: 'files'
                },
                meta: {
                    requireAuth: true
                },
                children: [
                    {
                        component: Files,
                        name: "files",
                        path: "files/:path",
                        meta: {
                            requireAuth: true
                        }
                    },
                    {
                        component: Files,
                        name: "files-highlight",
                        path: "f/:id",
                        meta: {
                            requireAuth: true
                        }
                    },
                    {
                        component: Shared,
                        name: "shared",
                        path: "shared/:id?",
                        meta: {
                            requireAuth: true
                        }
                    },
                    {
                        component: Recent,
                        name: "recent",
                        path: "recent",
                        meta: {
                            requireAuth: true
                        }
                    },
                    {
                        component: Favourites,
                        name: "favourites",
                        path: "favourites",
                        meta: {
                            requireAuth: true
                        }
                    },
                    {
                        component: Trash,
                        name: "trash",
                        path: "trash",
                        meta: {
                            requireAuth: true
                        }
                    },
                    {
                        component: Office,
                        name: "office",
                        path: "office/:id",
                        meta: {
                            requireAuth: true
                        }
                    }
                ],
            },
            {
                component: Chat,
                name: "chat",
                path: "chat",
                redirect: {
                    name: 'messages'
                },

                meta: {
                    requireAuth: true
                },
                children: [
                    {
                        component: ChatContainer,
                        name: "messages",
                        path: "messages/:id?",
                        meta: {
                            requireAuth: true
                        },
                    },
                    {
                        component: VideoView,
                        name: "video",
                        path: "video/:id?",
                        meta: {
                            requireAuth: true
                        },
                    },
                ],
            },
            {
                component: Dashboard,
                name: "dashboard",
                path: "dashboard",
                meta: {
                    requireAuth: true
                }
            },
            {
                component: Calendar,
                name: "calendar",
                path: "calendar",
                meta: {
                    requireAuth: true
                }
            },
            {
                component: Projects,
                name: "projects",
                path: "projects",
                redirect: {
                    name: 'my-projects'
                },
                meta: {
                    requireAuth: true
                },
                children: [
                    {
                        component: ProjectView,
                        name: "project",
                        path: "p/:id",
                        meta: {
                            requireAuth: true
                        }
                    },
                    {
                        component: ProjectFolderView,
                        name: "my-projects",
                        path: "my-projects",
                        meta: {
                            requireAuth: true
                        }
                    },
                    {
                        component: MyTaskView,
                        name: "my-tasks",
                        path: "my-tasks",
                        meta: {
                            requireAuth: true
                        }
                    },
                    {
                        component: ProjectRecents,
                        name: "project-recents",
                        path: "recents",
                        meta: {
                            requireAuth: true
                        }
                    },
                    {
                        component: ProjectTrash,
                        name: "project-trash",
                        path: "trash",
                        meta: {
                            requireAuth: true
                        }
                    },
                    {
                        component: ProjectFavouritesView,
                        name: "project-favourites",
                        path: "favourites",
                        meta: {
                            requireAuth: true
                        }
                    },
                    {
                        component: ProjectFolder,
                        name: "project-folder",
                        path: "folder/:id",
                        meta: {
                            requireAuth: true
                        }
                    }
                ]
            },
            {
                component: Settings,
                name: "settings",
                path: "settings",
                redirect: {
                    name: 'personal'
                },
                meta: {
                    requireAuth: true
                },
                children: [
                    {
                        component: Personal,
                        name: "personal",
                        path: "personal-info",
                        meta: {
                            requireAuth: true,
                            requireAdmin: false
                        }
                    },
                    {
                        component: UsersAndGroups,
                        name: "users-and-groups",
                        path: "users-and-groups",
                        meta: {
                            requireAuth: true,
                            requireAdmin: true
                        }
                    },
                    {
                        component: CreateNewUser,
                        name: "create-new-user",
                        path: "create-new-user",
                        meta: {
                            requireAuth: true,
                            requireAdmin: true
                        }
                    },
                    {
                        component: EditUser,
                        name: "edit-user",
                        path: "edit-user",
                        meta: {
                            requireAuth: true,
                            requireAdmin: true
                        }
                    },
                    {
                        component: AdminDashboard,
                        name: "admin-reports",
                        path: "admin-reports",
                        meta: {
                            requireAuth: true,
                            requireAdmin: true
                        }
                    },
                    {
                        component: UserNotifications,
                        name: "notifications",
                        path: "user-notifications",
                        meta: {
                            requireAuth: true,
                            requireAdmin: false
                        }
                    },
                    {
                        component: GeneralSettings,
                        name: "general-settings",
                        path: "general",
                        meta: {
                            requireAuth: true,
                            requireAdmin: true
                        }
                    },
                    {
                        component: Security,
                        name: "security",
                        path: "security",
                        meta: {
                            requireAuth: true,
                            requireAdmin: true
                        }
                    },
                    {
                        component: LicenseView,
                        name: "license",
                        path: "license",
                        meta: {
                            requireAuth: true,
                            requireAdmin: true
                        }
                    },
                    {
                        component: UserSecurity,
                        name: "user-security",
                        path: "user-security",
                        meta: {
                            requireAuth: true,
                            requireAdmin: false
                        }
                    },
                    {
                        component: Metadata,
                        name: "metadata",
                        path: "metadata",
                        meta: {
                            requireAuth: true,
                            requireAdmin: true
                        }
                    },
                ]
            },
        ],
    },
    {
        component: Login,
        name: "login",
        path: "/login",
        meta: {
            requireAuth: false,
        }
    },
    {
        component: PublicShare,
        name: "external-share",
        path: "/share/:path",
        meta: {
            requireAuth: false
        }
    },
    {
        component: PasswordProtect,
        name: "password-protect",
        path: "/password/:path",
        meta: {
            requireAuth: false
        }
    },
    {
        component: Registration,
        name: "registration",
        path: "/reset/:token",
        meta: {
            requireAuth: false
        }
    },
    {
        component: NotFound,
        name: "not-found",
        path: "*",
        meta: {
            requireAuth: false
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth && !store.getters['user/isAuthenticated']) {
        next({ name: 'login', query: { redirect: to.path } })
    }
    else {
        if (!to.meta.requireAdmin) {
            next()
        } else if (to.meta.requireAdmin && store.getters['user/getUser'].role == 'system_admin') {
            next()
        } else {
            //redirektēt uz iepriekšējo lapu no kurs nāk 'from'
            next({ name: 'settings' })
        }
        next()
    }
    if (to.name === 'not-found' && !store.getters['user/isAuthenticated']) {
        next({ name: 'login' })
    }
    else {
        next()
    }
    if (to.name === 'login' && store.getters['user/isAuthenticated']) {
        next({ path: '/' })
    }
    else {
        next()
    }
    if (from.name === 'project' && store.getters['user/isAuthenticated']) {
        store.commit("projects/setSocketPath", from.fullPath)
    }
    if (from.name === 'admin-reports' && store.getters['user/isAuthenticated']) {
        store.commit("projects/setReportingPath", from.fullPath)
    }
    if (from.name === 'video' && store.getters['user/isAuthenticated']) {
        store.commit("channels/setRoom", store.getters['channels/getRoom'].disconnect())
    }
    else {
        next()
    }
})

export default router
