import { mapGetters } from 'vuex';
import { projectUtils } from '../mixins/project'


export const calendar = {

  data: () => ({
    //
  }),

  mixins: [projectUtils],

  computed: {
    ...mapGetters('projects', [
      'getProject',
      "getMetaList",
      "getColumns",
      "getTasks",
      "getHeaders",
      "getStatusKey"
    ]),

    ...mapGetters("calendar", ["getCalendarTasks", "getNotSortedCalendars", "getCalendars"]),

  },

  methods: {
    updateCalendarRecord(item, action) {
      var fileIDS = [];
      if (item.files.length != 0) {
        for (let i = 0; i < item.files.length; i++) {
          fileIDS.push(item.files[i].id);
        }
      }
      var start = item.start;
      var end = item.end;
      if (action == 'status') {
        if (item.status == "completed") {
          item.status = "open";
        } else {
          item.status = "completed";
        }
      }

      start = item.start / 1000
      end = item.end / 1000

      if (item.parentType == 'project') {
        // edito projektu rec + ws
        item.title = item.name;
        item.start_date = item.start / 1000;
        item.end_date = item.end / 1000;
        item.start_date = item.start_date.toString()
        item.end_date = item.end_date.toString()

        var task = {
          id: item.id,
          type: item.type,
          projectID: item.calendar_id,
          title: item.title,
          description: item.description,
          startDate: item.start_date,
          endDate: item.end_date,
          status: item.status,
          files: fileIDS,
          timed: item.timed
        }

        this.$store
          .dispatch('projects/patchTask', task)
          .then((response) => {
            var files = []
            if (response.data.files != undefined) {
              files = response.data.files
            }
            for (let i = 0; i < this.getCalendarTasks.length; i++) {
              if (this.getCalendarTasks[i].id == response.data.id) {
                this.getCalendarTasks[i].name = response.data.title;
                this.getCalendarTasks[i].project_id = response.data.project_id;
                this.getCalendarTasks[i].description = response.data.description;
                this.getCalendarTasks[i].files = files;
                this.getCalendarTasks[i].start = parseInt(response.data.start_date) * 1000;
                this.getCalendarTasks[i].end = parseInt(response.data.end_date) * 1000;
                this.getCalendarTasks[i].timed = response.data.timed;
                this.getCalendarTasks[i].days = this.dayCounter(response.data.start_date, response.data.end_date);
              }
            }
            this.$store.commit("calendar/setCalendarWsTask", { task: response.data });
            this.$store.commit("calendar/setCalendarRecordUpdated", true);
          })
      } else {
        let calendar = item.calendar_id;

        // if (item.shared_event) {
        //   const matchingCalendar = this.getCalendars.find(calendar => calendar.id === item.calendar_id && calendar.parentType === "calendar");
        //   calendar = matchingCalendar ? matchingCalendar.id : this.getCalendars.find(calendar => calendar.default_calendar).id;
        // }

        var data = {
          id: item.id,
          type: item.type,
          calendarID: calendar,
          title: item.name,
          description: item.description,
          files: fileIDS,
          startDate: start.toString(),
          endDate: end.toString(),
          timed: item.timed,
          guests: item.guests,
          status: item.status,
          shared_event: item.shared_event
        }

        this.$store
          .dispatch('calendar/patchRecord', data)
          .then((response) => {
            var files = []
            if (response.data.files != undefined) {
              files = response.data.files
            }
            for (let i = 0; i < this.getCalendarTasks.length; i++) {
              if (this.getCalendarTasks[i].id == response.data.id) {
                this.getCalendarTasks[i].name = response.data.title;
                this.getCalendarTasks[i].calendar_id = response.data.calendar_id;
                this.getCalendarTasks[i].description = response.data.description;
                this.getCalendarTasks[i].files = files;
                this.getCalendarTasks[i].start = parseInt(response.data.start_date) * 1000;
                this.getCalendarTasks[i].end = parseInt(response.data.end_date) * 1000;
                this.getCalendarTasks[i].timed = response.data.timed;
                this.getCalendarTasks[i].guests = response.data.guests; // test
                this.getCalendarTasks[i].days = this.dayCounter(response.data.start_date, response.data.end_date);
              }
            }
          })
      }
    },

    taskColor(item) {
      for (let i = 0; i < this.getNotSortedCalendars.length; i++) {
        if (this.getNotSortedCalendars[i].id == item) {
          return this.getNotSortedCalendars[i].color;
        }
      }

      const calendarList = this.getCalendarList[0];
      for (const calendar of calendarList.items) {
        for (const member of calendar.members) {
          if (member.default_calendar) {
            return member.color;
          }
        }
      }

    },

    calendarStatusActive(item) {
      for (let i = 0; i < this.getNotSortedCalendars.length; i++) {
        if (this.getNotSortedCalendars[i].id == item) {
          if (this.getNotSortedCalendars[i].active) {
            return true
          }
        }
      }
      return false
    },

    addToProjectCalendarList(task, startTimestamp, endTimestamp) {
      var title = '';
      var color = '';

      if (this.$route.name == 'calendar') {
        for (let i = 0; i < this.getNotSortedCalendars.length; i++) {
          if (this.getNotSortedCalendars[i].id == task.project_id) {
            title = this.getNotSortedCalendars[i].title;
          }
        }
        color = this.taskColor(task.project_id)
      } else {
        color = "#00897B"
        title = this.getProject.title
      }

      var event = {
        id: task.id,
        name: task.title,
        start: parseInt(startTimestamp) * 1000,
        end: parseInt(endTimestamp) * 1000,
        color: color,
        description: task.description,
        files: task.files,
        timed: task.timed,
        type: task.type,
        days: this.dayCounter(parseInt(startTimestamp), parseInt(endTimestamp)),
        parentTitle: title,
        parentType: "project",
        status: task.status,
        parent_id: task.parent_id,
        project_id: task.project_id,
      };
      this.getCalendarTasks.push(event);
    },

    addToCalendarList(task, startTimestamp, endTimestamp) {
      var title = '';
      for (let i = 0; i < this.getNotSortedCalendars.length; i++) {
        if (this.getNotSortedCalendars[i].id == task.calendar_id) {
          title = this.getNotSortedCalendars[i].title;
        }
      }

      var event = {
        id: task.id,
        name: task.title,
        start: parseInt(startTimestamp) * 1000,
        end: parseInt(endTimestamp) * 1000,
        color: this.taskColor(task.calendar_id),
        description: task.description,
        files: task.files,
        timed: task.timed,
        type: task.type,
        days: this.dayCounter(parseInt(startTimestamp), parseInt(endTimestamp)),
        parentTitle: title,
        parentType: "calendar",
        status: task.status,
        calendar_id: task.calendar_id,
        guests: task.guests,
        meeting_room: task.meeting_room,
        shared_event: task.shared_event,
        activeGuests: task.active_guests
      };
      this.getCalendarTasks.push(event);
    },

    updateProjectProperties(item, index) {
      this.$store
        .dispatch("projects/getProject", item.project_id)
        .then((response) => {
          this.$store.commit("projects/setEditing", "list");
          this.$store.commit("projects/setProject", response.data);
          var projectData = JSON.parse(this.getProject.properties);
          this.$store.commit("projects/setColumns", projectData[0]);
          this.$store.commit("projects/setHeaders", projectData[1]);

          var newTask = item;
          for (let i = 0; i < this.getColumns.length; i++) {
            if (this.getColumns[i].index == index) {
              this.getColumns[i].tasks.unshift(newTask)
            }
          }

          var project = JSON.parse(JSON.stringify(this.getColumns));
          for (let i = 0; i < project.length; i++) {
            for (let j = 0; j < project[i].tasks.length; j++) {
              var tasks = { id: project[i].tasks[j].id }
              project[i].tasks[j] = tasks
            }
          }

          var properties = [project, this.getHeaders]
          var data = {
            id: this.getProject.id,
            properties: JSON.stringify(properties),
          }
          this.$store
            .dispatch('projects/patchProject', data)
        })
    },

    trashProjectTask(item) {
      var newTask = item.task
      for (let i = 0; i < this.getTasks.length; i++) {
        if (this.getTasks[i].id == newTask.id) {
          this.getTasks[i].trash = newTask.trash;
        }
      }
      if (newTask.type == 'task') {
        for (let i = 0; i < this.getColumns.length; i++) {
          for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
            if (this.getColumns[i].tasks[j].id == newTask.id) {
              this.getColumns[i].tasks[j].trash = newTask.trash;
            }
          }
        }
      }
      for (let i = 0; i < this.getCalendarTasks.length; i++) {
        if (this.getCalendarTasks[i].id == newTask.id) {
          this.getCalendarTasks.splice(this.getCalendarTasks.indexOf(this.getCalendarTasks[i]), 1)
        }
      }
    },

    updateProjectRecord(item) {
      var newTask = item.task
      for (let i = 0; i < this.getTasks.length; i++) {
        if (this.getTasks[i].id == newTask.id) {
          this.getTasks[i].status = newTask.status;
          this.getTasks[i].title = newTask.title;
          this.getTasks[i].start_date = newTask.start_date;
          this.getTasks[i].end_date = newTask.end_date;
          this.getTasks[i].file_ids = newTask.file_ids;
          this.getTasks[i].files = newTask.files;
          this.getTasks[i].trash = newTask.trash;
        }
      }
      if (newTask.type == 'task') {
        for (let i = 0; i < this.getColumns.length; i++) {
          for (let j = 0; j < this.getColumns[i].tasks.length; j++) {
            if (this.getColumns[i].tasks[j].id == newTask.id) {
              this.getColumns[i].tasks[j].title = newTask.title
              this.getColumns[i].tasks[j].status = newTask.status;
              this.getColumns[i].tasks[j].description = newTask.description;
              this.getColumns[i].tasks[j].start_date = newTask.start_date;
              this.getColumns[i].tasks[j].end_date = newTask.end_date;
              this.getColumns[i].tasks[j].file_ids = newTask.file_ids;
              this.getColumns[i].tasks[j].files = newTask.files;
              this.getColumns[i].tasks[j].trash = newTask.trash;
              var key = this.getStatusKey;
              this.$store.commit("projects/setStatusKey", key += 1);
            }
          }
        }
        this.editCalendarTask(newTask)
      }
    },

    addRecordToProject(item) {
      var newTask = item.task
      for (let i = 0; i < this.getMetaList.length; i++) {
        newTask[this.getMetaList[i].id.toString()] = ""
      }
      for (let i = 0; i < this.getColumns.length; i++) {
        if (this.getColumns[i].index == item.index) {
          this.getColumns[i].tasks.unshift(newTask)
        }
      }
      this.getTasks.push(newTask);
      this.$store.commit("projects/setAssigned", '');
      this.addToCalendar(newTask);
    }
  }
}
