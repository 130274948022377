<template>
  <v-dialog
    v-model="setDialog"
    fullscreen
  >
    <div>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="mx-4"
            :color="getAppearances.btn_color"
            @click="setDialog = false"
          >
            Close
          </v-btn>
        </v-toolbar>
      </v-sheet>
      <v-sheet style="height: 93vh;">
        <v-calendar
          ref="calendar"
          v-model="editCalendarItem.start"
          color="primary"
          type="category"
          category-show-all
          :categories="workloadGuests"
          category-text="id"
          :events="workloadEvents"
          :event-color="getEventColor"
        >
          <template v-slot:event="{ }">
            <!-- tukšums -->
          </template>
          <template v-slot:category="{ category }">
            <div> {{  category.name }}</div>
          </template>
        </v-calendar>
      </v-sheet>
    </div>
  </v-dialog>
</template>
  
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    dialog: Boolean,
    workloadGuests: Array,
    workloadEvents: Array,
    calendarItem: Object,
  },
  data() {
    return {
      //
    };
  },

  computed: {
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("calendar", [
      "getCalendarList",
      "getWorkloadRecoreds",
      "getCalendarDate",
    ]),

    editCalendarItem: {
      get() {
        return this.calendarItem;
      },
      set(value) {
        this.$emit("update:calendarItem", value);
      },
    },

    setDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
  },

  mounted() {
    // this.$refs.calendar.checkChange();
  },

  methods: {
    getEventColor() {
      return "#757575";
    },
    setToday() {
      this.editCalendarItem.start = ""; // te jāpieliek moments?
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
  },
};
</script>
  
<style>
</style>