<template>
  <div>
    <v-row class="mx-0 my-4 mb-4 d-flex align-center">
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4 mt-n1"
          size="20"
          style="overflow: visible;"
        >
          mdi-calendar-clock-outline
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        class="py-0"
      >
        <v-row :class="$vuetify.breakpoint.mobile ? 'pa-0 mx-5' : 'pa-0 mx-3'">
          <v-menu
            v-model="setStartDateMenu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
            nudge-left='40'
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                style="font-size: 13px;"
                class="txt"
                v-bind="attrs"
                v-on="on"
              >
                {{ formatDialogDates(calendarItem.start) +' '+  timeFormat(setStartTime) }}
              </div>
            </template>
            <v-date-picker
              v-model="setFromDate"
              no-title
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                class="mr-1 mb-2"
                @click="setStartDateMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                :color="getAppearances.btn_color"
                class="mb-2 mr-2"
                dark
                @click="saveStartDate()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <div
            class="mx-2"
            style="margin-top: -2px;"
          >-</div>
          <v-menu
            v-model="setEndDateMenu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
            nudge-left='40'
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                style="font-size: 13px;"
                class="txt"
                v-bind="attrs"
                v-on="on"
              >
                {{ formatDialogDates(calendarItem.end) +' '+  timeFormat(setEndTime) }}
              </div>
            </template>
            <v-date-picker
              v-model="setTillDate"
              no-title
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                class="mr-1 mb-2"
                @click="setEndDateMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                :color="getAppearances.btn_color"
                class="mb-2 mr-2"
                dark
                @click="saveEndDate()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-spacer />
          <div class="mt-n1">
            <TimeDialog :calendarItem="calendarItem" />
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mx-0 mt-n3 mb-1 d-flex align-center">
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4"
          size="20"
          style="overflow: visible;"
        >
          mdi-account-group
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        class="py-0"
      >
        <v-autocomplete
          v-model="editCalendarItem.guests"
          :color="getAppearances.btn_color"
          :items="getGuests()"
          @change="onChange"
          :disabled="editCalendarItem.shared_event"
          hide-selected
          chips
          dense
          flat
          solo
          label="Add guests"
          item-value="id"
          return-object
          no-data-text="No users available"
          multiple
          hide-details
          :class="$vuetify.breakpoint.mobile ? 'custom-autocomplete ml-2' : 'custom-autocomplete'"
        >
          <template v-slot:selection="data">
            <v-chip
              v-if="data.item.email != undefined"
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item)"
              class="ma-1"
            >
              <v-badge
                avatar
                bordered
                overlap
                offset-y="26"
                offset-x="16"
                :value="activeEventGuest(data.item)"
                color="error"
              >
                <template v-slot:badge>
                  <v-avatar>
                    <v-icon @click="restoreDialog(data.item)">mdi-close</v-icon>
                  </v-avatar>
                </template>

                <v-avatar left>
                  <v-img :src="`/api/users/photo/${data.item.photo}`"></v-img>
                </v-avatar>
              </v-badge>
              <!-- <v-avatar left>
                <v-img :src="`/api/users/photo/${data.item.photo}`"></v-img>
              </v-avatar> -->
              {{ data.item.name }} {{data.item.lastname}}
            </v-chip>
            <v-chip
              v-else
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item)"
              class="ma-1"
            >
              <v-avatar left>
                <v-icon
                  class="grey lighten-1"
                  dark
                >
                  mdi-clipboard-list-outline
                </v-icon>
              </v-avatar>
              {{ data.item.title }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="data.item.email != undefined">
              <v-row class="pa-3">
                <v-list-item-avatar>
                  <img :src="`/api/users/photo/${data.item.photo}`">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 13px;"
                    v-html="data.item.name + ' ' + data.item.lastname + ` (${data.item.email})`"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-row>
            </template>
            <template v-else>
              <v-row class="pa-3">
                <v-list-item-avatar>
                  <v-icon
                    class="grey lighten-1"
                    dark
                  >
                    mdi-clipboard-list-outline
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 13px;"
                    v-html="data.item.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-row>
            </template>
          </template>
          <template #label="{ inputAttrs }">
            <label
              :for="inputAttrs.id"
              style="font-size: 13px"
            >{{ label }}</label>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row
      v-if="editCalendarItem.guests != '' && editCalendarItem.guests != undefined"
      class="mx-0 mb-4 mt-2 d-flex align-center"
    >
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4"
          size="20"
        >
          mdi-calendar-multiple-check
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        class="py-0"
      >
        <v-btn
          small
          :class="$vuetify.breakpoint.mobile ? 'mt-n0 ml-5' : 'mt-n0 ml-3'"
          :color="getAppearances.btn_color"
          dark
          @click="createWorkload()"
        >
          Monitor workload
        </v-btn>
        <WorkloadDialog
          :dialog.sync="workloadDialog"
          :workloadGuests.sync="workloadGuests"
          :workloadEvents.sync="workloadEvents"
          :calendarItem.sync="calendarItem"
        />
      </v-col>
    </v-row>
    <v-row class="mx-0 mb-4 mt-2 d-flex align-center">
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4"
          size="20"
        >
          mdi-video-plus-outline
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        class="py-0"
      >
        <v-btn
          small
          :class="$vuetify.breakpoint.mobile ? 'mt-n0 ml-5' : 'mt-n0 ml-3'"
          :color="getAppearances.btn_color"
          @click="roomCreate()"
          dark
        >
          <v-icon
            v-if="videoMeetingText == 'Meeting room created'"
            left
          >
            mdi-check-circle-outline
          </v-icon>
          {{ videoMeetingText }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mx-0 mt-n2 d-flex align-center">
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4"
          size="20"
          style="overflow: visible;"
        >
          mdi-map-marker
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        class="py-0"
      >
        <!-- :disabled="action == 'update' ? true : false" -->
        <v-select
          v-model="editLocation"
          :items="places"
          :class="$vuetify.breakpoint.mobile ? 'placeholderColor ml-2' : 'placeholderColor'"
          @change="calendarSlected"
          :disabled="true"
          hide-details="auto"
          style="font-size: 13px;"
          placeholder="Add location"
          dense
          flat
          solo
          single-line
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mx-0 my-4 d-flex align-center">
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4"
          size="20"
          style="overflow: visible;"
        >
          mdi-text
        </v-icon>
      </v-col>
      <v-col
        v-if="setShowFields == false"
        cols="11"
        class="py-0"
      >
        <v-row :class="$vuetify.breakpoint.mobile ? 'ma-0 mx-5' : 'ma-0 mx-3'">
          <div
            style="font-size: 13px;"
            class="mr-1"
          >
            Add
          </div>
          <div
            style="font-size: 13px;"
            class="txt mr-1"
            @click="setShowFields = true"
          >
            description
          </div>
          <div
            class="mr-1"
            style="font-size: 13px;"
          >
            or
          </div>
          <div
            style="font-size: 13px;"
            class="txt"
            @click="setShowFields = true"
          >
            attachment
          </div>
        </v-row>
      </v-col>
      <v-row
        v-else
        class="row ma-0 pb-2"
        :class="$vuetify.breakpoint.mobile ? 'ma-0 mr-4 ml-8' : 'ma-0 ml-6 mr-4'"
      >
        <v-textarea
          v-model="calendarItem.description"
          class="placeholderColor"
          hide-details="auto"
          rows="1"
          filled
          dense
          flat
        ></v-textarea>
      </v-row>
    </v-row>
    <v-row
      v-if="setShowFields || showFieldsChecker()"
      class="ma-0 pa-0 mt-n2 mb-4"
    >
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4"
          size="20"
          style="overflow: visible;"
        >
          mdi-paperclip
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        class="py-0"
      >
        <div
          style="font-size: 13px;"
          @click="setFileDialog = true"
          :class="$vuetify.breakpoint.mobile ? 'ma-0 mr-2 ml-4' : 'ma-0 mr-1 ml-3 mt-1'"
        >
          Add attachment
        </div>
        <FileSelector
          :dialog="setFileDialog"
          @close="closeFileSelector"
          @accept="attachFile"
          :parent="'calendar'"
        />
        <FileList :files.sync="calendarItem.files" />
      </v-col>
    </v-row>
    <v-row class="mx-0 mt-n2 mb-1 d-flex align-center">
      <v-col
        cols="1"
        class="py-0"
      >
        <v-icon
          class="ml-4"
          size="20"
          style="overflow: visible;"
        >
          mdi-calendar
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        class="py-0"
      >
        <v-select
          v-model="calendarItem.calendar"
          :items="getCalendars"
          :class="$vuetify.breakpoint.mobile ? 'placeholderColor ml-2' : 'placeholderColor'"
          @change="calendarSlected"
          :rules="calendarListRules"
          :disabled="action == 'update' ? true : false"
          required
          hide-details="auto"
          style="font-size: 13px;"
          placeholder="Select calendar"
          return-object
          item-text="title"
          dense
          flat
          solo
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FileSelector from "../../Dialogs/fileSelector.vue";
import FileList from "../../Calendar/FileList.vue";
import WorkloadDialog from "../../Calendar/Dialogs/WorkloadDialog.vue";
import { projectUtils } from "../../../mixins/project";
import TimeDialog from "../../Calendar/Dialogs/TimeDialog.vue";
import moment from "moment";

export default {
  components: {
    FileSelector,
    FileList,
    WorkloadDialog,
    TimeDialog,
  },
  props: {
    date: String,
    startDateMenu: Boolean,
    fromDate: String,
    formatDialogDates: Function,
    calendarItem: Object,
    saveStartDate: Function,
    endDateMenu: Boolean,
    tillDate: String,
    saveEndDate: Function,
    location: String,
    calendarSlected: Function,
    showFields: Boolean,
    showFieldsChecker: Function,
    fileDialog: Boolean,
    attachFile: Function,
    action: String,
    videoMeetingText: String,
    type: String,
  },

  data() {
    return {
      workloadEvents: [],
      workloadGuests: [],
      workloadDialog: false,
      places: ["Ogre", "Rīga"],
      calendarListRules: [(v) => !!v || "Calendar is required"],
    };
  },

  mixins: [projectUtils],

  computed: {
    ...mapGetters("user", ["getUsers", "getClockDisplay", "getTimezone"]),
    ...mapGetters("admin", ["getAppearances"]),
    ...mapGetters("calendar", [
      "getCalendars",
      "getCalendarList",
      "getWorkloadRecoreds",
      "getStartTime",
      "getEndTime",
    ]),

    setStartTime: {
      get() {
        return this.getStartTime;
      },
      set(value) {
        this.$store.commit("calendar/setStartTime", value);
      },
    },

    setEndTime: {
      get() {
        return this.getEndTime;
      },
      set(value) {
        this.$store.commit("calendar/setEndTime", value);
      },
    },

    setFileDialog: {
      get() {
        return this.fileDialog;
      },
      set(value) {
        this.$emit("update:fileDialog", value);
      },
    },

    setShowFields: {
      get() {
        return this.showFields;
      },
      set(value) {
        this.$emit("update:showFields", value);
      },
    },

    editCalendarItem: {
      get() {
        return this.calendarItem;
      },
      set(value) {
        this.$emit("update:calendarItem", value);
      },
    },

    editLocation: {
      get() {
        return this.location;
      },
      set(value) {
        this.$emit("update:location", value);
      },
    },

    setEndDateMenu: {
      get() {
        return this.endDateMenu;
      },
      set(value) {
        this.$emit("update:endDateMenu", value);
      },
    },

    setFromDate: {
      get() {
        return this.fromDate;
      },
      set(value) {
        this.$emit("update:fromDate", value);
      },
    },

    setTillDate: {
      get() {
        return this.tillDate;
      },
      set(value) {
        this.$emit("update:tillDate", value);
      },
    },

    setStartDateMenu: {
      get() {
        return this.startDateMenu;
      },
      set(value) {
        this.$emit("update:startDateMenu", value);
      },
    },
  },

  methods: {
    onChange() {
      console.log("this.editCalendarItem", this.editCalendarItem);
    },

    activeEventGuest(item) {
      if (this.editCalendarItem.activeGuests == undefined) {
        return false;
      } else {
        for (let i = 0; i < this.editCalendarItem.activeGuests.length; i++) {
          if (this.editCalendarItem.activeGuests[i] == item.id) {
            return false;
          }
        }
        return true;
      }
    },

    timeFormat(item) {
      if (this.getClockDisplay === "24h") {
        return item;
      }

      if (!item) {
        return item;
      }

      const [hours, minutes] = item.split(":");
      let hoursInt = parseInt(hours);
      const amOrPm = hoursInt >= 12 ? "PM" : "AM";
      if (hoursInt > 12) {
        hoursInt -= 12;
      } else if (hoursInt === 0) {
        hoursInt = 12;
      }
      const formattedTime = `${hoursInt}:${minutes} ${amOrPm}`;

      return formattedTime;
    },

    getGuests() {
      var guests = [];
      guests.push(...this.getUsers);
      guests.unshift(...this.getCalendarList[3].items);

      return guests;
    },

    restoreDialog(item) {
      console.log("itemm", item);
      //vai arī nevajag nemaz, google nav..
    },

    createWorkload() {
      var rec = "";
      var ids = [];
      this.workloadGuests = [];
      this.workloadEvents = [];

      for (let i = 0; i < this.editCalendarItem.guests.length; i++) {
        if (this.editCalendarItem.guests[i].email != undefined) {
          rec = {
            name:
              this.editCalendarItem.guests[i].name +
              " " +
              this.editCalendarItem.guests[i].lastname,
            id: this.editCalendarItem.guests[i].id,
          };
          this.workloadGuests.push(rec);
          ids.push(this.editCalendarItem.guests[i].id);
        } else {
          rec = {
            name: this.editCalendarItem.guests[i].title,
            id: this.editCalendarItem.guests[i].id,
          };
          this.workloadGuests.unshift(rec);
          ids.push(this.editCalendarItem.guests[i].id);
        }
      }

      let startDate = moment
        .tz(this.calendarItem.start, this.getTimezone)
        .startOf("day")
        .format("X");

      let endDate = moment
        .tz(this.calendarItem.start, this.getTimezone)
        .endOf("day")
        .format("X");

      console.log("startDate", startDate);

      console.log("endDate", endDate);

      this.$store
        .dispatch("calendar/getWorkloadRecords", {
          ids: ids,
          start: parseInt(startDate),
          end: parseInt(endDate),
        })
        .then((response) => {
          this.$store.commit("calendar/setWorkloadRecoreds", response.data);
          const events = this.getWorkloadRecoreds.flatMap((record) =>
            this.filterGuests(record).guestID.map((guestID) => ({
              name: "TTT",
              start: this.dateFormater(record.start_date) * 1000,
              end: this.dateFormater(record.end_date) * 1000,
              color: "red",
              timed: true,
              category: guestID,
            }))
          );

          this.workloadEvents = events;
          this.workloadDialog = true;
        });
    },

    filterGuests(calRecord) {
      const guestsArr = JSON.parse(calRecord.guests || "[]");

      const allIds = [
        ...new Set([
          ...calRecord.members.map((m) => m.user_id),
          ...guestsArr.map((g) => g),
        ]),
      ];

      const uniqueIds = {};
      allIds.forEach((id) => (uniqueIds[id] = true));

      const newRecord = {
        ...calRecord,
        guests: guestsArr,
        guestID: Object.keys(uniqueIds),
      };

      return newRecord;
    },

    roomCreate() {
      this.$emit("update:videoMeetingText", "Meeting room created");
      // uztaisīt kkādu video rūma neimu kuru pēctam padodu uz backend pie save
    },

    closeFileSelector() {
      this.setFileDialog = false;
    },

    remove(item) {
      const index = this.editCalendarItem.guests.indexOf(item);
      if (index >= 0) this.editCalendarItem.guests.splice(index, 1);
    },
  },
};
</script>

<style scoped>
::v-deep .custom-autocomplete .v-label {
  font-size: 13px;
}

.placeholderColor ::placeholder {
  color: #000000 !important;
}

.txt:hover {
  text-decoration: underline;
}
</style>