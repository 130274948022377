<template>
  <v-container
    fluid
    class="pa-0"
    :key="getRestoreKey"
  >
    <v-row class="fill-width">
      <div class="ma-2 fillTaskWidth">
        <draggable
          v-model="getColumn"
          @end="patchProject()"
          v-bind="{disabled: getDisableDrag}"
          handle=".handleSection"
          :filter="getMobileView == true ? '.ignore-section' : ''"
        >
          <v-col
            v-for="col in getColumns"
            :key="col.index"
            class="handleSection ignore-section"
            cols="12"
          >
            <div class="ma-1">
              <v-row class="px-0 flex-nowrap">
                <div
                  v-if="getEditSectionName == true && col.index == getSectionIndex && getEditing == 'list'"
                  style="width: 350px;"
                  class="text-left"
                >
                  <v-card
                    style="background-color: transparent;"
                    flat
                    class="underline"
                    tile
                    elevation="0"
                    height="32.5"
                  >
                    <input
                      v-model.lazy="updateSectionName"
                      v-click-outside="sectionNameClickOutside"
                      style="font-size: 16px; height: 24px; width: 327px; text-transform:uppercase;"
                      @change="editSection()"
                      class="sectionTextarea mx-3"
                      type="text"
                    >
                  </v-card>
                </div>
                <div
                  v-else
                  style="width: 350px; min-width: 350px;"
                  class="text-left underline flex-nowrap"
                >
                  <v-card
                    :class="getDisableDrag == false ? 'd-inline-flex grabCursor' : 'd-inline-flex'"
                    flat
                    tile
                    elevation="0"
                    height="25"
                    style="background-color: transparent;"
                  >
                    <v-row class="ma-0">
                      <div
                        class="mx-3 text-left font-weight-medium text-uppercase"
                        style="width: 275px; min-width: 275px;"
                      >
                        <div class="maxTextLength">{{ col.name }}</div>
                      </div>
                      <v-tooltip
                        bottom
                        nudge-bottom="3"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            icon
                            x-small
                            @click="addTask(col.index, 'top')"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>ADD TASK</span>
                      </v-tooltip>
                      <SectionMenu
                        class="mt-n1"
                        :closeCreate.sync="closeCreate"
                        :dragStart.sync="dragStart"
                        :colIndex.sync="col.index"
                        @patch="patchProject()"
                      />
                    </v-row>
                  </v-card>
                </div>
                <draggable
                  v-if="col.new != true"
                  v-model="getHeader"
                  tag="tr"
                  handle=".handle"
                  @end="patchProject()"
                  delay="150"
                  :delayOnTouchOnly="true"
                >
                  <th
                    v-for="header in getHeaders"
                    :key="header.value"
                    class="thFont"
                  >
                    <div
                      v-if="header.value != 'title' && header.show == true"
                      style="width: 136px;"
                      class="handle"
                    >
                      <div class="mx-3 text-left grabCursor">
                        {{ header.name }}
                      </div>
                    </div>
                  </th>
                </draggable>
              </v-row>
              <draggable
                :list="col.tasks"
                :group="{ name: 'col' }"
                :empty-insert-threshold="100"
                class="row mb-3"
                @start="onStart()"
                @end="patchProject()"
                v-bind="{disabled: getDisableDrag}"
                style="min-height: 15px;"
                handle=".handleTask"
                delay="150"
                :delayOnTouchOnly="true"
              >
                <div
                  slot="header"
                  key="header"
                >
                  <div v-if="col.index == addTaskIndex && taskPosition == 'top'">
                    <NewTask
                      :closeCreate.sync="closeCreate"
                      :addTaskIndex.sync="addTaskIndex"
                      :taskPosition.sync="taskPosition"
                      :date="date"
                    />
                  </div>
                </div>
                <div
                  v-for="item in col.tasks"
                  :key="item.id"
                  class="whiteBackground"
                >
                  <v-col
                    v-show="item.trash == false && taskFilter(item) && statusFilter(item)"
                    cols="12"
                    :class="getDisableDrag == false ? 'ml-0 px-0 grabCursor handleTask' : 'ml-0 px-0 handleTask'"
                    @mouseup="clickEnd()"
                    @mousedown="clickStart(item.id)"
                    @touchstart="clickStart(item.id)"
                    @mouseleave="clickEnd()"
                    @touchend="clickEnd()"
                    @touchcancel="clickEnd()"
                    :style="selectedTaskID == item.id ?'border: 0.5px solid '+borderColor() : ''"
                  >
                    <v-card
                      tile
                      color="white"
                      class="mb-n3"
                      elevation="0"
                      :style="item.status == 'completed' ? 'opacity: 0.5' : ''"
                    >
                      <v-row class="mx-0 taskBorder flex-nowrap">
                        <div
                          v-for="header in getHeaders"
                          :key="header.value"
                          @click="fullTaskInfo(item)"
                        >
                          <div
                            v-if="header.value == 'title'"
                            style="width: 350px;"
                            class="pointerCursor"
                          >
                            <div class="mx-3 my-2">
                              <div v-if="editTitle == true && item.id == taskID && dragStart == false">
                                <input
                                  v-model.lazy="newTaskTitle"
                                  @change="editTaskTitle(item)"
                                  v-click-outside="editTitleClickOutside"
                                  style="font-size: 14px; width: 326px;"
                                  class="textarea my-n1"
                                  type="text"
                                >
                              </div>
                              <div
                                v-else
                                class="textCursor d-flex"
                                @dblclick="openEditTitle(item)"
                              >
                                <div class="mb-1">
                                  <v-btn
                                    icon
                                    x-small
                                    :class="item.status == 'completed' ? 'mr-1' : 'status mr-1'"
                                    :color="item.status == 'completed' ? '#4CAF50' : ''"
                                    @click="markCompleted(item)"
                                    :key="getStatusKey"
                                  >
                                    <v-icon size="18">{{item.status == 'completed' ? 'mdi-check-circle' : 'mdi-check-circle-outline'}}</v-icon>
                                  </v-btn>
                                </div>
                                <div
                                  class="noselect"
                                  style="font-size: 14px; margin-top: 3px;"
                                >
                                  {{ item[header.value] }}
                                </div>
                                <v-badge
                                  v-if="haveSubTask(item) > 0"
                                  color="rgb(255, 0, 0, 0)"
                                  right
                                  transition="slide-x-transition"
                                  offset-y="11"
                                  offset-x="11"
                                >
                                  <v-btn
                                    icon
                                    x-small
                                    :key="getStatusKey"
                                    class="ml-1 marginIcon"
                                  >
                                    <v-icon size="18">mdi-file-tree</v-icon>
                                  </v-btn>
                                  <template v-slot:badge>
                                    <div
                                      class="black--text"
                                      style="font-size: 13px;"
                                    >
                                      {{haveSubTask(item)}}
                                    </div>>
                                  </template>
                                </v-badge>
                                <v-badge
                                  v-if="showFileIcon(item)"
                                  color="rgb(255, 0, 0, 0)"
                                  right
                                  transition="slide-x-transition"
                                  offset-y="11"
                                  offset-x="11"
                                >
                                  <v-btn
                                    icon
                                    x-small
                                    :key="getStatusKey"
                                    class="ml-1 marginIcon"
                                  >
                                    <v-icon size="18">mdi-paperclip</v-icon>
                                  </v-btn>
                                  <template v-slot:badge>
                                    <div
                                      class="black--text"
                                      style="font-size: 13px;"
                                    >
                                      {{ item.files.length }}
                                    </div>>
                                  </template>
                                </v-badge>
                                <v-spacer />
                                <TaskMenu
                                  @open="openTaskMenu(item)"
                                  :dragStart.sync="dragStart"
                                  :taskActions.sync="taskActions"
                                />
                              </div>
                            </div>
                          </div>
                          <div v-else-if="header.value == 'assigned_to' && header.show == true">
                            <div style="width: 136px;">
                              <div class="mt-1">
                                <v-badge
                                  color="error"
                                  offset-x="19"
                                  offset-y="15"
                                  bordered
                                  overlap
                                  :value="userStatus(item)"
                                >
                                  <template v-slot:badge>
                                    <v-icon style="margin-top: -1px;">mdi-archive-outline</v-icon>
                                  </template>
                                  <v-avatar
                                    @click.stop="openAssignDialog(item)"
                                    size="24"
                                    class="mx-3 mt-1 mb-n1"
                                    :tile="item.assigned_to == '' ? true : false"
                                    style="cursor: pointer;"
                                  >
                                    <v-icon
                                      size="24"
                                      v-if="item.assigned_to == ''"
                                    >
                                      mdi-account-plus</v-icon>
                                    <v-img
                                      v-else
                                      size="22"
                                      :src="`/api/users/photo/${assignedUser(item.assigned_to)}`"
                                    >
                                    </v-img>
                                  </v-avatar>
                                </v-badge>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="header.value == 'end_date' && header.show == true">
                            <div style="width: 136px;">
                              <div :class="item.end_date == '' ? 'mt-1' : 'mt-3'">
                                <div
                                  v-if="item.end_date != ''"
                                  align="left"
                                  class="mx-3 my-2 text-capitalize font-weight-regular dateFont"
                                  @click.stop="openDateDialog(item, 'end')"
                                >
                                  {{ dateFormat(item.end_date) }}
                                </div>
                                <div v-else>
                                  <v-btn
                                    @click.stop="openDateDialog(item, 'end')"
                                    icon
                                    small
                                    class="mx-2 mt-1"
                                  >
                                    <v-icon>mdi-calendar-plus</v-icon>
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="header.value == 'start_date' && header.show == true">
                            <div style="width: 136px;">
                              <div :class="item.start_date == '' ? 'mt-1' : 'mt-3'">
                                <div
                                  v-if="item.start_date != ''"
                                  align="left"
                                  class="mx-3 my-2 text-capitalize font-weight-regular dateFont"
                                  @click.stop="openDateDialog(item, 'start')"
                                >
                                  {{ dateFormat(item.start_date) }}
                                </div>
                                <div v-else>
                                  <v-btn
                                    @click.stop="openDateDialog(item, 'start')"
                                    icon
                                    small
                                    class="mx-2 mt-1"
                                  >
                                    <v-icon>mdi-calendar-plus</v-icon>
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="header.value == 'created' && header.show == true">
                            <div style="width: 136px;">
                              <div
                                align="left"
                                class="mx-3 mb-2 mt-3 text-capitalize font-weight-regular dateFont"
                              >
                                <div>
                                  {{ dateFormat(item.created) }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="header.value == 'updated' && header.show == true">
                            <div style="width: 136px;">
                              <div
                                align="left"
                                class="mx-3 mb-2 mt-3 text-capitalize font-weight-regular dateFont"
                              >
                                <div>
                                  {{ dateFormat(item.updated) }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="header.value == 'status' && header.show == true">
                            <div style="width: 136px;">
                              <v-select
                                @change="editTask(item)"
                                @click.stop
                                v-model="item.status"
                                :items="taskStatusList"
                                item-text="name"
                                append-icon=""
                                item-value="value"
                                hide-details
                                dense
                                style="font-size: 13px;"
                                flat
                                solo
                              >
                                <template #selection="{ item }">
                                  <v-btn
                                    x-small
                                    :color="item.color"
                                    class="mb-n1"
                                    dark
                                  >
                                    {{item.name}}
                                  </v-btn>
                                </template>
                              </v-select>
                            </div>
                          </div>

                          <div v-else-if="customFields(header.value).type == 'Date' && header.show == true">
                            <div style="width: 136px;">
                              <div class="mt-1">
                                <div
                                  v-if="item[header.value] != '' && item[header.value] != undefined"
                                  align="left"
                                  class="mx-3 my-2 text-capitalize font-weight-regular"
                                  style="font-size: 13px;"
                                  @click.stop="openCustomDateDialog(item, header)"
                                >
                                  {{ dateFormat(item[header.value]) }}
                                </div>
                                <div v-else>
                                  <v-btn
                                    @click.stop="openCustomDateDialog(item, header)"
                                    icon
                                    small
                                    class="mx-2 mt-1"
                                  >
                                    <v-icon>mdi-calendar-plus</v-icon>
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                          </div>
                          <CustomFieldView
                            v-else-if="header.show == true"
                            :item="item"
                            :header="header"
                          />
                        </div>
                      </v-row>
                    </v-card>
                  </v-col>
                </div>
                <div
                  slot="footer"
                  key="footer"
                >
                  <div v-if="col.index == addTaskIndex && taskPosition == 'bottom'">
                    <NewTask
                      :closeCreate.sync="closeCreate"
                      :addTaskIndex.sync="addTaskIndex"
                      :taskPosition.sync="taskPosition"
                      :date="date"
                    />
                  </div>
                  <div class="text-left pb-1">
                    <v-btn
                      v-if="dragStart == false"
                      text
                      small
                      color="grey darken-3 ma-1"
                      @click="addTask(col.index, 'bottom')"
                    >
                      + Add Task
                    </v-btn>
                  </div>
                </div>
              </draggable>
              <div class="text-left mt-n3 ml-n3">
                <v-btn
                  v-if="dragStart != false"
                  text
                  small
                  color="grey darken-3"
                >
                  + Add Task
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col
            class="mx-0 pt-0 mt-3"
            cols="12"
          >
            <div class="ma-1">
              <v-row class="px-0 flex-nowrap">
                <div
                  v-if="sectionPosition != 'bottom'"
                  style="width: 350px;"
                  class="text-left"
                >
                  <v-btn
                    :color="getAppearances.btn_color"
                    dark
                    small
                    @click="addSection('','bottom')"
                    class="mb-2"
                  >
                    + Add Section
                  </v-btn>
                </div>
                <div
                  v-if="sectionPosition == 'bottom'"
                  style="width: 350px;"
                  class="text-left"
                >
                  <v-card
                    class="underline"
                    style="background-color: transparent;"
                    tile
                    flat
                    elevation="0"
                    height="27"
                  >
                    <input
                      v-model.lazy="updateSectionName"
                      @change="newSection('bottom')"
                      v-click-outside="newSectionClickOutside"
                      style="font-size: 14px; width: 326px;"
                      class="sectionTextarea mx-3"
                      type="text"
                      placeholder="New Section title"
                    >
                  </v-card>
                </div>
              </v-row>
            </div>
          </v-col>
        </draggable>
        <DateDialog
          :dateDialog.sync="dateDialog"
          :selectedTaskDate.sync="selectedTaskDate"
          :selectedTask.sync="selectedTask"
        />
        <AssignDialog
          :assignDialog.sync="assignDialog"
          :selectedUser.sync="selectedUser"
          :selectedTask.sync="selectedTask"
        />
        <CustomDateDialog
          :customDateValues.sync="customDateValues"
          :customDateDialog.sync="customDateDialog"
        />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters, mapMutations } from "vuex";
import NewTask from "./List/NewTask";
import { projectUtils } from "../../mixins/project";
import SectionMenu from "./Menu/SectionMenu.vue";
import DateDialog from "./Dialogs/DateDialog.vue";
import AssignDialog from "./Dialogs/AssigneeDialog.vue";
import CustomFieldView from "../../components/Projects/List/CustomFieldView.vue";
import CustomDateDialog from "../../components/Projects/Dialogs/CustomDateDialog.vue";
import TaskMenu from "./Menu/TaskMenuBoard.vue";

export default {
  components: {
    draggable,
    NewTask,
    SectionMenu,
    DateDialog,
    AssignDialog,
    CustomFieldView,
    CustomDateDialog,
    TaskMenu,
  },
  data: () => ({
    dateDialog: false,
    selectedTask: {},
    selectedTaskDate: "",
    assignDialog: false,
    selectedUser: "",
    customDateValues: {},
    customDateDialog: false,
    taskPosition: "",
    addTaskIndex: "",
    dragStart: false,
    assigneeMenu: false,
    taskID: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    newTaskTitle: "",
    sectionPosition: "",
    startSectionIndex: "",
    editTitle: false,
    taskMenu: [
      { title: "Edit task name", icon: "mdi-pencil" },
      { title: "Delete task", icon: "mdi-delete" },
    ],
    interval: false,
    count: 0,
    selectedTaskID: "",
    selectedTaskToDrop: "",
  }),

  watch: {
    "$store.state.projects.editing": function () {
      this.assigneeMenu = false;
    },
    count: function () {
      if (this.getMobileView == true && this.count > 75) {
        this.selectedTaskID = this.selectedTaskToDrop;
      }
    },
  },

  mixins: [projectUtils],

  computed: {
    ...mapGetters("projects", [
      "getColumns",
      "getHeaders",
      "getRefreshDate",
      "getSectionName",
      "getSectionIndex",
      "getDisableDrag",
      "getEditSectionName",
      "getEditing",
      "getStatusKey",
      "getRestoreKey",
      "getMobileView",
      "getProjectMembers",
    ]),

    ...mapGetters("admin", ["getAppearances"]),

    getColumn: {
      get() {
        return this.getColumns;
      },
      set(value) {
        this.$store.commit("projects/setColumns", value);
      },
    },

    updateSectionName: {
      get() {
        return this.getSectionName;
      },
      set(value) {
        this.$store.commit("projects/setSectionName", value);
      },
    },

    getHeader: {
      get() {
        return this.getHeaders;
      },
      set(value) {
        this.$store.commit("projects/setHeaders", value);
      },
    },
  },

  methods: {
    ...mapMutations("projects", [
      "setColumns",
      "setHeaders",
      "setSectionName",
      "setSectionIndex",
      "setDisableDrag",
      "setEditSectionName",
      "setStatusKey",
    ]),

    openTaskMenu(item) {
      this.taskID = item.id;
      this.newTaskTitle = item.title;
      this.closeCreate();
    },

    borderColor() {
      if (this.getAppearances.btn_color == "primary") {
        return "#1976D2";
      } else {
        return this.getAppearances.btn_color;
      }
    },

    editSection() {
      for (let i = 0; i < this.getColumns.length; i++) {
        if (this.getColumns[i].index == this.getSectionIndex) {
          this.getColumns[i].name = this.getSectionName;
        }
      }
      this.closeCreate();
      this.patchProject("title");
    },

    clickStart(item) {
      if (!this.interval) {
        this.interval = setInterval(() => this.count++, 10);
        this.selectedTaskToDrop = item;
      }
    },

    clickEnd() {
      clearInterval(this.interval);
      this.interval = false;
      this.count = 0;
      this.selectedTaskToDrop = "";
    },

    onStart() {
      this.dragStart = true;
      this.editTitle = false;
    },

    editTitleClickOutside() {
      this.taskID = "";
      this.newTaskTitle = "";
      this.closeCreate();
    },

    editTaskTitle(item) {
      item.title = this.newTaskTitle;
      this.editTask(item);
      this.taskID = "";
      this.newTaskTitle = "";
      this.closeCreate();
    },

    sectionNameClickOutside() {
      this.editSection();
    },

    newSectionClickOutside() {
      this.newSection("bottom");
    },

    addTask(index, position) {
      this.addTaskIndex = index;
      this.dragStart = true;
      this.setDisableDrag(true);
      this.sectionPosition = "";
      this.editTitle = false;
      this.datePickerMenu = false;

      if (position == "top") {
        this.taskPosition = "top";
        this.taskName = "";
      } else if (position == "bottom") {
        this.taskPosition = "bottom";
        this.taskName = "";
      }
    },

    addSection(index, position) {
      this.dragStart = true;
      this.setDisableDrag(true);
      this.editTitle = false;
      this.datePickerMenu = false;
      this.setEditSectionName(false);
      this.setSectionIndex("");
      if (position == "bottom") {
        this.sectionPosition = "bottom";
        this.taskPosition = "";
        this.setSectionName("");
      }
    },

    openEditTitle(item) {
      this.taskID = item.id;
      this.closeCreate();
      this.editTitle = true;
      this.newTaskTitle = item.title;
      this.setDisableDrag(true);
    },

    taskActions(item) {
      this.editTitle = false;
      if (item.title == "Delete task") {
        this.deleteTask(this.taskID);
        this.dragStart = false;
        this.taskID = "";
      } else if (item.title == "Edit task name") {
        this.editTitle = true;
        this.setDisableDrag(true);
      }
    },

    updateDate(item, period) {
      this.newDate(item, period, this.date);
      this.closeCreate();
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.taskID = "";
    },

    openDateDialog(item, date) {
      this.selectedTask = item;
      this.selectedTaskDate = date;
      this.dateDialog = true;
    },

    openAssignDialog(item) {
      for (let i = 0; i < this.getProjectMembers.length; i++) {
        if (this.getProjectMembers[i].id == item.assigned_to) {
          this.selectedUser = this.getProjectMembers[i].id;
        } else {
          this.selectedUser = "";
        }
      }

      this.selectedTask = item;
      this.assignDialog = true;
    },

    closeCreate() {
      this.addTaskIndex = "";
      this.taskPosition = "";
      this.setSectionName("");
      this.setSectionIndex("");
      this.setDisableDrag(false);
      this.editTitle = false;
      this.sectionPosition = "";
      this.assigneeMenu = false;
      this.dragStart = false;
      this.setEditSectionName(false);
      if (this.sectionPosition == "menu") {
        this.getColumns.splice(this.startSectionIndex, 1);
      }
      this.sectionPosition = "";
    },

    patchProject(update) {
      this.selectedTaskID = "";
      this.dragStart = false;
      this.onEnd(update);
      this.$emit("websocket");
    },

    newSection(position) {
      if (this.getSectionName != "") {
        var maxVal = 0;
        var items = [];
        for (let i = 0; i < this.getColumns.length; i++) {
          items.push(this.getColumns[i].index);
        }
        if (position == "bottom") {
          maxVal = Math.max.apply(Math, items);
          var data = {
            index: maxVal + 1,
            name: this.getSectionName,
            tasks: [],
          };
          this.getColumns.push(data);
        }
        this.patchProject();
      }
      this.closeCreate();
    },

    openCustomDateDialog(item, header) {
      var data = {
        item: item,
        header: header,
      };
      this.customDateValues = data;
      this.customDateDialog = true;
    },
  },
};
</script>

<style scoped>
.taskBorder {
  border: 1px solid rgb(245, 245, 245);
}

.taskBorder:hover {
  border: 1px solid #e2e2e2;
}

.grabCursor {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.fill-width {
  overflow-x: auto;
  overflow-y: auto;
  flex-wrap: nowrap;
  height: 85.2vh;
}

.dateFont {
  font-size: 13px;
  cursor: pointer;
}

.textCursor {
  cursor: text;
}

.pointerCursor {
  cursor: pointer;
}

.thFont {
  font-weight: normal;
  color: rgb(88, 88, 88);
  font-size: 14px;
}

.underline {
  border-bottom: 2px solid #1976d2;
}

.status:hover {
  color: #4caf50;
}

.fillTaskWidth {
  /* bug fix */
  width: 10000px;
}

.whiteBackground {
  width: 100%;
}

.marginIcon {
  margin-top: 2px;
}

.maxTextLength {
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.textarea {
  background-color: transparent;
  resize: none;
  outline: none;
  border-bottom: 1px solid rgb(63, 63, 63);
}

.sectionTextarea {
  background-color: transparent;
  resize: none;
  outline: none;
  border: none;
}
</style>