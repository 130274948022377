import { render, staticRenderFns } from "./EventTab.vue?vue&type=template&id=ccd88c3a&scoped=true&"
import script from "./EventTab.vue?vue&type=script&lang=js&"
export * from "./EventTab.vue?vue&type=script&lang=js&"
import style0 from "./EventTab.vue?vue&type=style&index=0&id=ccd88c3a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccd88c3a",
  null
  
)

export default component.exports