<template>
  <v-dialog
    v-if="thisChannel.channel_members != null"
    v-model="openShare"
    persistent
    max-width="600"
  >
    <v-form>
      <v-card>
        <v-card-title class="text-h5">
          {{ thisChannel.name }}
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="selectedUsers"
            color="primary"
            :items="ItemsToSelect"
            hide-selected
            chips
            label="Select person"
            item-text="name"
            item-value="id"
            return-object
            no-data-text="No users available"
            multiple
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item)"
              >
                <v-avatar left>
                  <v-img :src="`/api/users/photo/${data.item.photo}`"></v-img>
                </v-avatar>
                {{ data.item.name }} {{data.item.lastname}}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-row class="pa-3">
                  <v-list-item-avatar>
                    <img :src="`/api/users/photo/${data.item.photo}`">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name + ' ' + data.item.lastname + ` (${data.item.email})`"></v-list-item-title>
                  </v-list-item-content>
                </v-row>
              </template>
            </template>
          </v-autocomplete>

          <v-list>
            <v-subheader class="ma-0 pa-0">Channel members</v-subheader>
            <v-list-item
              class="v-list-item--link ma-0 pa-0"
              v-for="(item, index) in getChannelUsers()"
              :key="index"
            >
              <v-badge
                color="error"
                offset-x="25"
                offset-y="22"
                bordered
                overlap
                :value="item.deactivated_at == 0 ? false : true"
              >
                <template v-slot:badge>
                  <v-icon style="margin-top: -1px;">mdi-archive-outline</v-icon>
                </template>
                <v-list-item-avatar>
                  <v-img :src="`/api/users/photo/${item.photo}`">
                  </v-img>
                </v-list-item-avatar>
              </v-badge>

              <v-list-item-content>
                <v-list-item-title v-text="item.name + ' ' + item.lastname"></v-list-item-title>
                <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="item.owner"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                    >
                      {{ showMemberRole(item.owner) }}
                    </v-btn>
                  </template>

                  <v-list>
                    <v-dialog
                      v-model="removeDialog"
                      persistent
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-title>unshare</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title class="headline">
                          Unshare this user?
                        </v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red"
                            text
                            @click="removeDialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="primary"
                            @click="removeUser(item), removeDialog = false"
                          >
                            Yes
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red"
            text
            @click="openShare = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="addUserToChannel(selectedUsers)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
  
  <script>
import { mapGetters } from "vuex";
export default {
  props: {
    shareStatus: Boolean,
    thisChannel: Object,
  },

  data: () => ({
    // activeUsers: null,
    ItemsToSelect: null,
    selectedUsers: null,
    removeDialog: false,
  }),

  mounted() {},

  updated() {},

  watch: {
    shareStatus() {
      this.getUsersToSelect();
    },
  },

  computed: {
    ...mapGetters("user", ["getUserById", "getUsers"]),
    ...mapGetters("channels", []),

    openShare: {
      get() {
        return this.shareStatus;
      },
      set(value) {
        this.$emit("update:shareStatus", value);
      },
    },
  },

  methods: {
    showMemberRole(data) {
      if (data) {
        return "Owner";
      } else {
        return "Member";
      }
    },

    getChannelUsers() {
      if (this.thisChannel != null) {
        let currentChannel = this.thisChannel;
        let userList = [];

        if (currentChannel != null) {
          for (let i = 0; i < currentChannel.channel_members.length; i++) {
            // userList.push(
            //   this.getUserById(currentChannel.channel_members[i].user_id)
            // );
            const user = this.getUserById(
              currentChannel.channel_members[i].user_id
            );
            user.owner = currentChannel.channel_members[i].owner;

            if (user.owner) {
              userList.unshift(user); // Add owner at the beginning of the array
            } else {
              userList.push(user); // Add non-owner at the end of the array
            }
          }
        }

        return userList;
      }
    },

    getUsersToSelect() {
      if (this.thisChannel !== undefined) {
        let members = this.getChannelUsers(); // Get connected users
        console.log(members);

        // Filter out users that are not connected to the channel
        this.ItemsToSelect = this.getUsers.filter((user) => {
          return !members.some((member) => member.id === user.id);
        });
      }
    },

    remove(item) {
      const index = this.selectedUsers.indexOf(item);
      if (index >= 0) this.selectedUsers.splice(index, 1);
    },

    addUserToChannel(users) {
      this.$store.dispatch("channels/addUsersToChannel", {
        channel_id: this.thisChannel.id,
        users: users.map((el) => el.id),
      });
      this.openShare = false;
      this.selectedUsers = null;
    },
  },
};
</script>
  