<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      clipped
      app
      :width="navigationWidth()"
      style="z-index: 15"
    >
      <v-row
        class="fill-height flex-nowrap"
        no-gutters
      >
        <div style="position: fixed; height: 100%">
          <v-navigation-drawer
            v-model="drawer"
            clipped
            :color="getAppearances.header_color"
            class="lighten"
            dark
            mini-variant
            mini-variant-width="56"
            :stateless="$vuetify.breakpoint.mobile ? true : false"
          >

            <v-list
              dense
              nav
              class="px-0 pt-2 pb-0"
            >
              <v-list-item
                class="pl-4"
                @click="switchApp('drive')"
              >
                <v-icon>mdi-folder</v-icon>
              </v-list-item>
              <v-badge
                color="red"
                :content="getUnreadPosts.length"
                overlap
                offset-x="15"
                offset-y="17"
                :value="getUnreadPosts.length > 0 ? true : false"
              >
                <v-list-item
                  class="pl-4"
                  @click="switchApp('messages')"
                >
                  <v-icon>mdi-message-video</v-icon>
                </v-list-item>
              </v-badge>
              <v-list-item
                class="pl-4"
                @click="switchApp('my-projects')"
              >
                <v-icon>mdi-chart-gantt</v-icon>
              </v-list-item>
              <v-list-item
                class="pl-4"
                @click="switchApp('calendar')"
              >
                <v-icon>mdi-calendar</v-icon>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </div>
        <div style="margin-left: 56px; width: 100%">
          <FilesNavigation v-if="$route.matched[1].name == 'drive'" />
          <SettingsNavigation v-if="$route.matched[1].name == 'settings'" />
          <ChatNavigation v-if="$route.matched[1].name == 'chat'" />
          <ProjectsNavigation v-if="$route.matched[1].name == 'projects'" />
          <CalendarNavigation v-if="$route.matched[1].name == 'calendar'" />
        </div>
      </v-row>
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      :color="getAppearances.header_color"
      dense
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      :clipped-right="$vuetify.breakpoint.lgAndUp"
      elevation="0"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-4"
      >
        <span class="hidden-sm-and-down">{{ getAppearances.header }}</span>
      </v-toolbar-title>
      <v-col>
        <v-menu
          offset-y
          v-model="searchMenu"
        >
          <template v-slot:activator="{}">
            <v-text-field
              v-model="search_text"
              dense
              flat
              solo-inverted
              :color="getAppearances.btn_color"
              hide-details
              clearable
              label="Search"
              class="hidden-sm-and-down"
              prepend-inner-icon="mdi-magnify"
              @input="searchInput"
            ></v-text-field>
          </template>
          <v-list v-if="searchedItems.length > 0">
            <v-virtual-scroll
              :bench="10"
              :items="searchedItems"
              min-height="80"
              item-height="80"
              :min-width="$vuetify.breakpoint.smAndDown ? 300 : 500"
              max-height="300"
            >
              <template v-slot:default="{ item }">
                <v-list-item @click="openItemLocation(item)">
                  <v-list-item-avatar size="40">
                    <v-icon
                      v-if="
                        fileTypes[item.type] !== undefined &&
                        fileTypes[item.type].fileType !== 'image'
                      "
                      large
                      class="grey lighten-4"
                      dark
                      :color="fileTypes[item.type].color"
                    >
                      {{ fileTypes[item.type].icon }}
                    </v-icon>
                    <v-img
                      v-else-if="
                        fileTypes[item.type] !== undefined &&
                        fileTypes[item.type].fileType == 'image'
                      "
                      :src="`/api/files/thumbnails/${item.id}`"
                    >
                    </v-img>
                    <v-icon
                      v-else
                      large
                      class="grey lighten-4"
                      dark
                      :color="fileTypes['unknown'].color"
                    >
                      {{ fileTypes["unknown"].icon }}
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon color="grey lighten-1">mdi-information</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-list>
          <v-card v-else>
            <div
              class="pa-9 fill-height"
              align="center"
              justify="center"
            >
              <v-row
                align="center"
                justify="center"
                class="fill-height"
              >
                <div
                  align="center"
                  justify="center"
                >
                  <v-icon size="48"> mdi-magnify </v-icon>
                  <div class="text-h5 font-weight-regular grey--text pt-2">
                    No search results
                  </div>
                </div>
              </v-row>
            </div>
          </v-card>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-scroll-y-transition>
        <div v-if="getOpened && $router.currentRoute.name == 'office'">
          <v-row class="flex-nowrap">
            <v-btn
              depressed
              :color="getAppearances.btn_color"
              dark
              @click="openShareDialog"
              class="mr-3"
            >
              Share
            </v-btn>
            <v-btn
              depressed
              :color="getAppearances.btn_color"
              dark
              @click="close"
              class="mr-3"
            >
              Close
            </v-btn>
          </v-row>
        </div>
      </v-scroll-y-transition>
      <NotificationMenu :messages="messages"></NotificationMenu>
      <v-menu
        bottom
        min-width="200px"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            medium
            v-on="on"
          >
            <v-avatar size="36">
              <img
                :src="`/api/users/photo/${getUser.photo}`"
                alt="Missing"
              />
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                size="48"
                class="mb-2"
              >
                <v-img
                  :src="`/api/users/photo/${getUser.photo}`"
                  alt="Missing"
                ></v-img>
              </v-avatar>
              <h3>{{ getUser.name + " " + getUser.lastname }}</h3>
              <p class="caption mt-1">
                {{ getUser.email }}
              </p>
              <v-divider class="my-2"></v-divider>
              <v-btn
                depressed
                rounded
                text
                @click="settings"
              > Settings </v-btn>
              <v-divider class="my-2"></v-divider>
              <AboutDialog />
              <v-divider class="my-2"></v-divider>
              <v-btn
                depressed
                rounded
                text
                @click="logOut"
              > Log Out </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container
        class="d-flex align-start pa-0 overflow-hidden"
        fluid
        fill-height
      >
        <v-col class="ma-0 pa-0 fill-height">
          <router-view v-if="loaded"></router-view>
        </v-col>
      </v-container>
    </v-main>
    <UploadBar />
    <Alert />
    <FileViewer v-if="getState" />
    <PermissionDialog />
    <LinkpermissionDialog />
  </v-app>
</template>

<script>
import UploadBar from "../components/Upload";
import Alert from "../components/Alert";
import NotificationMenu from "../components/NotificationMenu.vue";
import FilesNavigation from "../components/Navigation/Files.vue";
import SettingsNavigation from "../components/Navigation/Settings.vue";
import ProjectsNavigation from "../components/Navigation/Projects.vue";
import ChatNavigation from "../components/Navigation/Chat.vue";
import CalendarNavigation from "../components/Navigation/Calendar.vue";
import FileViewer from "../components/FileViewer.vue";
import AboutDialog from "../components/AboutDialog.vue";
import PermissionDialog from "../components/Dialogs/share/permissionDialog.vue";
import LinkpermissionDialog from "../components/Dialogs/share/linkPermissionDialog.vue";
import { mapGetters, mapMutations } from "vuex";
import { utils } from "../mixins/utils";
import { navigation } from "../mixins/navigation";
import { dateUtils } from "../mixins/date";
import { file } from "../mixins/file";
import { actions } from "../mixins/browserActions";
import { getCsrfFromCookie } from "../js/utils";
import { initialize } from "../js/websocket/websocket";

export default {
  components: {
    UploadBar,
    Alert,
    FileViewer,
    AboutDialog,
    // Notification,
    NotificationMenu,
    FilesNavigation,
    SettingsNavigation,
    ProjectsNavigation,
    ChatNavigation,
    CalendarNavigation,
    PermissionDialog,
    LinkpermissionDialog,
  },

  data: () => ({
    loaded: false,
    messages: [],
    message: {},
    wsConnection: null,
    activeApp: 1,
    drawer: null,
    miniDrawer: true,
    item: false,
    bio: "",
    items: [
      // { app: "dashboard", icon: "mdi-monitor-dashboard", text: "Dashboard" },
      { app: "files", icon: "mdi-folder", text: "Files" },
      { app: "chat", icon: "mdi-message-video", text: "Chat" },
      // { app: "calendar", icon: "mdi-calendar", text: "Calendar" },
      // { app: "chat", icon: "mdi-message-video", text: "Chat" },
      { app: "calendar", icon: "mdi-calendar", text: "Calendar" },
      { app: "projects", icon: "mdi-chart-gantt", text: "Projects" },
    ],

    sharing: false,
    select: [],
    sharedUsers: [],
    peopleWithPermissions: [],

    search_text: "",
    searchedItems: [],
    searchMenu: false,

    dateMenu: null,
    date: "",

    miniNav: 56,
    largeNav: 330,
  }),

  watch: {
    "$store.state.projects.socketPath"() {
      this.webSocket.close();
      this.$store.commit("projects/setTaskNavigation", false);
      this.$store.commit("projects/setFilterData", []);
    },
    "$store.state.projects.reportingPath"() {
      this.$store.commit("projects/setFilterData", []);
    },
  },

  mixins: [utils, dateUtils, file, actions, navigation],

  created() {
    this.$store.commit("auth/setCSRF", getCsrfFromCookie());
    this.$store.dispatch("user/getMyPreferences");
    this.$store.dispatch("user/getUsers");
    this.$store.dispatch("channels/getUnreadPosts");
  },

  mounted() {
    //Create websocket and register in Vuex?
    //Then interact with websocket trough vuex storedo connection?
    this.$store.dispatch("notifications/getNotifications").then((response) => {
      this.messages = response.data;
    });

    initialize(); //Websocket

    this.loaded = true;
  },

  computed: {
    ...mapGetters("files", [
      "isSelectedFile",
      "selectedFile",
      "fileTypes",
      "getActivity",
      "getDetails",
      "getShareItem",
      "getTreeview",
      "getFiles",
      "getPerPage",
    ]),

    ...mapGetters("main", ["details", "tab"]),

    ...mapGetters("meta", ["getMetadata"]),

    ...mapGetters("user", ["getUser", "getUsers", "getUserById"]),

    ...mapGetters("office", ["getOpened"]),

    ...mapGetters("admin", ["getAppearances"]),

    ...mapGetters("fileViewer", ["getState"]),

    ...mapGetters("projects", ["getSocket"]),

    ...mapGetters("channels", ["getUnreadPosts"]),

    webSocket: {
      get() {
        return this.getSocket;
      },
      set(value) {
        this.$store.commit("projects/setSocket", value);
      },
    },
  },

  methods: {
    ...mapMutations("projects", ["setSocket"]),

    logOut() {
      this.$store.dispatch("user/logOut").then(() => {
        this.$router.push({ name: "login" });
      });
    },

    switchApp(name) {
      this.closeDetails();

      this.navigateToRoute({ name: name });
      //      switch (item.app) {
      //        case "files":
      //          this.navigateToRoute({ name: "drive" });
      //          break;
      //        case "dashboard":
      //          this.navigateToRoute({ name: "dashboard" });
      //          break;
      //        case "chat":
      //          this.navigateToRoute({ name: "chat" });
      //          break;
      //        case "calendar":
      //          this.navigateToRoute({ name: "calendar" });
      //          break;
      //        case "projects":
      //          this.navigateToRoute({ name: "my-projects" });
      //          break;
      //
      //        default:
      //          break;
      //      }

      if (name != "drive") {
        this.$store.commit("files/setOpen", []);
      }
    },

    closeDetails() {
      this.$store.commit("main/openDetails", false);
    },

    close() {
      this.$router.go(-1);
    },

    openShareDialog() {
      this.$store
        .dispatch("files/downloadFile", this.$router.currentRoute.params.id)
        .then((response) => {
          this.$store.commit("files/setShareItem", response.data);
          this.$store.commit("shareDialog/openShareDialog", response.data);
        });
    },

    openPermissions(item) {
      this.$store.commit("files/setShareItem", this.selectedFile);

      this.$store.commit("shareDialog/openUserPermissionsDialog", {
        status: true,
        user: item,
      });
    },

    openItemLocation(item) {
      if (item.owner == this.getUser.id) {
        let id = item.id;
        if (!item.isFolder) {
          id = item.parentDir;
        }

        this.openDirectory(id);
        this.highlightFile(item);

        return;
      }

      this.$router.push({ name: "shared", params: { id: item.id } });
    },

    permissions() {
      let arr = [];

      for (let i = 0; i < this.getUsers.length; i++) {
        for (let j = 0; j < this.selectedFile.userPermissions.length; j++) {
          if (
            this.getUsers[i].id == this.selectedFile.userPermissions[j].user_id
          ) {
            arr.push(this.getUsers[i]);
          }
        }
      }

      return arr;
    },

    navigationWidth() {
      let name = this.$router.currentRoute.name;

      let arr = ["office", "dashboard", "chat"];

      for (let i = 0; i < arr.length; i++) {
        if (name == arr[i]) {
          return this.miniNav;
        }
      }

      return this.largeNav;
    },

    settings() {
      this.navigateToRoute({ name: "personal" });
      this.$store.commit("main/openDetails", false);
    },

    searchInput() {
      if (!this.searchMenu) {
        this.searchMenu = true;
      }
      if (this.search_text != "") {
        this.$store
          .dispatch("toolbar/search", this.search_text)
          .then((response) => {
            this.searchedItems = response.data;
            return;
          });
      } else if (this.search_text == "") {
        this.searchedItems = [];
      }
    },
  },
};
</script>

<style scoped>
.lighten {
  filter: brightness(120%);
}
</style>
