import Vue from 'vue'
import Vuex from 'vuex'
//import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
    ws: null
  },

  getters: {
    getWS(state) {
      return state.ws;
    }
  },

  mutations: {
    setWS(state, ws) {
      state.ws = ws;
    }
  },

  actions: {

  },

  namespaced: true,
})
