import store from '../../store/store'
import router from '../../router/index'

export var connection = null;

export function initialize() {
    var protocol = "wss://";
    if (window.location.hostname == "localhost") {
        protocol = "ws://";
    }
    let address = protocol + window.location.host + "/api/notifications/ws/notifications";

    connection = new WebSocket(address);


    connection.onmessage = (event) => {
        var object = JSON.parse(event.data);

        handleEvent(object)

        //this.message = object;
        //this.message.user = this.getUserById(this.message.sender);
        //this.$store.commit("notifications/showNotification", true);
        //this.messages.unshift(object);
    };
}

//Handle events
function handleEvent(msg) {
    switch (msg.event) {
        case "task_create":
        case "task_assigned":
        case "task_delete":
        case "task_status_changed":
        case "task_owner_status_changed":
            handleTaskNotification(msg);
            break;
        case "project_delete":
        case "project_rename":
        case "project_invite":
            handleProjectNotification(msg);
            break;
        case "file_create":
        case "file_share":
        case "file_upload":
        case "file_rename":
        case "file_delete":
        case "file_restore":
        case "file_download":
        case "file_public_download":
            handleFileNotifications(msg);
            break;
        case "post":
            handlePost(msg);
            break;
        case "channel": // Check vai šito vajag vispār jāpārdomā
            handleChannel(msg);
            break;
        default:
            return;
    }
}

function handleTaskNotification(msg) {
    //push message to notifications
    addToNotifications(msg.data);
    //for projects send notification anyway even if project is open
    let data = getProjectData(msg);
    sendDesktopNotification(msg.data.app, msg.data.message, data);
}

function handleProjectNotification(msg) {
    addToNotifications(msg.data);

    let data = getProjectData(msg);
    sendDesktopNotification(msg.data.app, msg.data.message, data);
}


function handleFileNotifications(msg) {
    addToNotifications(msg.data);

    let data = getFileData(msg)
    sendDesktopNotification(msg.data.app, msg.data.message, data);
}

function handleChannel(msg) {
    const channels = store.getters["channels/getChannels"];
    const directMsg = store.getters["channels/getDirectMsg"];

    if (msg.data.props.channel.type == "D") {
        if (directMsg.length == 0 && channels.length == 0) {
            store.commit("channels/setCurrentChannel", msg.data.props.channel)
            router.push({
                name: "messages",
                params: { id: msg.data.props.channel.id },
            });
        }

        directMsg.push(msg.data.props.channel)
    } else {
        if (channels.length == 0 && directMsg.length == 0) {
            store.commit("channels/setChannels", msg.data.props.channel)
            router.push({
                name: "messages",
                params: { id: msg.data.props.channel.id },
            });
        }

        channels.push(msg.data.props.channel)
    }


    // store.getters["channels/getCurrentChannel"].id

    // const data = {
    //     app: "chat",
    //     event: msg.event,
    //     channel_id: msg.data.props.channel.id,
    //     notification_id: msg.data.id
    // };

    // sendDesktopNotification(msg.data.app, msg.data.props.channel.message, data);
}

function handlePost(msg) {
    if (msg.data.props.post.type === "DirectMsg") {
        const directMsg = store.getters["channels/getDirectMsg"];

        directMsg.forEach((dm) => {
            dm.channel_members.forEach((member) => {
                if (member.channel_id == msg.data.props.post.channel_id) {
                    member.deleted = false;
                }
            });
        });
    }


    const data = {
        app: "chat",
        event: msg.event,
        channel_id: msg.data.props.post.channel_id,
        post_id: msg.data.props.post.id,
        notification_id: msg.data.id
    };

    if (router.currentRoute.name == "messages" && store.getters["channels/getCurrentChannel"].id == msg.data.props.post.channel_id) {

        store.getters["channels/getCurrentChannelPosts"].posts.push(msg.data.props.post);
        //Jāatrod no kapēc tur ir tas undefined
        if (msg.data.props.reply_post != undefined) {
            store.getters["channels/getCurrentChannelPosts"].reply_posts.push(msg.data.props.reply_post);
        }
        //mark messages as read
        store.dispatch("channels/readChannelPosts", { channel_id: msg.data.props.post.channel_id, id: msg.data.props.post.id });

        if (!document.hasFocus()) {
            //If document has no focus then update unread messages
            store.dispatch("channels/getUnreadPosts");

            sendDesktopNotification(msg.data.app, msg.data.props.post.message, data);

            return;
        }

        return;
    }

    //Update unread Messages
    store.dispatch("channels/getUnreadPosts")

    sendDesktopNotification(msg.data.app, msg.data.props.post.message, data);
}

//move to separate file later
function sendDesktopNotification(title, body, data) {
    //Verify if i am in the app or page before showing notification

    //This should be in separate file to show notification
    Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
            const notification = new Notification(title, {
                icon: window.location.origin + '/api/settings/appearances/image/logo',
                body: body,
                tag: "Welcome message",
                data: data
            });

            notification.onclick = (event) => {
                store.dispatch("notifications/readNotification", { read: true, id: [event.target.data.notification_id] })
                window.focus();

                if (event.target.data.app == "projects" && event.target.data.url) {
                    window.location.href = event.target.data.url;
                } else if (event.target.data.app == "files") {
                    store.dispatch("files/downloadFile", event.target.data.file_id).then((response) => {
                        handleFileRedirect(response.data, event);
                    }).catch((error) => {
                        console.log(error.response.data);
                    });
                } else if (event.target.data.app == "chat") {
                    store.dispatch("channels/getChannelList").then((response) => {
                        for (let i = 0; i < response.data.length; i++) {
                            if (event.target.data.channel_id == response.data[i].id) {
                                router.push({
                                    name: "messages",
                                    params: { name: response.data[i].name },
                                });
                            }
                        }
                    });
                }
            };
        }
    });
}

function getProjectData(msg) {
    let url = window.location.origin + "/projects/p/" + msg.data.props.project_id;

    const data = {
        app: "projects",
        event: msg.event,
        url: url,
        notification_id: msg.data.id
    }

    return data;
}

function getFileData(msg) {
    //Get file and then decide where to redirect user
    const data = {
        app: "files",
        event: msg.event,
        file_id: msg.data.props.file_id,
        notification_id: msg.data.id
    };

    return data;
}

function handleFileRedirect(file, event) {
    //Do nothing because the file doesn't exist
    if (event.target.data.event == "file_delete") {
        return;
    }

    store.dispatch("files/getDrive").then((response) => {
        for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].id == file.parentDir && store.getters["user/getUser"].id != file.owner) {
                router.push({ name: "shared" });
                return;
            }
        }

        router.push({ name: 'files', params: { path: file.parentDir } });
    });
}

function addToNotifications(message) {
    let messages = store.getters["notifications/getMessages"];
    messages.unshift(message);
}

