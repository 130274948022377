<template>
  <div>
    <v-row class="ma-4">
      <v-icon
        class="mx-3 mt-n1"
        size="20"
      >
        mdi-calendar-clock-outline
      </v-icon>
      <v-menu
        v-model="startDateMenu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
        nudge-left='40'
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            style="font-size: 13px;"
            class="txt"
            v-bind="attrs"
            v-on="on"
          >
            {{ formatDialogDates(calendarItem.end) }}
          </div>
        </template>
        <v-date-picker
          v-model="tillDate"
          no-title
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            class="mr-1 mb-2"
            @click="startDateMenu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :color="getAppearances.btn_color"
            class="mb-2 mr-2"
            dark
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-row>
    <v-row class="ma-4 d-flex flex-row">
      <v-icon
        class="ml-3 mt-n2"
        size="20"
      >
        mdi-repeat
      </v-icon>
      <div class="d-inline-flex ">
        <v-text-field
          v-model="repeat"
          class="placeholderColor"
          hide-details="auto"
          style="font-size: 13px; max-width: 155px;"
          placeholder="Add calendar"
          dense
          flat
          solo
        ></v-text-field>
      </div>

      <v-checkbox
        class="mr-16"
        v-model="repeatCheckbox"
        label="All day"
        dense
      ></v-checkbox>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>