<template>
  <video
    class="video"
    id="video-share"
  ></video>
</template>

<script>
export default {
  props: {
    participant: Object,
  },

  data: function () {
    return {};
  },

  beforeDestroy() {
    console.log("Destroying share video tile");
  },

  methods: {},
};
</script>

<style scoped>
.video {
  /* object-fit: contain; */
  width: 100%;
  height: auto;
  background: black;
  max-height: 80vh;
}
</style>
