<template>
  <div class="grow grey lighten-5 fill-height">
    <v-list
      dense
      class="ma-0 pa-0 pt-2"
    >
      <v-subheader>
        PERSONAL
      </v-subheader>
      <v-list-item-group
        v-model="selectUser"
        :color="getAppearances.btn_color"
      >
        <v-list-item
          v-for="(item, i) in user"
          :disabled="item.disabled"
          :color="getAppearances.btn_color"
          :to="{ name: item.link }"
          :key="i"
          class="my-0 my-0"
        >
          <v-list-item-icon class="ml-2">
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-list
      dense
      v-if="getUser.role == 'system_admin'"
      class="ma-0 pa-0"
    >
      <v-subheader>
        ADMINISTRATOR
      </v-subheader>
      <v-list-item-group
        v-model="selectAdmin"
        :color="getAppearances.btn_color"
      >
        <v-list-item
          v-for="(item, i) in admin"
          :disabled="item.disabled"
          :color="getAppearances.btn_color"
          :to="{ name: item.link }"
          :key="i"
          class="my-0 my-0"
        >
          <v-list-item-icon class="ml-2">
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { navigation } from "../../mixins/navigation";

export default {
  data: function () {
    return {
      selectedUser: "",
      selectedAdmin: "",
      user: [
        {
          text: "Personal Info",
          icon: "mdi-account",
          link: "personal",
          disabled: false,
        },
        {
          text: "Notifications",
          icon: "mdi-bell-ring",
          link: "notifications",
          disabled: false,
        },
        {
          text: "Security",
          icon: "mdi-security",
          link: "user-security",
          disabled: false,
        },
      ],
      admin: [
        {
          text: "Admin reports",
          icon: "mdi-view-dashboard",
          link: "admin-reports",
          disabled: false,
        },
        {
          text: "Users",
          icon: "mdi-account-group",
          link: "users-and-groups",
          disabled: false,
        },
        {
          text: "General settings",
          icon: "mdi-cog-outline",
          link: "general-settings",
          disabled: false,
        },
        {
          text: "Security",
          icon: "mdi-security",
          link: "security",
          disabled: false,
        },
        {
          text: "Metadata",
          icon: "mdi-tag",
          link: "metadata",
          disabled: false,
        },
        {
          text: "License",
          icon: "mdi-license",
          link: "license",
          disabled: false,
        },
      ],
    };
  },

  mixins: [navigation],

  computed: {
    ...mapGetters("user", ["getUser"]),

    ...mapGetters("admin", ["getAppearances"]),

    selectUser: {
      get() {
        return this.selectedUser;
      },
      set(val) {
        this.selectedAdmin = "";
        this.selectedUser = val;
      },
    },

    selectAdmin: {
      get() {
        return this.selectedAdmin;
      },
      set(val) {
        this.selectedUser = "";
        this.selectedAdmin = val;
      },
    },
  },

  methods: {
    changeRoute(item) {
      this.navigateToRoute({ name: item });
    },
  },
};
</script>
